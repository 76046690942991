/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClient = `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    code
    name
    description
    logo
    website
    email
    address
    phoneno
    accounts {
      items {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        features {
          items {
            id
            feature {
              id
              name
              code
            }
            claimsCanAccess
            claimsCanEdit
          }
          nextToken
        }
        users {
          items {
            id
            user {
              name
              id
              email
              claimsCanAccess
              claimsCanEdit
              isApproved
              claims (limit: 1500) {
                items {
                  id
                  claim {
                    id
                    code
                    name
                    description
                    feature {
                      id
                      code
                    }
                  }
                }
                nextToken
              }
              version
            }
            claimsCanAccess
            claimsCanEdit
          }
          nextToken
        }
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
    features {
      items {
        id
        feature {
          id
          name
          code
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        user {
          name
          id
          email
          claimsCanAccess
          claimsCanEdit
          isApproved
          claims (limit: 1500) {
            items {
              id
              claim {
                id
                code
                name
                description
                feature {
                  id
                  code
                }
              }
            }
            nextToken
          }
          version
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const listClients = `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        items {
          id
          code
          name
          description
          logo
          phoneno
          version
          website
        }
        nextToken
      }
      features {
        nextToken
        items {
          id
          feature {
            id
            code
          }
        }
      }
      users {
        items {
          user {
            id
            name
            email
          }
        }
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    nextToken
  }
}
`;
export const listPictureFormats = `query ListPictureFormats(
  $filter: ModelPictureFormatFilterInput
  $limit: Int
  $nextToken: String
) {
  listPictureFormats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
    }
    nextToken
  }
}
`;
export const getClientFeatureMap = `query GetClientFeatureMap($id: ID!) {
  getClientFeatureMap(id: $id) {
    id
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        items {
          id
          name
          email
          code
        }
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listClientFeatureMaps = `query ListClientFeatureMaps(
  $filter: ModelClientFeatureMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientFeatureMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      feature {
        id
        code
        name
        description
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const getAccount = `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    code
    name
    description
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        items {
          id
          name
          description
        }
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    logo
    website
    email
    address
    phoneno
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    features {
      items {
        id
        feature {
          id
          name
          code
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        user {
          name
          id
          email
          claimsCanAccess
          claimsCanEdit
          isApproved
          claims {
            items {
              id
              claim {
                id
                code
                name
                description
                feature {
                  id
                  code
                }
              }
            }
          }
          version
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    version
  }
}
`;
export const listAccounts = `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        items {
          id
        }
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    nextToken
  }
}
`;
export const getAccountClaimMap = `query GetAccountClaimMap($id: ID!) {
  getAccountClaimMap(id: $id) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listAccountClaimMaps = `query ListAccountClaimMaps(
  $filter: ModelAccountClaimMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountClaimMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      claim {
        id
        code
        name
        description
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const getAccountFeatureMap = `query GetAccountFeatureMap($id: ID!) {
  getAccountFeatureMap(id: $id) {
    id
    account {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listAccountFeatureMaps = `query ListAccountFeatureMaps(
  $filter: ModelAccountFeatureMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccountFeatureMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      feature {
        id
        code
        name
        description
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    name
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    appSettings {
      items {
        key
        value
      }
      nextToken
    }
    isApproved
    claims {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    notificationEnabled
    notificationSettings
    lastUpdatedBy {
      id
      name
    }
    version
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    nextToken
  }
}
`;
export const getUserClientMap = `query GetUserClientMap($id: ID!) {
  getUserClientMap(id: $id) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    client {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      accounts {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listUserClientMaps = `query ListUserClientMaps(
  $filter: ModelUserClientMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserClientMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const getUserAccountMap = `query GetUserAccountMap($id: ID!) {
  getUserAccountMap(id: $id) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    accounts {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listUserAccountMaps = `query ListUserAccountMaps(
  $filter: ModelUserAccountMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserAccountMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      accounts {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const getUserClaimMap = `query GetUserClaimMap($id: ID!) {
  getUserClaimMap(id: $id) {
    id
    user {
      id
      email
      name
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      appSettings {
        nextToken
      }
      isApproved
      claims {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    claim {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listUserClaimMaps = `query ListUserClaimMaps(
  $filter: ModelUserClaimMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserClaimMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        email
        name
        isApproved
        claimsCanAccess
        claimsCanEdit
        version
      }
      claim {
        id
        code
        name
        description
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const listEvents = `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      type
      sport
      property
      fixture
      mosChecked
      requestedBitrate
      bugRequired
      serviceStartTime
      lineupTime
      startTime
      endTime
      serviceEndTime
      status
      headTimeMin
      tailTimeMin
      inputAudioPid
      inputVideoPid
      claimsCanAccess
      claimsCanEdit
      version
      startType
      attachments
      groupId
      customer {
        id
        code
        name
        description
        version
      }
      account {
        id
        code
        name
        description
        logo
        website
        email
      }
      lastUpdatedBy {
        id
        name
      }
      audioMapping {
        items {
          id
          channelNo
          description
          active
          stereo
          claimsCanAccess
          claimsCanEdit
        }
        nextToken
      }
      actions {
        items {
          actionType
          configWowza {
            id
            targets {
              items {
                application
                id
                streamKey
                transcoderProfile
                target
                type
                version
              }
            }
            server
            type
            autostart
            claimsCanAccess
            claimsCanEdit
            version
            autostartOffsetMin
          }
          assignedOutput {
            id
            url
            name
            displayName
            ottPlatform
            outputType
            status
          }
        }
        nextToken
      }
      outputs {
        items {
          id
          output {
            id
            url
            name
            displayName
            description
            outputType
            status
            ottPlatform
          }
        }
      }
      input {
        id
        url
        name
        status
        description
        claimsCanAccess
        claimsCanEdit
        version
        requiresPidInfo
      }
    }
    nextToken
  }
}
`;
export const eventsByStartTime = `query EventsByStartTime(
  $type: EventType
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByStartTime(
    type: $type
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      type
      sport
      property
      mosChecked
      fixture
      requestedBitrate
      bugRequired
      serviceStartTime
      lineupTime
      startTime
      endTime
      serviceEndTime
      status
      headTimeMin
      tailTimeMin
      inputAudioPid
      inputVideoPid
      claimsCanAccess
      claimsCanEdit
      cancellationMsg
      version
      importSource
      importDate
      startType
      attachments
      inputDetails
      inputFrcEnabled
      groupId
      inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
      outputStreams {
        items {
          id
          outputStream {
            id
            name
            type
            address
            port
            latencyMs
            talkbackAddress
            talkbackPort
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
            udpPort
            udpRtt
          }
        }
      }
      inputStreams {
        items {
          id
          inputStream {
            id
            name
            description
            type
            attachedTo
            address
            port
            latencyMs
            talkbackAddress
            talkbackPort
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
          }
        }
      }
      customer {
        id
        code
        name
      }
      account {
        id
        code
        name
      }
      lastUpdatedBy {
        id
        name
      }
      commentaryLanguage
      commentaryUser {
        name
        email
        id
      }
      audioMapping {
        items {
          id
          channelNo
          description
          active
          stereo
          claimsCanAccess
          claimsCanEdit
        }
        nextToken
      }
      actions (limit: 1500) {
        items {
          actionType
          configWowza {
            id
            targets {
              items {
                application
                id
                streamKey
                target
                type
                noStremKeyRequired
                transcoderProfile
                claimsCanAccess
                claimsCanEdit
                version
              }
            }
            server
            type
            autostart
            autostartOffsetMin
            claimsCanAccess
            claimsCanEdit
            version
          }
          assignedOutput {
            id
            url
            name
            displayName
            ottPlatform
            outputType
            status
          }
        }
        nextToken
      }
      outputs (limit: 1500) {
        items {
          id
          outputOttApplicationOverride
          output {
            id
            url
            name
            displayName
            description
            outputType
            status
            ottPlatform
            ottServer
            ottApplication
          }
        }
      }
      inputDownlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      input {
        id
        name
        type
        status
        url
        description
        claimsCanAccess
        claimsCanEdit
        requiresPidInfo
        frcAvailable
        requireDetails
        probeProjectId
        probeTaskId
        downlinkConfig {
          satelliteName
          service
          symbolRate
          satelliteFrequency
          satellitePolarization
          BISS
        }
        version
      }
      inputDetails
    }
    nextToken
  }
}
`;
export const eventsByStartTimeAdmin = `query EventsByStartTime(
  $type: EventType
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByStartTime(
    type: $type
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      type
      sport
      property
      mosChecked
      fixture
      requestedBitrate
      bugRequired
      serviceStartTime
      lineupTime
      startTime
      endTime
      serviceEndTime
      status
      headTimeMin
      tailTimeMin
      inputAudioPid
      inputVideoPid
      claimsCanAccess
      claimsCanEdit
      cancellationMsg
      version
      importSource
      importDate
      startType
      attachments
      inputDetails
      inputFrcEnabled
      groupId
      inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
      outputStreams {
        items {
          id
          outputStream {
            id
            name
            type
            address
            port
            latencyMs
            talkbackAddress
            talkbackPort
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
            udpPort
            udpRtt
          }
        }
      }
      inputStreams {
        items {
          id
          inputStream {
            id
            name
            description
            type
            attachedTo
            address
            port
            latencyMs
            talkbackAddress
            talkbackPort
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
          }
        }
      }
      customer {
        id
        code
        name
      }
      account {
        id
        code
        name
      }
      lastUpdatedBy {
        id
        name
      }
      commentaryLanguage
      commentaryUser {
        name
        email
        id
      }
      audioMapping {
        items {
          id
          channelNo
          description
          active
          stereo
          claimsCanAccess
          claimsCanEdit
        }
        nextToken
      }
      actions (limit: 1500) {
        items {
          actionType
          configWowza {
            id
            targets {
              items {
                application
                id
                streamKey
                target
                type
                noStremKeyRequired
                transcoderProfile
                claimsCanAccess
                claimsCanEdit
                version
              }
            }
            server
            type
            autostart
            autostartOffsetMin
            claimsCanAccess
            claimsCanEdit
            version
          }
          assignedOutput {
            id
            url
            name
            displayName
            ottPlatform
            outputType
            status
          }
        }
        nextToken
      }
      outputs (limit: 1500) {
        items {
          id
          outputOttApplicationOverride
          output {
            id
            url
            name
            displayName
            description
            outputType
            status
            ottPlatform
            ottServer
            ottApplication
          }
        }
      }
      inputDownlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      input {
        id
        name
        type
        status
        url
        description
        claimsCanAccess
        claimsCanEdit
        requiresPidInfo
        frcAvailable
        requireDetails
        probeProjectId
        probeTaskId
        downlinkConfig {
          satelliteName
          service
          symbolRate
          satelliteFrequency
          satellitePolarization
          BISS
        }
        version
      }
      inputDetails
      inputOverride {
        id
        name
        type
        url
        status
        description
        claimsCanAccess
        claimsCanEdit
        probeProjectId
        probeTaskId
        downlinkConfig {
          satelliteName
          service
          symbolRate
          satelliteFrequency
          satellitePolarization
          BISS
        }
        version
      }
    }
    nextToken
  }
}
`;
export const eventsByStartTimeExtended = `query EventsByStartTime(
  $type: EventType
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByStartTime(
    type: $type
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      type
      mosChecked
      sport
      property
      fixture
      requestedBitrate
      bugRequired
      serviceStartTime
      lineupTime
      startTime
      endTime
      serviceEndTime
      status
      headTimeMin
      tailTimeMin
      claimsCanAccess
      claimsCanEdit
      cancellationMsg
      version
      importSource
      importDate
      startType
      attachments
      groupId
      inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
      customer {
        id
        code
        name
        description
        version
      }
      account {
        id
        code
        name
        description
        logo
        website
        email
      }
      lastUpdatedBy {
        id
        name
      }
      audioMapping {
        items {
          id
          channelNo
          description
          active
          stereo
          claimsCanAccess
          claimsCanEdit
        }
        nextToken
      }
      actions (limit: 1500) {
        items {
          actionType
          configWowza {
            id
            targets {
              items {
                application
                id
                streamKey
                target
                type
                noStremKeyRequired
                transcoderProfile
                claimsCanAccess
                claimsCanEdit
                version
              }
            }
            server
            type
            autostart
            autostartOffsetMin
            claimsCanAccess
            claimsCanEdit
            version
          }
          assignedOutput {
            id
            url
            name
            displayName
            ottPlatform
            outputType
            status
            claimsCanAccess
            claimsCanEdit
          }
        }
        nextToken
      }
      outputs (limit: 1500) {
        items {
          id
          output {
            id
            url
            name
            displayName
            description
            outputType
            status
            ottPlatform
            ottServer
            ottApplication
            outputProfiles {
              items {
                outputProfile {
                  id
                }
              }
            }
            broadcastDestinations {
              items {
                id
                routerDestination {
                  id
                  name
                  description
                  originRouterGroup
                  originRouterDestinationId
                  originRouterId
                  claimsCanAccess
                  claimsCanEdit
                  version
                  routerGroups {
                    items {
                      id
                      routerGroup {
                        id
                        name
                      }
                      claimsCanAccess
                      claimsCanEdit
                    }
                    nextToken
                  }
                  defaultSource {
                    id
                    name
                    description
                    originRouterGroup
                    originRouterSourceId
                    originRouterId
                    claimsCanAccess
                    claimsCanEdit
                    version
                  }
                  routedSource {
                    id
                    name
                    description
                    originRouterGroup
                    originRouterSourceId
                    originRouterId
                    claimsCanAccess
                    claimsCanEdit
                    version
                  }
                }
              }
            }
            equipment {
              items {
                id
                equipment {
                  id
                  name
                  description
                  equipmentType
                  equipmentId
                  actions
                }
              }
            }
          }
        }
      }
      inputDetails
      inputDownlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      input {
        id
        name
        type
        status
        description
        url
        claimsCanAccess
        claimsCanEdit
        requireDetails
        probeProjectId
        probeTaskId
        downlinkConfig {
          satelliteName
          service
          symbolRate
          satelliteFrequency
          satellitePolarization
          BISS
        }
        broadcastSources {
          items {
            id
            routerSource {
              name
              description
              originRouterGroup
              originRouterSourceId
              originRouterId
              claimsCanAccess
              claimsCanEdit
              version
              routerGroups {
                items {
                  id
                }
                nextToken
              }
              routedDestinations {
                items {
                  name
                  id
                }
                nextToken
              }
              streamSource {
                id
                name
                description
                type
                address
                port
                talkbackAddress
                talkbackPort
                latencyMs
                bufferMinMs
                bufferMaxMs
                encPassphrase
                encType
                encKeyLen
                udpAddress
                udpPort
                claimsCanAccess
                claimsCanEdit
                version
              }
            }
          }
        }
        equipment {
          items {
            id
            equipment {
              id
              name
              description
              equipmentType
              equipmentId
              actions
            }
          }
        }
        version
      }
      inputOverride {
        id
        name
        type
        url
        status
        description
        claimsCanAccess
        claimsCanEdit
        probeProjectId
        probeTaskId
        downlinkConfig {
          satelliteName
          service
          symbolRate
          satelliteFrequency
          satellitePolarization
          BISS
        }
        broadcastSources {
          items {
            id
            routerSource {
              name
              description
              originRouterGroup
              originRouterSourceId
              originRouterId
              claimsCanAccess
              claimsCanEdit
              version
              routerGroups {
                items {
                  id
                }
                nextToken
              }
              routedDestinations {
                items {
                  id
                }
                nextToken
              }
              streamSource {
                id
                name
                description
                type
                address
                port
                talkbackAddress
                talkbackPort
                latencyMs
                bufferMinMs
                bufferMaxMs
                encPassphrase
                encType
                encKeyLen
                udpAddress
                udpPort
                claimsCanAccess
                claimsCanEdit
                version
              }
            }
          }
        }
        equipment {
          items {
            id
            equipment {
              id
              name
              description
              equipmentType
              equipmentId
              actions
            }
          }
        }
        version
      }
    }
    nextToken
  }
}
`;

export const listAudioMappingProfiles = `query ListAudioMappingProfiles(
  $filter: ModelAudioMappingProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listAudioMappingProfiles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
      }
      channels {
        items {
          description
          active
          channelNo
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getEvent = `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    name
    description
    type
    sport
    property
    mosChecked
    fixture
    requestedBitrate
    bugRequired
    serviceStartTime
    lineupTime
    startTime
    endTime
    serviceEndTime
    status
    headTimeMin
    tailTimeMin
    inputAudioPid
    inputVideoPid
    claimsCanAccess
    claimsCanEdit
    cancellationMsg
    version
    importSource
    importDate
    startType
    attachments
    inputDetails
    inputFrcEnabled
    groupId
    inputFrcInputFormat {
      id
      name
      description
    }
    inputFrcOututFormat {
      id
      name
      description
    }
    outputStreams {
      items {
        id
        outputStream {
          id
          name
          type
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
          udpPort
          udpRtt
        }
      }
    }
    inputStreams {
      items {
        id
        inputStream {
          id
          name
          description
          type
          attachedTo
          address
          port
          latencyMs
          talkbackAddress
          talkbackPort
          bufferMinMs
          bufferMaxMs
          encPassphrase
          encKeyLen
          encType
          udpAddress
        }
      }
    }
    customer {
      id
      code
      name
      description
      version
    }
    account {
      id
      code
      name
      description
      logo
      website
      email
    }
    lastUpdatedBy {
      id
      name
    }
    commentaryLanguage
    commentaryUser {
      name
      email
      id
    }
    audioMapping {
      items {
        id
        channelNo
        description
        active
        stereo
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    actions (limit: 1500) {
      items {
        actionType
        configWowza {
          id
          targets {
            items {
              application
              id
              streamKey
              target
              type
              noStremKeyRequired
              transcoderProfile
              claimsCanAccess
              claimsCanEdit
              version
            }
          }
          server
          type
          autostart
          autostartOffsetMin
          claimsCanAccess
          claimsCanEdit
          version
        }
        assignedOutput {
          id
          url
          name
          displayName
          ottPlatform
          status
          outputType
          claimsCanAccess
          claimsCanEdit
        }
      }
      nextToken
    }
    outputs (limit: 1500) {
      items {
        id
        outputOttApplicationOverride
        output {
          id
          url
          name
          displayName
          description
          outputType
          status
          ottPlatform
          ottServer
          ottApplication
          outputProfiles {
            items {
              outputProfile {
                id
              }
            }
          }
        }
      }
    }
    inputDownlinkConfig {
      satelliteName
      service
      symbolRate
      satelliteFrequency
      satellitePolarization
      BISS
    }
    input {
      id
      name
      type
      status
      url
      description
      claimsCanAccess
      claimsCanEdit
      requiresPidInfo
      requireDetails
      frcAvailable
      downlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      version
    }
    inputOverride {
      id
      name
      type
      url
      status
      description
      probeProjectId
      probeTaskId
      claimsCanAccess
      claimsCanEdit
      downlinkConfig {
        satelliteName
        service
        symbolRate
        satelliteFrequency
        satellitePolarization
        BISS
      }
      version
    }
  }
}
`;
export const getEventOutputMap = `query GetEventOutputMap($id: ID!) {
  getEventOutputMap(id: $id) {
    id
    outputOttApplicationOverride
    event {
      id
      groupId
      commentaryLanguage
      commentaryUser {
        name
        email
        id
      }
      customer {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }      
      inputFrcEnabled
      inputFrcInputFormat {
        id
        name
        description
      }
      inputFrcOututFormat {
        id
        name
        description
      }
      outputStreams {
        items {
          id
          outputStream {
            id
            name
            type
            address
            port
            latencyMs
            talkbackAddress
            talkbackPort
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
            udpPort
            udpRtt
          }
        }
      }
      inputStreams {
        items {
          id
          inputStream {
            id
            name
            description
            type
            attachedTo
            address
            port
            latencyMs
            talkbackAddress
            talkbackPort
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
          }
        }
      }
      name
      description
      type
      mosChecked
      sport
      property
      fixture
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      bitrateMbps
      bugRequired
      outputs {
        nextToken
      }
      input {
        id
        url
        name
        status
        description
        claimsCanAccess
        claimsCanEdit
        version
        frcAvailable
        requiresPidInfo
      }
      actions {
        nextToken
      }
      lineupTime
      startTime
      endTime
      status
      timeExtensionMin
      audioMapping {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    output {
      id
      url
      name
      description
      events {
        nextToken
      }
      outputType
      status
      ottPlatform
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    claimsCanAccess
    claimsCanEdit
  }
}
`;
export const listEventOutputMaps = `query ListEventOutputMaps(
  $filter: ModelEventOutputMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventOutputMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      outputOttApplicationOverride
      event {
        id
        name
        description
        type
        sport
        property
        fixture
        bitrateMbps
        bugRequired
        lineupTime
        startTime
        endTime
        status
        timeExtensionMin
        claimsCanAccess
        claimsCanEdit
        version
        groupId
      }
      output {
        id
        url
        name
        description
        outputType
        status
        ottPlatform
        claimsCanAccess
        claimsCanEdit
        version
      }
      claimsCanAccess
      claimsCanEdit
    }
    nextToken
  }
}
`;
export const getInput = `query GetInput($id: ID!) {
  getInput(id: $id) {
    id
    name
    description
    status
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claimsCanAccess
    claimsCanEdit
    frcAvailable
    lastUpdatedBy {
      id
      name
    }
    version
  }
}
`;
export const listInputs = `query ListInputs(
  $filter: ModelInputFilterInput
  $limit: Int
  $nextToken: String
) {
  listInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      status
      url
      probeProjectId
      probeTaskId
      accounts {
        items {
          id
        }
        nextToken
      }
      equipment (limit: 250) {
        items {
          id
        }
      }
      broadcastSources (limit: 250) {
        items {
          id
        }
      }
      type
      requiresPidInfo
      requireDetails
      streamFileBaseName
      frcAvailable
      downlinkConfig {
        satelliteName
        satelliteFrequency
        satellitePolarization
        symbolRate
        BISS
        service
      }
      claimsCanAccess
      claimsCanEdit
      version
    }
    nextToken
  }
}
`;

export const getOutput = `query GetOutput($id: ID!) {
  getOutput(id: $id) {
    id
    name
    description
    events {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    outputType
    status
    ottPlatform
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
    version
  }
}
`;
export const listOutputs = `query ListOutputs(
  $filter: ModelOutputFilterInput
  $limit: Int
  $nextToken: String
) {
  listOutputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      url
      description
      outputType
      status
      ottPlatform
      displayName
      accounts {
        items {
          id
        }
      }
      events (limit: 250) {
        items {
          id
        }
      }
      equipment (limit: 250){
        items {
          id
        }
      }
      broadcastDestinations (limit: 250){
        items {
          id
        }
      }
      outputProfiles (limit: 250){
        items {
          id
        }
      }
      ottServer
      ottApplication
      ottNoStremKeyRequired
      claimsCanAccess
      claimsCanEdit
      version
    }
    nextToken
  }
}
`;
export const listRouters = `query ListRouters(
  $filter: ModelRouterFilterInput
  $limit: Int
  $nextToken: String
) {
  listRouters(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
      account {
        id
        name
      }
      routerGroups {
        items {
          id
          name
          sources {
            items {
              id
            }
            nextToken
          }
          destinations {
            items {
              id
            }
            nextToken
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getRouter = `query GetRouter($id: ID!) {
  getRouter(id: $id) {
    id
    name
    description
    claimsCanAccess
    claimsCanEdit
    version
    account {
      id
      code
      name
      description
      logo
      website
      email
      address
      phoneno
      claimsCanAccess
      claimsCanEdit
      version
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      claims {
        nextToken
      }
      features {
        nextToken
      }
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      users {
        nextToken
      }
      inputs {
        nextToken
      }
      outputs {
        nextToken
      }
    }
    lastUpdatedBy {
      id
      name
      isApproved
      version
      email
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
      appSettings {
        nextToken
      }
    }
    routerGroups {
      items {
        id
        name
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
  }
}
`;
export const listRouterGroups = `query ListRouterGroups(
  $filter: ModelRouterGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listRouterGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      claimsCanAccess
      claimsCanEdit
      version
      router {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      sources {
        items {
          id
          routerSource {
            id
            name
          }
        }
        nextToken
      }
      destinations {
        items {
          id
          routerDestination {
            id
            name
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getRouterGroup = `query GetRouterGroup($id: ID!) {
  getRouterGroup(id: $id) {
    id
    name
    claimsCanAccess
    claimsCanEdit
    version
    lastUpdatedBy {
      id
      name
      email
    }
    router {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      version
      account {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      routerGroups {
        nextToken
      }
    }
    sources {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    destinations {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
  }
}
`;
export const getRouterSourceGroupMap = `query GetRouterSourceGroupMap($id: ID!) {
  getRouterSourceGroupMap(id: $id) {
    id
    claimsCanAccess
    claimsCanEdit
    routerGroup {
      id
      name
      version
      router {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      sources {
        items {
          id
          name
        }
        nextToken
      }
      destinations {
        items {
          id
          name
        }
        nextToken
      }
    }
    routerSource {
      id
      name
      description
      originRouterGroup
      originRouterSourceId
      originRouterId
      claimsCanAccess
      claimsCanEdit
      version
      mappedInputs {
        items {
          name
          id
          requiresPidInfo
          frcAvailable
        }
        nextToken
      }
      routerGroups {
        items {
          name
          id
        }
        nextToken
      }
      routedDestinations {
        items {
          name
          id
        }
        nextToken
      }
    }
  }
}
`;
export const listRouterSourceGroupMaps = `query ListRouterSourceGroupMaps(
  $filter: ModelRouterSourceGroupMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listRouterSourceGroupMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
        email
      }
      routerGroup {
        id
        name
        claimsCanAccess
        claimsCanEdit
        version
      }
      routerSource {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        originRouterId
        claimsCanAccess
        claimsCanEdit
        version
      }
    }
    nextToken
  }
}
`;
export const getRouterDestinationGroupMap = `query GetRouterDestinationGroupMap($id: ID!) {
  getRouterDestinationGroupMap(id: $id) {
    id
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
      isApproved
      version
      email
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
      appSettings {
        nextToken
      }
    }
    routerGroup {
      id
      name
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
        email
      }
      router {
        id
        name
        description
        claimsCanAccess
        claimsCanEdit
        version
      }
      sources {
        items {
          name
          id
        }
        nextToken
      }
      destinations {
        items {
          name
          id
        }
        nextToken
      }
    }
    routerDestination {
      id
      name
      description
      originRouterGroup
      originRouterDestinationId
      originRouterId
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
        email
      }
      mappedOutputs {
        items {
          id
        }
        nextToken
      }
      routerGroups {
        items {
          name
          id
        }
        nextToken
      }
      routedSource {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        originRouterId
        claimsCanAccess
        claimsCanEdit
        version
      }
    }
  }
}
`;
export const listRouterDestinationGroupMaps = `query ListRouterDestinationGroupMaps(
  $filter: ModelRouterDestinationGroupMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listRouterDestinationGroupMaps(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      routerGroup {
        id
        name
        claimsCanAccess
        claimsCanEdit
        version
      }
      routerDestination {
        id
        name
        description
        originRouterGroup
        originRouterDestinationId
        originRouterId
        claimsCanAccess
        claimsCanEdit
        version
      }
    }
    nextToken
  }
}
`;
export const listRouterSources = `query ListRouterSources(
  $filter: ModelRouterSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  listRouterSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      originRouterGroup
      originRouterSourceId
      originRouterId
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
      }
      routerGroups {
        items {
          id
          routerGroup {
            id
            name
          }
        }
        nextToken
      }
      mappedInputs {
        items {
          id
          input {
            url
            id
            name
            status
            requiresPidInfo
            frcAvailable
          }
        }
      }
      routedDestinations (limit: 1500) {
        items {
          id
          name
          description
          originRouterGroup
          originRouterDestinationId
          originRouterId
          version
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getRouterSource = `query GetRouterSource($id: ID!) {
  getRouterSource(id: $id) {
    id
    name
    description
    originRouterGroup
    originRouterSourceId
    originRouterId
    claimsCanAccess
    claimsCanEdit
    version
    lastUpdatedBy {
      id
      name
      email
    }
    routerGroups {
      items {
        id
        routerGroup {
          id
          name
        }
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    routedDestinations (limit: 1500) {
      items {
        id
        name
        description
        originRouterGroup
        originRouterDestinationId
        originRouterId
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
  }
}
`;
export const getRouterDestination = `query GetRouterDestination($id: ID!) {
  getRouterDestination(id: $id) {
    id
    name
    description
    originRouterGroup
    originRouterDestinationId
    originRouterId
    claimsCanAccess
    claimsCanEdit
    version
    lastUpdatedBy {
      id
      name
      isApproved
      version
      email
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
      appSettings {
        nextToken
      }
    }
    mappedOutputs {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    routerGroups {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    routedSource {
      id
      name
      description
      originRouterGroup
      originRouterSourceId
      originRouterId
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
        isApproved
        version
        email
        claimsCanAccess
        claimsCanEdit
      }
      mappedInputs {
        nextToken
      }
      routerGroups {
        nextToken
      }
      routedDestinations {
        nextToken
      }
    }
  }
}
`;
export const listRouterDestinations = `query ListRouterDestinations(
  $filter: ModelRouterDestinationFilterInput
  $limit: Int
  $nextToken: String
) {
  listRouterDestinations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      originRouterGroup
      originRouterDestinationId
      originRouterId
      claimsCanAccess
      claimsCanEdit
      version
      mappedOutputs {
        items {
          id
          output {
            id
            url
            name
            displayName
            status
          }
        }
        nextToken
      }
      routerGroups {
        items {
          id
          routerGroup {
            id
            name
          }
          claimsCanAccess
          claimsCanEdit
        }
        nextToken
      }
      defaultSource {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        originRouterId
        claimsCanAccess
        claimsCanEdit
        version
      }
      routedSource {
        id
        name
        description
        originRouterGroup
        originRouterSourceId
        originRouterId
        claimsCanAccess
        claimsCanEdit
        version
      }
    }
    nextToken
  }
}
`;
export const getClaim = `query GetClaim($id: ID!) {
  getClaim(id: $id) {
    id
    code
    name
    description
    feature {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        nextToken
      }
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    users {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
  }
}
`;
export const listClaims = `query ListClaims(
  $filter: ModelClaimFilterInput
  $limit: Int
  $nextToken: String
) {
  listClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      name
      description
      feature {
        id
        code
        name
        description
      }
      accounts {
        nextToken
      }
      users {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getFeature = `query GetFeature($id: ID!) {
  getFeature(id: $id) {
    id
    code
    name
    description
    clients {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    accounts {
      items {
        id
        claimsCanAccess
        claimsCanEdit
      }
      nextToken
    }
    claims {
      items {
        id
        code
        name
        description
      }
      nextToken
    }
  }
}
`;
export const listFeatures = `query ListFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      code
      name
      description
      clients {
        nextToken
      }
      accounts {
        nextToken
      }
      claims {
        items {
          id
          code
          name
          description
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getLanguage = `query GetLanguage($id: ID!) {
  getLanguage(id: $id) {
    id
    name
    isoCode1
    isoCode2t
    isoCode2b
    isoCode3
  }
}
`;
export const listLanguages = `query ListLanguages(
  $filter: ModelLanguageFilterInput
  $limit: Int
  $nextToken: String
) {
  listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      isoCode1
      isoCode2t
      isoCode2b
      isoCode3
    }
    nextToken
  }
}
`;
export const getInputAccountMap = `query GetInputAccountMap($id: ID!) {
  getInputAccountMap(id: $id) {
    id
    input {
      id
      url
      name
      status
      description
      accounts {
        nextToken
      }
      frcAvailable
      claimsCanAccess
      claimsCanEdit
      requiresPidInfo
      frcAvailable
      lastUpdatedBy {
        id
        name
      }
      version
    }
    accounts {
      id
      code
      name
      description
      client {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      logo
      website
      email
      address
      phoneno
      claims {
        nextToken
      }
      features {
        nextToken
      }
      users {
        nextToken
      }
      inputs {
        nextToken
      }
      outputs {
        nextToken
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
      version
    }
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
  }
}
`;
export const listInputAccountMaps = `query ListInputAccountMaps(
  $filter: ModelInputAccountMapFilterInput
  $limit: Int
  $nextToken: String
) {
  listInputAccountMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      input {
        id
        url
        name
        status
        description
        claimsCanAccess
        claimsCanEdit
        version
        requiresPidInfo
        frcAvailable
      }
      accounts {
        id
        code
        name
        description
        logo
        website
        email
        address
        phoneno
        claimsCanAccess
        claimsCanEdit
        version
      }
      claimsCanAccess
      claimsCanEdit
      lastUpdatedBy {
        id
        name
      }
    }
    nextToken
  }
}
`;

export const listClaimsProfiles = `query ListClaimsProfiles(
  $filter: ModelClaimsProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listClaimsProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      claims
      name
      description
    }
    nextToken
  }
}
`;


export const getOutputProfile = `query GetOutputProfile($id: ID!) {
  getOutputProfile(id: $id) {
    id
    name
    description
    claimsCanAccess
    claimsCanEdit
    lastUpdatedBy {
      id
      name
    }
    outputs {
      items {
        id
        name
        displayName
        description
        outputType
        status
        ottPlatform
        claimsCanAccess
        claimsCanEdit
        version
      }
      nextToken
    }
  }
}
`;
export const listOutputProfiles = `query ListOutputProfiles(
  $filter: ModelOutputProfileFilterInput
  $limit: Int
  $nextToken: String
) {
  listOutputProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      claimsCanAccess
      claimsCanEdit
      outputs {
        items {
          id
          output {
            id
            name
            status
            displayName
            description
            outputType
            status
            ottPlatform
            ottServer
            ottApplication
            ottNoStremKeyRequired
            url
            claimsCanAccess
          }
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const listEventReports = `query ListEventReports(
  $filter: ModelEventReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ntr
      description
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
      }
      event {
        id
        name
        description
        type
        sport
        property
        fixture
        requestedBitrate
        bugRequired
        serviceStartTime
        lineupTime
        startTime
        endTime
        serviceEndTime
        status
        mosChecked
        headTimeMin
        tailTimeMin
        inputAudioPid
        inputVideoPid
        claimsCanAccess
        claimsCanEdit
        version
        groupId
        customer {
          id
          code
          name
          description      
        }
        audioMapping {
          items {
            id
            channelNo
            description
            active
            stereo
            claimsCanAccess
            claimsCanEdit
          }
          nextToken
        }
        actions {
          items {
            actionType
            configWowza {
              id
              targets {
                items {
                  application
                  id
                  streamKey
                  transcoderProfile
                  target
                  type
                  version
                }
              }
              server
              type
              autostart
              autostartOffsetMin
              claimsCanAccess
              claimsCanEdit
              version
            }
            assignedOutput {
              id
              url
              name
              displayName
              ottPlatform
              outputType
              status
            }
          }
          nextToken
        }
        outputs {
          items {
            id
            output {
              id
              url
              name
              description
              displayName
              outputType
              status
              ottPlatform
            }
          }
        }
        input {
          id
          url
          name
          status
          description
          claimsCanAccess
          claimsCanEdit
          version
          requiresPidInfo
          frcAvailable
        }
      }
    }
    nextToken
  }
}
`;

export const listAutomationAudits = `query ListAutomationAudits(
  $filter: ModelAutomationAuditFilterInput
  $limit: Int
  $nextToken: String
) {
  listAutomationAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      invokedDate
      linkedType
      linkedId
      actionTaken
      actionType
      clientId {
        id
        code
        name
      }
      accountId {
        id
        code
        name
      }
      invokedBy {
        id
        email
        name
      }
    }
    nextToken
  }
}
`;

export const listStreamDestinations = `query ListStreamDestinations(
  $filter: ModelStreamDestinationFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamDestinations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      type
      address
      port
      talkbackAddress
      talkbackPort
      latencyMs
      encPassphrase
      encType
      udpAddress
      udpPort
      udpRtt
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
      }
      routerDestination {
        items {
          id
          name
          description
        }
      }
    }
    nextToken
  }
}
`;

export const listStreamSources = `query ListStreamSources(
  $filter: ModelStreamSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  listStreamSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      type
      address
      port
      talkbackAddress
      talkbackPort
      latencyMs
      bufferMinMs
      bufferMaxMs
      encPassphrase
      encType
      encKeyLen
      udpAddress
      udpPort
      claimsCanAccess
      claimsCanEdit
      version
      lastUpdatedBy {
        id
        name
      }
      routerSources {
        items {
          id
          name
          description
        }
      }
    }
    nextToken
  }
}
`;
export const getAppSetting = `query GetAppSetting($id: ID!) {
  getAppSetting(id: $id) {
    id
    key
    value
  }
}`;
export const listAppSettings = `query ListAppSettings(
  $filter: ModelAppSettingFilterInput
  $limit: Int
  $nextToken: String
  ) {
  listAppSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      key
      value
    }
  }
}`;
export const listTranscoderProfiles = `query ListTranscoderProfiles(
  $filter: ModelTranscoderProfileFilterInput
  $limit: Int
  $nextToken: String
  ) {
    listTranscoderProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      transcoderProfile
      claimsCanAccess
      claimsCanEdit
      version
      createdAt
      updatedAt
    }
  }
}`;
export const listMGWInterfaces = `query ListMGWInterfaces(
  $filter: ModelMGWInterfaceFilterInput
  $limit: Int
  $nextToken: String
  ) {
    listMGWInterfaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      active
      server {
        id
        name
        claimsCanAccess
        claimsCanEdit
      }
      claimsCanAccess
      claimsCanEdit
      version
      createdAt
      updatedAt
    }
  }
}`;
export const listMGWServers = `query ListMGWServers(
  $filter: ModelMGWServerFilterInput
  $limit: Int
  $nextToken: String
  ) {
    listMGWServers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      interfaces {
        items {
          id
          name
          active
          claimsCanAccess
          claimsCanEdit
        }
      }
      claimsCanAccess
      claimsCanEdit
      version
      createdAt
      updatedAt
    }
  }
}`;

export const listMultiviewers = `query ListMultiviewers(
  $filter: ModelMultiviewerFilterInput
  $limit: Int
  $nextToken: String
  ) {
    listMultiviewers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      grid
      claimsCanAccess
      claimsCanEdit
      version
      createdAt
      updatedAt
      type
      audioControlId
      routableDestinationId
      streamSources {
        items {
          id
          name
          sortOrder
          claimsCanAccess
          claimsCanEdit
          createdAt
          updatedAt
          streamSource {
            id
            name
            description
            type
            attachedTo
            address
            port
            talkbackAddress
            talkbackPort
            latencyMs
            bufferMinMs
            bufferMaxMs
            encPassphrase
            encKeyLen
            encType
            udpAddress
            udpPort
            claimsCanAccess
            claimsCanEdit
            version
            createdAt
            updatedAt
          }
        }
      }
    }
  }
}`;
