<template>
  <div class="agenda">
    <div  class="wsrapper">
      <div>
        <div>
          <div class="sticky-date">{{stickyDate}}</div>
          <div class="select-multible">
            <span class="btn btn-sm bg-gray-800" v-show="!showCheck" @click="showCheck = true">Select Events</span>
            <span class="btn bg-black" v-show="showCheck" @click="showCheck = false">Cancel</span>
            <span class="btn text-white ml-2" v-show="showCheck" @click="showEdit">Edit Selected ({{selectedEvents.length}})</span>
            <span class="btn text-white ml-2" v-show="showCheck" @click="showExportDialog = true">Show Selected ({{selectedEvents.length}})</span>
          </div>
          <div class="agenda-header agenda-header-columns">
            <div v-for="col in visibleColumnDefs" :key="col.headerName">
              {{col.headerName}}
            </div>
            <div class="agenda-header-filter">
              <el-popover placement="bottom"
              height="400"
              trigger="click">
                <div class="agenda-filter-popup" >
                  <strong>Filters</strong><br>
                  <label class="group-label">Show bookings:</label>
                  <el-radio-group @change="filterChanged" v-model="filter.pastEvents" size="small">
                    <el-radio-button label="past">Past</el-radio-button>
                    <el-radio-button label="future">Future</el-radio-button>
                    <el-radio-button label="all">All</el-radio-button>
                  </el-radio-group>
                  <label class="group-label">Accounts:</label>
                  <div style="max-height: 300px; overflow-y: auto;">
                    <div v-for="(client, key) in filter.clients" :key="'filter-' + key">
                      <el-checkbox
                      @change="filterChanged"
                        :label="key" v-model="client.value">
                      </el-checkbox>
                    </div>
                  </div>
                  <br>
                  <el-button-group>
                    <el-button type="primary" size="mini" @click="toggleClientFilter(true)">Check all</el-button>
                    <el-button type="primary" size="mini" @click="toggleClientFilter(false)">Uncheck all</el-button>
                  </el-button-group>
                </div>
                <i slot="reference" class="bi bly-filter icon-btn"
                :class="{active: isFilterActive}"></i>
              </el-popover>
            </div>
            <div class="agenda-header-settings">
              <el-popover placement="bottom"
              trigger="click">
                Hide/Show columns <br>
                <div v-for="col in columnDefs" :key="'x' + col.headerName">
                  <el-checkbox
                    :label="col.headerName" v-model="col.visible">
                  </el-checkbox>
                </div>
                <i slot="reference" class="bi bly-more icon-btn"></i>
              </el-popover>
            </div>
          </div>
          <div class="agenda-day-group" v-view="viewHandler" :data-day="key"
            v-for="(day, key) in groupCurrentEvents"
            :key="key">
            <div class="agenda-day-group-name">
              <div class="agenda-day-group-name-name"
                :class="{today: groupsIsTodayFlags[key]}">
                <span>{{key | dateUtc('DD/MM/YYYY')}}</span>
              </div>
            </div>
            <div
                :class="{'event-group': event.extendedProps.groupId}"
                v-for="event in day" :key="event.id">
              <div class="collapse" v-if="event.extendedProps.groupId && eventGroups[event.extendedProps.groupId].length > 1"
                @click="toggleGroup(event)">
                  <svg v-show="collapsedEvents[event.id]" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <svg v-show="!collapsedEvents[event.id]" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
              </div>

              <!-- GROUP HEADER -->
              <div class="agenda-day-event"
                v-if="event.extendedProps.groupId && eventGroups[event.extendedProps.groupId].length > 1"
                @click="toggleGroup(event)">

                <div class="agenda-day-event-name"
                  :class="event.status">
                  <el-tooltip content="MOS CHECKED">
                    <span class="moschecked" v-if="event.extendedProps.mosChecked && isSysAdmin">
                      <i class="bi bly-check"></i>
                    </span>
                  </el-tooltip>
                  <span class="fc-event-account">
                    <span v-if="event.extendedProps.commentaryLanguage"
                      class="commentary-language"><i class="fas fa-globe-americas"></i> {{event.extendedProps.commentaryLanguage}}</span>
                      GROUP
                      <span class="vertical-divinder"></span>
                    <span class="fc-event-account-client">{{event.extendedProps.customer.name}}</span>
                  </span>
                   [ GROUP ] {{event.extendedProps.name}} {{eventGroups[event.extendedProps.groupId].length}}
                   <span v-for="status in groupStatuses(groupEventsStatuses[event.extendedProps.groupId])" :key="status"
                    class="group-statuses w-3 h-3 inline-block ml-1 rounded transform translate-y-px"
                    :class="status"></span>
                  <div class="agenda-day-event-name-description ml-1">
                    {{event.extendedProps.description}}
                  </div>
                </div>
                <div class="agenda-day-event-details">
                  <div v-for="col in visibleColumnDefs" :key="'i' + col.headerName"
                  :class="'agenda-day-event-' + col.headerName">
                    <span class="agenda-label">{{col.headerName}}</span>
                    <span class="time" v-if="col.type === 'time'">
                      {{event.extendedProps[col.field] | dateUtc('H:mm')}}
                    </span>
                    <span class="other" v-if="col.type === 'html' && col.field !== 'output' && col.field !== 'audio'"
                      v-html='event.extendedProps[col.field]'>
                    </span>
                    <span class="other"
                      v-if="!col.type && col.field !== 'audio'"
                      :class="{warning: event.extendedProps[col.field] === 'not set'}"
                      >
                      {{event.extendedProps[col.field]}}
                    </span>
                  </div>
                </div>
                <div class="agenda-day-event-actions">
                </div>
              </div>
              <!-- END GROUP HEADER -->

              <!-- NORMAL EVENT HEADER-->
              <div class="agenda-day-event"
                v-if="!event.extendedProps.groupId || eventGroups[event.extendedProps.groupId].length <= 1"
                :class="{ active: isEventActvie(event.extendedProps.id), 'highlight-event': eventsFlags[event.id].highlight  }"
                @click="setActiveEvent(event.extendedProps)">

                <div class="agenda-day-event-name cursor-pointer"
                  @click="toggleSelection(event.extendedProps)"
                  :class="event.status">
                  <el-tooltip content="MOS CHECKED">
                    <span class="moschecked" v-if="event.extendedProps.mosChecked && isSysAdmin">
                      <i class="bi bly-check"></i>
                    </span>
                  </el-tooltip>
                  <span class="fc-event-account">
                    <span v-if="event.extendedProps.commentaryLanguage"
                      class="commentary-language"><i class="fas fa-globe-americas"></i> {{event.extendedProps.commentaryLanguage}}</span>
                      {{event.extendedProps.account.name}}
                    <span class="vertical-divinder"></span>
                    <span class="fc-event-account-client">{{event.extendedProps.customer.name}}</span>
                  </span>
                  <label class="sm:inline-flex items-center" :for="event.id + 'x'">
                    <input type="checkbox" class="form-checkbox mr-2" v-show="showCheck" :name="event.id + 'x'" :checked="getSelectedIndex(event.extendedProps) !== -1">
                    {{event.extendedProps.name}}
                    <div class="agenda-day-event-name-description">
                      {{event.extendedProps.description}}
                    </div>

                  </label>
                </div>
                <div class="agenda-day-event-details">
                  <div v-for="col in visibleColumnDefs" :key="'i' + col.headerName"
                  :class="'agenda-day-event-' + col.headerName">
                    <span class="agenda-label">{{col.headerName}}</span>
                    <span class="time" v-if="col.type === 'time'">
                      {{event.extendedProps[col.field] | dateUtc('H:mm')}}
                    </span>
                    <span class="other" v-if="col.type === 'html'"
                      v-html='event.extendedProps[col.field]'>
                    </span>
                    <span class="other"
                      v-if="!col.type"
                      :class="{warning: event.extendedProps[col.field] === 'not set'}"
                      >
                      {{event.extendedProps[col.field]}}
                    </span>
                  </div>
                </div>
                <div class="agenda-day-event-actions">
                  <el-tooltip content="MOS Check/Uncheck"
                    v-show="isSysAdmin">
                    <!-- <i class="bi bly-check orange icon-btn" @click="save('mosChecked', !event.extendedProps.mosChecked, event.extendedProps)"></i> -->
                    <!-- <el-switch v-model="event.extendedProps.mosChecked" @click.native="save('mosChecked', event.extendedProps.mosChecked, event.extendedProps)"></el-switch> -->
                    <button type="button" class="top-0.5 bg-gray-200 mt-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" role="switch" aria-checked="false"
                        @click="save('mosChecked', event.extendedProps.mosChecked, event.extendedProps)">
                        <span
                          :class="{'translate-x-0': !event.extendedProps.mosChecked, 'translate-x-5': event.extendedProps.mosChecked}"
                          aria-hidden="true" class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
                      </button>
                  </el-tooltip>
                  <el-tooltip content="Download PDF">
                    <i class="bi bly-file icon-btn"
                      @click="createPdf(event.extendedProps)"></i>
                  </el-tooltip>
                  <el-tooltip content="Duplicate booking"
                    v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator">
                    <i class="bi bly-clipbin icon-btn"
                      @click="duplicateBooking(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Reschedule"
                    v-if='!event.isPastServiceTime'
                    v-show="isAdmin || acl.clientAdmin || acl.ee">
                    <i class="bi bly-opening-times icon-btn"
                      @click="setRescheduleEventDialog(true)"></i>
                  </el-tooltip>
                  <el-tooltip content="Nothing to report"
                    v-if="event.status === 'LIVE' || event.status === 'COMPLETED'"
                    v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                    <i class="bi bly-check green icon-btn" @click="sendNothingToReport(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Create report"
                    v-if="event.status === 'LIVE' || event.status === 'COMPLETED'"
                    v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                    <i class="bi bly-warning-2 icon-btn" @click="openReportDialog(event)"></i>
                  </el-tooltip>
                  <el-tooltip content="Cancel booking"
                    v-if="event.status === 'LIVE' || !event.isPastServiceTime"
                    v-show="event.extendedProps.status !== 'REJECTED'">
                    <i class="bi bly-close icon-btn red"
                      @click="confirmDelete(event)"></i>
                  </el-tooltip>
                </div>
              </div>
              <!-- END NORMAL EVENT HEADER-->

              <!-- GROUP EVENTS-->
              <div class="group-events"
                :class="{collapsed: collapsedEvents[event.id]}"
                v-if="event.extendedProps.groupId && !collapsedEvents[event.id]">
                <div class="agenda-day-event"
                  v-for="groupEvent in eventGroups[event.extendedProps.groupId]" :key="groupEvent.id"
                  :class="{ active: isEventActvie(groupEvent.extendedProps.id), 'bg-red-500': eventsFlags[groupEvent.id].highlight  }"
                  @click="setActiveEvent(groupEvent.extendedProps)">
                  <div class="agenda-day-event-name"
                    @click="toggleSelection(groupEvent.extendedProps)"
                    :class="groupEvent.status">
                    <el-tooltip content="MOS CHECKED">
                      <span class="moschecked" v-if="groupEvent.extendedProps.mosChecked && isSysAdmin">
                        <i class="bi bly-check"></i>
                      </span>
                    </el-tooltip>
                    <span class="fc-event-account">
                      <span v-if="groupEvent.extendedProps.commentaryLanguage"
                        class="commentary-language"><i class="fas fa-globe-americas"></i> {{groupEvent.extendedProps.commentaryLanguage}}</span>
                        {{groupEvent.extendedProps.account.name}}
                      <span class="vertical-divinder"></span>
                      <span class="fc-event-account-client">{{groupEvent.extendedProps.customer.name}}</span>
                    </span>
                    <label class="inline-flex items-center" :for="groupEvent.id + 'x'">
                      <input type="checkbox" class="form-checkbox mr-2" v-show="showCheck" :name="groupEvent.id + 'x'" :checked="getSelectedIndex(groupEvent.extendedProps) !== -1">
                      {{groupEvent.extendedProps.name}}
                      <div class="agenda-day-event-name-description">
                        {{groupEvent.extendedProps.description}}
                      </div>

                    </label>
                  </div>
                  <div class="agenda-day-event-details">
                    <div v-for="col in visibleColumnDefs" :key="'i' + col.headerName"
                    :class="'agenda-day-event-' + col.headerName">
                      <span class="agenda-label">{{col.headerName}}</span>
                      <span class="time" v-if="col.type === 'time'">
                        {{groupEvent.extendedProps[col.field] | dateUtc('H:mm')}}
                      </span>
                      <span class="other" v-if="col.type === 'html'"
                        v-html='groupEvent.extendedProps[col.field]'>
                      </span>
                      <span class="other"
                        v-if="!col.type"
                        :class="{warning: groupEvent.extendedProps[col.field] === 'not set'}"
                        >
                        {{groupEvent.extendedProps[col.field]}}
                      </span>
                    </div>
                  </div>
                  <div class="agenda-day-event-actions flex items-center">
                    <bly-tooltip text="MOS Check/Uncheck"
                      v-show="isSysAdmin">
                      <!-- <el-switch v-model="groupEvent.extendedProps.mosChecked" @click.native="save('mosChecked', groupEvent.extendedProps.mosChecked, event.extendedProps)"></el-switch> -->
                      <button type="button" class="top-0.5 bg-gray-200 mt-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" role="switch" aria-checked="false"
                        @click="save('mosChecked', groupEvent.extendedProps.mosChecked, event.extendedProps)">
                        <span
                          :class="{'translate-x-0': !groupEvent.extendedProps.mosChecked, 'translate-x-5': groupEvent.extendedProps.mosChecked}"
                          aria-hidden="true" class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
                      </button>
                    </bly-tooltip>
                    <bly-tooltip text="Download PDF">
                      <i class="bi bly-file icon-btn"
                        @click="createPdf(groupEvent.extendedProps)"></i>
                    </bly-tooltip>
                    <bly-tooltip text="Duplicate booking"
                      v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator">
                      <i class="bi bly-clipbin icon-btn"
                        @click="duplicateBooking(groupEvent)"></i>
                    </bly-tooltip>
                    <bly-tooltip text="Reschedule"
                      v-if='!groupEvent.isPastServiceTime'
                      v-show="isAdmin || acl.clientAdmin || acl.ee">
                      <i class="bi bly-opening-times icon-btn"
                        @click="setRescheduleEventDialog(true)"></i>
                    </bly-tooltip>
                    <bly-tooltip text="Nothing to report"
                      v-if="groupEvent.status === 'LIVE' || groupEvent.status === 'COMPLETED'"
                      v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                      <i class="bi bly-check green icon-btn" @click="sendNothingToReport(groupEvent)"></i>
                    </bly-tooltip>
                    <bly-tooltip text="Create report"
                      v-if="groupEvent.status === 'LIVE' || groupEvent.status === 'COMPLETED'"
                      v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                      <i class="bi bly-warning-2 icon-btn" @click="openReportDialog(groupEvent)"></i>
                    </bly-tooltip>
                    <bly-tooltip text="Cancel booking"
                      v-if="groupEvent.status === 'LIVE' || !groupEvent.isPastServiceTime"
                      v-show="groupEvent.extendedProps.status !== 'REJECTED'">
                      <i class="bi bly-close icon-btn red"
                        @click="confirmDelete(groupEvent)"></i>
                    </bly-tooltip>
                     <!-- <el-tooltip content="MOS Check/Uncheck"
                      v-show="isSysAdmin">

                    </el-tooltip> -->
                    <!-- <el-tooltip content="Download PDF">

                    </el-tooltip>
                    <el-tooltip content="Duplicate booking"
                      v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator">
                      <i class="bi bly-clipbin icon-btn"
                        @click="duplicateBooking(groupEvent)"></i>
                    </el-tooltip> -->
                    <!-- <el-tooltip content="Reschedule"
                      v-if='!groupEvent.isPastServiceTime'
                      v-show="isAdmin || acl.clientAdmin || acl.ee">
                      <i class="bi bly-opening-times icon-btn"
                        @click="setRescheduleEventDialog(true)"></i>
                    </el-tooltip> -->
                    <!-- <el-tooltip content="Nothing to report"
                      v-if="groupEvent.status === 'LIVE' || groupEvent.status === 'COMPLETED'"
                      v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                      <i class="bi bly-check green icon-btn" @click="sendNothingToReport(groupEvent)"></i>
                    </el-tooltip> -->
                    <!-- <el-tooltip content="Create report"
                      v-if="groupEvent.status === 'LIVE' || groupEvent.status === 'COMPLETED'"
                      v-show="isAdmin || acl.clientAdmin || acl.ee || acl.eo">
                      <i class="bi bly-warning-2 icon-btn" @click="openReportDialog(groupEvent)"></i>
                    </el-tooltip> -->
                    <!-- <el-tooltip content="Cancel booking"
                      v-if="groupEvent.status === 'LIVE' || !groupEvent.isPastServiceTime"
                      v-show="groupEvent.extendedProps.status !== 'REJECTED'">
                      <i class="bi bly-close icon-btn red"
                        @click="confirmDelete(groupEvent)"></i>
                    </el-tooltip> -->
                  </div>
                </div>
              </div>
              <!-- END GROUP EVENTS-->
            </div>
          </div>
        </div>
      </div>
      <div v-html="agendaHeaderStyle"></div>
    </div>
    <!-- -------------------------------------------------------------------------- -->
    <!-- EVENT REPORT ------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Create report for booking"
      :visible.sync="reportEventDialog"
      width="80%"
      :close-on-click-modal="false">
      <div class="send-report-dialog">
        <el-row :gutter="24">
          <el-col :span="16">
            <el-form ref="createReportForm" :model="reportForm" label-position="top">
              <strong>Issue description</strong>
              <quill-editor v-model="reportForm.description"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)">
              </quill-editor>
            </el-form>
          </el-col>
          <el-col :span="8">
            <div v-if="eventToReport" class="tx-report-booking-details">
              <strong>{{eventToReport.name}}</strong>
              <el-row>
                <el-col :span="8">
                  <div class="label">Line-up</div>
                  <div class="content">{{eventToReport.lineupTime | date('HH:mm')}}</div>
                </el-col>
                <el-col :span="8">
                  <div class="label">Kick-off</div>
                  <div class="content">{{eventToReport.startTime | date('HH:mm')}}</div>
                </el-col>
                <el-col :span="8">
                  <div class="label">End Time</div>
                  <div class="content">{{eventToReport.endTime | date('HH:mm')}}</div>
                </el-col>
              </el-row>

              <div class="label">Fixture</div>
              <div class="content">{{eventToReport.fixture | defaultValue('---')}}</div>

              <div class="label">Property</div>
              <div class="content">{{eventToReport.property | defaultValue('---')}}</div>

              <div class="label">Sport</div>
              <div class="content">{{eventToReport.sport | defaultValue('---')}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reportEventDialog = false">Cancel</el-button>
        <el-button type="primary" @click="sendReport" v-loading="loading">Create</el-button>
      </span>
    </el-dialog>


     <!-- -------------------------------------------------------------------------- -->
    <!-- BATCH EVENT EDIT --------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Events"
      :visible.sync="showEditDialog"
      width="360px"
      :close-on-click-modal="false">
      <div class="w-full send-report-dialog select-text" id="edit-events">
        <div>
          {{selectedEvents.length}} event<span v-if="selectedEvents.length > 1">s</span> selected!
        </div>
        <div class="mt-3 uppercase text-gray-400" v-if="batchEdit.sameStatus">Update status</div>
        <div class="flex flex-wrap mb-2 justify-evenly" v-if="batchEdit.sameStatus">
          <div class="px-4 py-2 uppercase text-center border-2 border-yellow-500 cursor-pointer rounded-md flex items-center"
            :class="{'bg-yellow-500 text-white': batchEdit.status === 'RECEIVED'}" @click="batchEdit.status = 'RECEIVED'" v-if="batchEdit.originalStatus === 'CLIENT_CONFIRMED' && isSysAdmin">
            RECEIVED
          </div>
          <div class="px-4 py-2 uppercase text-center border-2 border-yellow-500 cursor-pointer rounded-md flex items-center"
            :class="{'bg-yellow-500 text-white': batchEdit.status === 'AWAITING_SIGNAL'}" @click="batchEdit.status = 'AWAITING_SIGNAL'" v-if="(batchEdit.originalStatus === 'CLIENT_CONFIRMED' || batchEdit.originalStatus === 'RECEIVED') && isSysAdmin">
            AWAITING <br> SIGNAL
          </div>
          <div class="px-4 py-2 uppercase text-center border-2 border-yellow-500 cursor-pointer rounded-md flex items-center"
            :class="{'bg-yellow-500 text-white': batchEdit.status === 'CONFIRMED'}" @click="batchEdit.status = 'CONFIRMED'"
            v-if="(batchEdit.originalStatus === 'CLIENT_CONFIRMED' || batchEdit.originalStatus === 'RECEIVED' || batchEdit.originalStatus === 'AWAITING_SIGNAL') && isSysAdmin">
            MOS <br>Confirm
          </div>
        </div>
        <div class="mt-3 uppercase text-gray-400" v-if="batchEdit.sameDates">Reschedule</div>
        <div class="mb-2" v-if="batchEdit.sameDates">
          <el-collapse-transition>
            <el-alert type="warning" v-if="isCloseToServiceStartTime">
              Late Event. This event is close to start time needs to be started manually
            </el-alert>
          </el-collapse-transition>
          <el-collapse-transition>
            <el-alert type="warning" v-if="doesEventStarted">
              Late Event. This event is after start time needs to be started manually
            </el-alert>
          </el-collapse-transition>
          <el-form ref="rescheduleForm" :model="rescheduleForm" label-position="top">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-form-item size="small" label="Line-up date (GMT)" prop="lineupDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.lineupDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select line-up date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Line-up time (GMT)" prop="lineupTime" required>
                  <el-time-picker
                    value-format="HH:mm:ss"
                    v-model="rescheduleForm.lineupTime"
                    type="datetime"
                    format="HH:mm"
                    placeholder="Select line-up Time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Start date (GMT)" prop="startDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.startDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select start date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Start Time (GMT)" prop="startTime" required>
                  <el-time-picker
                    v-model="rescheduleForm.startTime"
                    value-format="HH:mm:ss"
                    format="HH:mm"
                    placeholder="Select start time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="End date (GMT)" prop="endDate" required>
                  <el-date-picker
                    v-model="rescheduleForm.endDate"
                    :picker-options="datePickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="Select end date"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="End time (GMT)" prop="endTime" required>
                  <el-time-picker
                    v-model="rescheduleForm.endTime"
                    value-format="HH:mm:ss"
                    format="HH:mm"
                    placeholder="Select end time"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item size="small" label="Add time" prop="tailTimeMin">
                  <el-input type="number" v-model="rescheduleForm.tailTimeMin"
                    size="small"
                    placeholder="Please input time in minutes"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="showEditDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveBatch" v-loading="loading">Save</el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- EVENT Export ------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Events"
      :visible.sync="showExportDialog"
      width="360px"
      :close-on-click-modal="false">
      <div class="w-full send-report-dialog space-x-2 select-text" id="export-events">
        <div class="export-event align-top max-w-lg block space-y-1 mb-2 pl-0" v-for="event in sortedSelectedEvents" :key="event.id">
          <div class="xxx"><span class="data text-black font-bold text-lg">{{event.fixture}}</span></div>
          <div class=""><span class="label text-gray-700">Property:</span> <span class="data text-black">{{event.property}}</span></div>
          <div class=""><span class="label text-gray-700">Sport:</span> <span class="data text-black">{{event.sport}}</span></div>
          <div class="xxx"><span class="label text-gray-700">Description:</span> <span class="data text-black inline-block">{{event.description}}</span></div>
          <div class="xxx" v-if="event.overrideInputName"><span class="label text-gray-700" >Source:</span> <span class="data text-black font-bold inline-block" >{{event.inputOriginalName}} <span v-if="event.inputDetails">({{event.inputDetails}})</span></span></div>
          <div class="xxx"><span class="label text-gray-700">Input:</span> <span class="data text-black font-bold inline-block" >{{event.overrideInputName || event.inputOriginalName}}</span></div>
          <!-- <div class="xxx"><span class="label text-gray-700 align-top">Output:</span> <span class="data text-black font-bold inline-block">{{event.outputString}}</span></div> -->
          <div>---------------------------------</div>
          <div class="xxx"><span class="label text-gray-700">Line-up:</span> <span class="data text-black inline-block">{{moment.utc(event.lineupTime).format('YYYY-MM-DD')}} <span class="ml-4">- {{moment.utc(event.lineupTime).format('HH:mm')}}</span> GMT</span></span></div>
          <div class="xxx"><span class="label text-gray-700">Start:</span> <span class="data text-black inline-block">{{moment.utc(event.startTime).format('YYYY-MM-DD')}} <span class="ml-4">- {{moment.utc(event.startTime).format('HH:mm')}}</span> GMT</span></span></div>
          <div class="xxx"><span class="label text-gray-700">End:</span> <span class="data text-black inline-block">{{moment.utc(event.endTime).format('YYYY-MM-DD')}} <span class="ml-4">- {{moment.utc(event.endTime).format('HH:mm')}}</span> GMT</span></div>
          <hr class="border-black mt-4">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="copyToClipboard('export-events')">Copy</el-button>
        <el-button type="primary" @click="showExportDialog = false" v-loading="loading">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { mapState, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';

const moment = extendMoment(Moment);

export default {
  data() {
    return {
      stickyDate: '',
      // grid data
      moment,
      selectedEvents: [],
      showExportDialog: false,
      showEditDialog: false,
      showCheck: false,
      batchEdit: {
        status: 'RECIVED',
        originalStatus: 'RECIVED',
        sameStatus: true,
      },
      columnDefs: [
        {
          headerName: 'Line-up',
          field: 'lineupTime',
          sortable: true,
          visible: true,
          width: 80,
          type: 'time',
        },
        {
          headerName: 'Kick-off',
          field: 'startTime',
          sortable: true,
          visible: true,
          width: 70,
          type: 'time',
        },
        {
          headerName: 'End time',
          field: 'endTime',
          visible: true,
          width: 70,
          type: 'time',
        },
        {
          headerName: 'Sport',
          field: 'sport',
          sortable: true,
          visible: true,
          width: 100,
        },
        {
          headerName: 'Property',
          field: 'property',
          sortable: true,
          visible: true,
          width: 100,
        },
        /*
        {
          headerName: 'Fixture',
          field: 'fixture',
          visible: true,
          width: 110,
        },

        {
          headerName: 'Client',
          field: 'customerName',
          sortable: true,
          visible: true,
          width: 130,
        },
        */
        {
          headerName: 'Input',
          field: 'inputName',
          sortable: true,
          visible: true,
          type: 'html',
          width: 130,
        },
        {
          headerName: 'Outputs',
          field: 'output',
          sortable: true,
          type: 'html',
          visible: true,
          width: 240,
        },
        {
          headerName: 'Audio',
          field: 'audio',
          sortable: true,
          visible: true,
          width: 'auto',
        },
      ],

      reportForm: {
        ntr: false,
        eventId: null,
        description: null,
      },
      eventGroups: {},
      editorOption: {
        theme: 'bubble',
        placeholder: 'Describe the issue',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
      },
      // Reported Event Details
      reportFormEvent: null,
      eventToReport: null,
      reportEventDialog: false,
      rescheduleEventDialog: false,
      rescheduleForm: {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      },
      datePickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < new Date(new Date().setDate(new Date().getDate() - 1))
          );
        },
      },
      filter: {
        pastEvents: 'all',
        clients: [],
      },
      collapsedEvents: {},
      groupEventsStatuses: {},
      groupsIsTodayFlags: {},
      baseEventList: [],
      currentEvents: [],
      groupCurrentEvents: {},
      eventsFlags: {},
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      account: (state) => state.userAccount.account,
      client: (state) => state.userAccount.client,
      clientAccounts: (state) => state.userAccount.clientAccounts,
      clients: (state) => state.userAccount.listClients,
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,

      selectedEvent: (state) => state.schedule.selectedEvent,
      loading: (state) => state.schedule.loading,
      events: (state) => state.schedule.events,
      listEvents: (state) => state.schedule.listEvents,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventTypes: (state) => state.schedule.eventTypes,
      viewsSettings: (state) => state.schedule.viewsSettings,
      inputs: (state) => state.schedule.inputs,
      outputs: (state) => state.schedule.outputs,
      showAllEvents: (state) => state.schedule.showAllEvents,
      showAllActiveEvents: (state) => state.schedule.showAllActiveEvents,
      showCanceled: (state) => state.schedule.showCanceled,

      scheduleDateRange: (state) => state.schedule.scheduleDateRange,
      eventsWithTheSameOTT: (state) => state.schedule.eventsWithTheSameOTT,
    }),

    sortedSelectedEvents() {
      return this.selectedEvents.sort((left, right) => moment.utc(left.serviceStartTime)
          .diff(moment.utc(right.serviceStartTime)));
    },

    getSelectedIndex() {
      return (event => {
        let index = -1;
        this.selectedEvents.forEach((ev, i) => {
          if (event.id === ev.id) index = i;
        });

        return index;
      });
    },

    accounts() {
      const doesAccountExist = !!this.clientAccounts;
      if (!doesAccountExist) return [];
      return this.clientAccounts.map((account) => account.id);
    },

    accountsNames() {
      const doesAccountExist = !!this.clientAccounts;
      if (!doesAccountExist) return [];
      return this.clientAccounts.map((account) => account.name);
    },

    allAccounts() {
      const doesClientsExist = !!this.clients && !!this.clients.items;
      if (!doesClientsExist) return [];

      const accounts = [];
      this.clients.items.forEach((client) => {
        client.accounts.items.forEach((account) => accounts.push(account.name));
      });
      return accounts;
    },

    visibleColumnDefs() {
      return this.columnDefs.filter((column) => column.visible);
    },

    isEventActvie() {
      return (id) => this.selectedEvent && this.selectedEvent.id === id;
    },

    agendaHeaderStyle() {
      let gridColumns = '';
      this.columnDefs.forEach((column) => {
        const isVisible = column.visible;
        const isAuto = column.width === 'auto';
        if (!isVisible) return;
        gridColumns += isAuto ? ' auto' : `${column.width}px `;
      });
      const style = `
        <style>
          .agenda-header-columns, .agenda-day-event-details {
            grid-template-columns: ${gridColumns};
          }
        </style>
      `;
      return style;
    },

    groupStatuses() {
      return statuses => [...new Set(statuses)];
    },

    filterClients() {
      if (this.showAllEvents) {
        return this.allAccounts;
      }
      const doesAccountExist = Array.isArray(this.clientAccounts);
      if (!doesAccountExist) return [];
      const clients = this.clientAccounts.map((item) => item.name);
      return _.union(clients);
    },

    claimsCanEdit() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [`ee.${accountId}.${clientId}`, `eo.${accountId}.${clientId}`];
    },

    claimsCanAccess() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
      ];
    },

    isFilterActive() {
      let isClientFilterInactive = false;
      Object.keys(this.filter.clients).forEach((client) => {
        if (!this.filter.clients[client].value) isClientFilterInactive = true;
      });
      return this.filter.pastEvents !== 'all' || isClientFilterInactive;
    },
    // isToday() {
    //   return (date) => {
    //     console.log('someDate', date);
    //     const today = new Date();
    //     const someDate = new Date(date);
    //     return someDate.getDate() == today.getDate()
    //       && someDate.getMonth() == today.getMonth()
    //       && someDate.getFullYear() == today.getFullYear();
    //   };
    // },
    serviceStartTimeOffsetMin() {
      return moment(this.selectedEvent.lineupTime).diff(this.selectedEvent.serviceStartTime, 'minutes');
    },

    isStartTimeModified() {
      if (!this.rescheduleForm || !this.rescheduleForm.startTime || !this.rescheduleForm.startDate) return false;
      return !(moment.utc(`${this.rescheduleForm.startDate} ${this.rescheduleForm.startTime}`).isSame(moment(this.selectedEvent.startTime)));
    },

    isEndTimeModified() {
      if (!this.rescheduleForm || !this.rescheduleForm.endTime || !this.rescheduleForm.endDate) return false;
      return !(moment.utc(`${this.rescheduleForm.endDate} ${this.rescheduleForm.endTime}`).isSame(moment(this.selectedEvent.endTime)));
    },

    isCloseToServiceStartTime() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const before = moment.utc(this.rescheduleForm.startTime, format).subtract('1', 'hours');
      const currentStartTime = moment.utc(this.rescheduleForm.startTime, format);
      const isCurrentDate = moment.utc(this.rescheduleForm.startDate).isSame(new Date(), 'day');
      if (!isCurrentDate) return false;
      return now.isBetween(before, currentStartTime, '[]');
    },

    doesEventStarted() {
      const format = 'HH:mm:ss';
      const now = moment.utc();
      const currentStartTime = moment.utc(this.rescheduleForm.startTime, format);
      const currentEndTime = moment.utc(this.rescheduleForm.endTime, format);
      const isCurrentDate = moment.utc(this.rescheduleForm.startDate).isSame(new Date(), 'day');

      if (!isCurrentDate) return false;
      return now
        .isBetween(currentStartTime, currentEndTime, '[]');
    },

    rescheduleEventDialog: {
      set(value) {
        this.setRescheduleEventDialog(value);
      },
      get() {
        return this.showRescheduleEventDialog;
      },
    },
  },

  watch: {
    listEvents: {
      handler: function (newVal) {
        console.log('%c EVENT LOADED', 'font-size: 20px; color: lime', newVal);
        this.baseEventList = this.getBaseEvents(newVal);
        this.currentEvents = this.getCurrentEvents(this.baseEventList);
        this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
      },
      deep: true,
    },
    filter: {
      handler: function(newValue) {
        console.log('%c filter EVENT LOADED', 'font-size: 20px; color: lime', this.listEvents);
        this.baseEventList = this.getBaseEvents(this.listEvents);
        this.currentEvents = this.getCurrentEvents(this.baseEventList);
        this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
      },
      deep: true
    },
    groupCurrentEvents(newVal) {
      console.log('sticky date', Object.keys(newVal)[0], moment.utc(Object.keys(newVal)[0]).format('DD/MM/YYYY'));
      this.stickyDate = moment.utc(Object.keys(newVal)[0]).format('DD/MM/YYYY');
    },

    filterClients(newVal) {
      this.filter.clients = {};
      const filterString = localStorage.getItem('scheduler-agenda-filter');
      const savedFilter = JSON.parse(filterString);
      const clients = savedFilter ? savedFilter.clients : {};
      console.log('restoreClientsFilter', clients);
      newVal.forEach((client) => {
        const hasValue = !!clients[client];
        Vue.set(this.filter.clients, client, {
          name: client,
          value: hasValue ? clients[client].value : true,
        });
      });
      this.filter.clients = Object.keys(this.filter.clients).sort().reduce(
        (obj, key) => {
          obj[key] = this.filter.clients[key];
          return obj;
        },
        {}
      );

    },
    showAllActiveEvents() {
      this.baseEventList = this.getBaseEvents(this.listEvents);
      this.currentEvents = this.getCurrentEvents(this.baseEventList);
      this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
    },
  },

  created() {
    const filter = JSON.parse(localStorage.getItem('scheduler-agenda-filter'));
    if (filter) {
      this.filter.pastEvents = filter.pastEvents;
    }
  },

  mounted() {
    console.log('mounted', this.filterClients);
    this.restoreClientsFilter(this.filterClients);

    setTimeout(() => {
      window.scroll(0, document.querySelector('.today').offsetTop - 150);
    }, 1800);
    setTimeout(() => {
      const firstGroup = document.querySelector('.agenda-day-group');
      this.stickyDate = moment(firstGroup.dataset.day).utc().format('DD/MM/YYYY');
    }, 500);

    this.baseEventList = this.getBaseEvents(this.listEvents);
    this.currentEvents = this.getCurrentEvents(this.baseEventList);
    this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
  },

  methods: {
    ...mapActions({
      fetchEvents: 'schedule/fetchEvents',
      createEvent: 'schedule/createEvent',
      addBooking: 'schedule/addBooking',
      deleteEvent: 'schedule/deleteEvent',
      updateEvent: 'schedule/updateEvent',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',
      updateEventAudioChannel: 'schedule/updateEventAudioChannel',
      addEventReport: 'schedule/addEventReport',
      rescheduleEvent: 'schedule/rescheduleEvent',

      fetchInputs: 'schedule/fetchInputs',
      fetchOutputs: 'schedule/fetchOutputs',

      startStreamTarget: 'schedule/startStreamTarget',
      stopStreamTarget: 'schedule/stopStreamTarget',
      restartEvent: 'schedule/restartEvent',
      restartIncomingStream: 'schedule/restartIncomingStream',
      updateWowzaTarget: 'schedule/updateWowzaTarget',

      createPdf: 'schedule/createPdf',
    }),
    ...mapMutations({
      setActiveEvent: 'schedule/setActiveEvent',
      setSelectedEventProperty: 'schedule/setSelectedEventProperty',
      setRescheduleEventDialog: 'schedule/setRescheduleEventDialog',
      setShowCreateEventDialog: 'schedule/setShowCreateEventDialog',
      setBookingClone: 'schedule/setBookingClone',
    }),
    restoreClientsFilter(newVal) {
      this.filter.clients = {};
      const filterString = localStorage.getItem('scheduler-agenda-filter');
      const savedFilter = JSON.parse(filterString);
      const clients = savedFilter ? savedFilter.clients : {};
      newVal.forEach((client) => {
        const hasValue = !!clients[client];
        Vue.set(this.filter.clients, client, {
          name: client,
          value: hasValue ? clients[client].value : true,
        });
      });
    },
    async save(property, value, event) {
      console.log('ssssss', property, value, event);
      const newEvent = this.getEventObjForUpdate(event);
      newEvent[property] = value;
      if (property === 'fixture') {
        newEvent.name = value;
      }
      return await this.updateEvent(newEvent);
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        startType: event.startType,
        id: event.id,
        attachments: event.attachments,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        eventLastUpdatedById: this.userId,
      };
    },

    toggleClientFilter(value) {
      console.log('toogle chasdasd', value, this.filter.clients);
      for (const property in this.filter.clients) {
        this.filter.clients[property].value = value;
        console.log(`${property}: ${this.filter.clients[property]}`);
      }
      localStorage.setItem('scheduler-agenda-filter', JSON.stringify(this.filter));
    },

    duplicateBooking(booking) {
      this.setBookingClone(booking);
      this.setShowCreateEventDialog(true);
    },

    openReportDialog(event) {
      this.reportForm.eventId = event.id;
      this.reportFormEvent = event;
      this.reportForm.ntr = false;
      this.eventToReport = event.extendedProps;
      this.reportForm.description = '';
      this.reportEventDialog = true;

      this.reportForm.clientId = this.client.code;
      this.reportForm.accountId = this.account.code;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
    },

    async sendReport() {
      const result = await this.addEventReport(this.reportForm);
      if (!result.error) {
        this.reportEventDialog = false;
        this.eventToReport = null;
      }
    },

    async sendNothingToReport(event) {
      this.reportForm.eventId = event.id;
      this.reportForm.clientId = this.client.code;
      this.reportForm.accountId = this.account.code;
      this.reportForm.ntr = true;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
      delete this.reportForm.description;
      await this.addEventReport(this.reportForm);
    },

    openRescheduleBooking(event) {
      this.rescheduleForm = {
        endTime: event.end.split('T')[1],
        startTime: event.start.split('T')[1],
        eventId: event.id,
        lineupTime: event.lineupTime.split('T')[1],
        expectedVersion: event.extendedProps.version,
        accountId: this.account.code,
        clientId: this.client.code,
        tailTimeMin: event.tailTimeMin,
        headTimeMin: event.headTimeMin,
      };

      this.rescheduleEventDialog = true;
    },

    async rescheduleBooking() {
      const startDate = this.selectedEvent.startTime.split('T')[0];
      const endDate = this.selectedEvent.endTime.split('T')[0];
      const lineupDate = this.selectedEvent.lineupTime.split('T')[0];

      const newTimings = {
        ...this.rescheduleForm,
        lineupTime: moment.utc(`${lineupDate} ${this.rescheduleForm.lineupTime}`)
          .format(),
        startTime: moment.utc(`${startDate} ${this.rescheduleForm.startTime}`)
          .format(),
        endTime: moment.utc(`${endDate} ${this.rescheduleForm.endTime}`)
          .format(),
      };
      const result = await this.rescheduleEvent(newTimings);
      const hasErrors = result.error;
      if (!hasErrors) this.rescheduleEventDialog = false;
    },

    async confirmDelete(event) {
      const confirmation = await this.$confirm('Are you sure you want to cancel booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      const newEvent = this.getEventObjForUpdate(event.extendedProps);
      newEvent.status = 'CANCELLED';
      if (event.extendedProps.status === 'LIVE' || event.isPastServiceTime) newEvent.status = 'LATE_CANCELLED';
      await this.updateEvent(newEvent);
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        id: event.id,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        eventLastUpdatedById: this.userId,
      };
    },
    filterChanged() {
      console.log('xxxx');
      localStorage.setItem('scheduler-agenda-filter', JSON.stringify(this.filter));
    },
    toggleGroup(event) {
      console.log('xxxx', event);
      this.collapsedEvents[event.id] = !this.collapsedEvents[event.id];
      // collapsedEvents[event.id] = !collapsedEvents[event.id];
      // this.$forceUpdate();
    },
    viewHandler(e) {
      // console.log(e.type, e.target.element.dataset.day, e); // 'enter', 'exit', 'progress'
      const { top } = e.target.rect;
      if (top < 146) {
        // console.log('STICK', moment.utc(e.target.element.dataset.day).format('DD/MM/YYYY'));
        this.stickyDate = moment(e.target.element.dataset.day).utc().format('DD/MM/YYYY');
      }
      // console.log(e.percentInView) // 0..1 how much element overlap the viewport
      // console.log(e.percentTop) // 0..1 position of element at viewport 0 - above , 1 - below
      // console.log(e.percentCenter) // 0..1 position the center of element at viewport 0 - center at viewport top, 1 - center at viewport bottom
      // console.log(e.scrollPercent) // 0..1 current scroll position of page
      // console.log(e.scrollValue) // 0..1 last scroll value (change of page scroll offset)
      // console.log(e.target.rect) // element.getBoundingClientRect() result
    },

    toggleSelection(event) {
      const index = this.getSelectedIndex(event);

      if (index === -1) this.selectedEvents.push(event);
      if (index !== -1) this.selectedEvents.splice(index, 1);
    },

    copyToClipboard(containerid) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.getElementById(containerid));
      selection.removeAllRanges(); // Remove all ranges from the selection.
      selection.addRange(range);
      document.execCommand('Copy');
      this.$message.success('Copied to clipboard');
      const sel = window.getSelection ? window.getSelection() : document.selection;
      if (sel) {
          if (sel.removeAllRanges) {
              sel.removeAllRanges();
          } else if (sel.empty) {
              sel.empty();
          }
      }
    },

    getBaseEvents(list) {
      if (!list && !list.items) return [];
      console.log('scheduleDateRange', this.scheduleDateRange);
      const events = list.items ? list.items : [];
      const mappedEvents = events
        .filter((event) => {
          const eventRange =  moment.range(moment.utc(event.serviceStartTime), moment.utc(event.serviceEndTime));
          const calRange = moment.range(moment.utc(this.scheduleDateRange.start).add(1, 'days').startOf('day'), moment.utc(this.scheduleDateRange.end).subtract(2, 'days').endOf('day'));
          const areOverlapping = eventRange.overlaps(calRange);
          console.log('IS EVENT OVERLAPPING', areOverlapping, eventRange.toString(), calRange.toString() )
          return areOverlapping;
        })
        .filter((event) => {
          const isBeforeWeekStart = moment.utc(this.scheduleDateRange.start).add(1, 'days').isBefore(moment.utc(event.endTime));
          const serviceStartIsAfterSelectedDay = moment.utc(this.scheduleDateRange.end).subtract(1, 'days').isBefore(moment.utc(event.serviceStartTime));
          console.log('%c FILTER', 'color:lime;font-weight: bold', isBeforeWeekStart, moment.utc(event.endTime).format(), ':|||:', moment.utc(this.scheduleDateRange.start).format());
          if (serviceStartIsAfterSelectedDay) return false;
          if (!isBeforeWeekStart) return false;
          const isAssignToCurrentAccount = this.account.id === event.account.id;
          const isCanceled = event.status === 'CANCELLED'
            || event.status === 'DELETED'
            || event.status === 'REJECTED';
          const isActive = event.status !== 'COMPLETED'
            && event.status !== 'POSTPONED';
          const isAccountDefault = this.account.id === this.client.id;
          if (isCanceled && !this.showCanceled) return false;
          if (!this.showAllActiveEvents && !isActive) return false;
          if (this.showAllEvents) return true;
          if (isAccountDefault) return this.accounts.indexOf(event.account.id) !== -1;
          return isAssignToCurrentAccount;
        });


      return mappedEvents;
    },
    getCurrentEvents(list) {
      // use base event list
      const mappedEvents = list.map((item) => {
        const nowUtc = moment().utc();
        const flags = {

        };
        const newItem = {
          id: item.id,
          start: item.startTime,
          startServiceTime: item.serviceStartTime,
          lineupTime: item.lineupTime,
          end: item.endTime,
          title: item.name,
          content: item.content,
          classNames: `${item.status} ${item.type}`,
          status: item.status,
          extendedProps: item,
          data: item,
          collapsed: true,
          isPastServiceTime: moment(nowUtc).isAfter(moment(item.serviceEndTime)),
        };
        if (item.customer) newItem.extendedProps.customerName = item.customer.name;

        flags.isToday = moment(nowUtc).isSame(moment.utc(item.startTime), 'day');
        flags.highlight = flags.isToday && (item.status === 'CLIENT_CONFIRMED' || item.status === 'RECIVED' || item.status === 'AWAINTING_SIGNAL');


        const isOptus = 'otu' === newItem.extendedProps.customer.code && 'otu' === newItem.extendedProps.account.code;
        const isDownlink = newItem.extendedProps.input && newItem.extendedProps.input.type === 'DOWNLINK';
        console.log('CURRENT EVENTS CLIENT ACCOUNT', newItem.extendedProps.customer.code, newItem.extendedProps.account.code, isOptus, isDownlink);

        newItem.showRemap = isOptus && isDownlink;
        flags.showRemap = newItem.showRemap;
        newItem.extendedProps.inputName = item.input ? item.input.name : '';
        newItem.extendedProps.inputOriginalName = item.input ? item.input.name : '';
        newItem.extendedProps.inputName += item.inputDetails ? `<div class="muted">${item.inputDetails}</div>` : '';
        newItem.extendedProps.overrideInputName = item.inputOverride ? item.inputOverride.name : '';

        if (newItem.extendedProps.overrideInputName !== '' && this.isSysAdmin) {
          newItem.extendedProps.inputName += ` ${newItem.extendedProps.overrideInputName} (OVERRIDE)`;
        }
        if (newItem.extendedProps.input && newItem.extendedProps.input.type === 'DOWNLINK') {
          if (newItem.extendedProps.inputDownlinkConfig) {
            const {
              BISS,
              satelliteFrequency,
              satelliteName,
              symbolRate,
            } = newItem.extendedProps.inputDownlinkConfig;
            const configMissing = BISS && satelliteFrequency && satelliteName && symbolRate;
            if (!configMissing) newItem.extendedProps.inputName += '<div class="warning"> DOWNLINK CONFIG INCOMPLETE</div>';
          } else {
            newItem.extendedProps.inputName += '<div class="warning">DOWNLINK CONFIG MISSING</div>';
          }
          if (newItem.showRemap) newItem.extendedProps.inputName += '<span class="remap-tag">REMAP</span>';
        }
        newItem.extendedProps.audio = '';
        newItem.extendedProps.audioPDF = '';
        item.audioMapping.items.forEach((channel) => {
          newItem.extendedProps.audio
            += `${channel.channelNo} ${channel.description}
          `;
          newItem.extendedProps.audioPDF += `${channel.channelNo} ${channel.description} \r\n`;
        });
        newItem.extendedProps.output = '';
        newItem.extendedProps.outputPDF = '';
        newItem.extendedProps.outputString = '';
        item.outputs.items
          .filter((output) => !!output)
          .forEach((el) => {
            const [outputConfig] = newItem.extendedProps.actions.items
              .filter((action) => action.assignedOutput.id === el.output.id);
            const hasWowzaConfig = outputConfig && outputConfig.configWowza;
            const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
            const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
            const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
            const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : '';
            // console.log('===> ', el, newItem.extendedProps, outputConfig);

            const urlPdf = el.output.url ? `URL: ${el.output.url}` : '';
            newItem.extendedProps.output += `
              <div class="output-${el.output.status}">
                ${el.output.displayName || el.output.name}
                <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
                <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
                ${hasWowzaConfig ? autostartString : ''}
                <div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>
              </div>
            `;

            const ott = `[${el.output.ottServer} - ${el.output.ottApplication}]`;
            const ottOverrride = `[OTT Override: ${el.outputOttApplicationOverride}]`;
            newItem.extendedProps.outputPDF += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''} ${urlPdf} \r\n`;

            newItem.extendedProps.outputString += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''}, `;
            if (el.outputOttApplicationOverride) {
              newItem.extendedProps.output += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>
              `;
            }
          });

        const hasRelatedEvents = this.eventsWithTheSameOTT.filter((ev) => ev.id === item.id).length > 0;
        if (hasRelatedEvents) newItem.extendedProps.output += '<span class="warning">OVERLAPPING EVENTS WITH THE SAME OUTPUT</span>';
        if (newItem.extendedProps.output === '') newItem.extendedProps.output = 'not set';
        if (newItem.extendedProps.inputName === '') newItem.extendedProps.inputName = 'not set';
        if (newItem.extendedProps.outputPDF === '') newItem.extendedProps.outputPDF = 'not set';
        if (newItem.extendedProps.audioPDF === '') newItem.extendedProps.audioPDF = 'not set';

        newItem.extendedProps.inputPDF = newItem.extendedProps.inputName.replace('<br>', '');

        Vue.set(this.eventsFlags, item.id, flags);
        return newItem;
      }).filter((event) => {
        const { extendedProps } = event;
        const hideByAccount = this.filter.clients[extendedProps.account.name] && this.filter.clients[extendedProps.account.name].value;
        const showFuture = this.filter.pastEvents === 'future' && moment.utc(extendedProps.serviceEndTime).isBefore(moment.utc());

        const showPast = this.filter.pastEvents === 'past' && moment.utc(extendedProps.serviceEndTime).isAfter(moment.utc());
        if (!hideByAccount || showPast || showFuture) return false;
        return true;
      });
      return mappedEvents
        .sort((a, b) => {
          return new Date(a.startServiceTime) - new Date(b.startServiceTime);
        });
    },
    getEventGroupedByDate(list) {
      // return _.groupBy(this.currentEvents, (date) => moment.utc(date.startServiceTime).startOf('day'));
      this.eventGroups = {};
      const events = list
        .filter((event) => {
          const { groupId } = event.extendedProps;
          const hasGroup = !!groupId;
          if (!hasGroup) return true;

          const doesGroupExists = !!this.eventGroups[groupId];
          console.log('%cdoesGroupExists', 'color: orange', groupId, doesGroupExists);
          if (!doesGroupExists) {
            this.eventGroups[groupId] = [];
            this.groupEventsStatuses[groupId] = [];
            Vue.set(this.collapsedEvents, event.id, this.collapsedEvents.hasOwnProperty(event.id) ? this.collapsedEvents[event.id] : true);
            this.eventGroups[groupId].push(event);
            this.groupEventsStatuses[groupId].push(event.status);
            console.log('%c first event', 'color: lime', groupId, event);
            return true;
          }
          this.groupEventsStatuses[groupId].push(event.status);
          this.eventGroups[groupId].push(event);
          console.log('group: ', this.eventGroups[groupId]);
          return false;
        });

      // this.$forceUpdate();

      const groups = _.groupBy(events, date => moment.utc(date.startServiceTime).startOf('day'));
      Object.keys(groups).forEach((key) => {
        console.log('someDate', key);
        const today = new Date();
        const someDate = new Date(key);
        this.groupsIsTodayFlags[key] = someDate.getDate() == today.getDate()
          && someDate.getMonth() == today.getMonth()
          && someDate.getFullYear() == today.getFullYear();
        console.log('%c:: ', 'color: lime', key, groups[key]);
      });

          console.log('groups: ', groups);
      return groups;
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },
    async saveBatch() {
      console.log('saveBatch', this.selectedEvents);
      await this.asyncForEach(this.selectedEvents, async (event) => {
        const updatedEvent = await this.save('status', this.batchEdit.status, event);

        console.log('event Update', this.batchEdit.sameStatus, event, updatedEvent, this.batchEdit);
        await this.rescheduleBooking(updatedEvent);
      });
      await this.$parent.getEvents();
      this.baseEventList = this.getBaseEvents(this.listEvents);
      this.currentEvents = this.getCurrentEvents(this.baseEventList);
      this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
      this.showEditDialog = false;
      this.selectedEvents = [];
    },
    showEdit() {
      this.batchEdit.sameStatus = true;
      this.batchEdit.sameDates = true;
      this.batchEdit.sameTail = true;
      // let initStatus =
      const statuses = this.selectedEvents
        .map((event) => event.status)
        .filter((value, index, self) =>
          index === self
          .findIndex((t) => t.status === value.status)
        )
      this.selectedEvents
        .forEach((value, index, arr) => {
          if (index === 0 || !this.batchEdit.sameDates) return;
          const { lineupTime, startTime, endTime, tailTimeMin } = value;
          const prevEl = arr[index -1];
          if (lineupTime !== prevEl.lineupTime || startTime !== prevEl.startTime || endTime !== prevEl.endTime) this.batchEdit.sameDates = false;
          if (tailTimeMin !== prevEl.tailTimeMin) this.batchEdit.sameTail = false;
        });
      for (const event of this.selectedEvents) {
        console.log('for of event', event);
      }
      // batchEdit: {
      //   status: 'RECIVED',
      //   sameStatus: true,
      // },

      console.log('batch event edit', statuses, this.batchEdit);
      if (statuses.length > 1) this.batchEdit.sameStatus = false;
      this.batchEdit.rescheduleForm = {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      };
      if (this.batchEdit.sameDates) {
        this.rescheduleForm = {
          endDate: this.selectedEvents[0].endTime.split('T')[0],
          startDate: this.selectedEvents[0].startTime.split('T')[0],
          lineupDate: this.selectedEvents[0].lineupTime.split('T')[0],
          eventId: this.selectedEvents[0].id,
          endTime: this.selectedEvents[0].endTime.split('T')[1],
          startTime: this.selectedEvents[0].startTime.split('T')[1],
          lineupTime: this.selectedEvents[0].lineupTime.split('T')[1],
          expectedVersion: this.selectedEvents[0].version,
          accountId:this.selectedEvents[0].account.code,
          clientId: this.selectedEvents[0].customer.code,
          tailTimeMin: this.selectedEvents[0].tailTimeMin,
          headTimeMin: this.selectedEvents[0].headTimeMin,
        };
      }
      if (this.batchEdit.sameTime) {
        this.rescheduleForm = {
          tailTimeMin: this.selectedEvents[0].tailTimeMin,
          headTimeMin: this.selectedEvents[0].headTimeMin,
        };
      }
      this.batchEdit.status = statuses[0];
      this.batchEdit.originalStatus = statuses[0];

      console.log('batch event edit', this.batchEdit);
      this.showEditDialog = true;
    },
    getUpdatedTimes(lineupTime, startTime, endTime, headTimeMin, tailTimeMin) {
      const startDate = this.selectedEvent.startTime.split('T')[0];
      const endDate = this.selectedEvent.endTime.split('T')[0];
      const lineupDate = this.selectedEvent.lineupTime.split('T')[0];


      return {
        ...this.rescheduleForm,
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`)
          .format(),
        startTime: moment.utc(`${startDate} ${startTime}`)
          .format(),
        endTime: moment.utc(`${endDate} ${endTime}`)
          .format(),
        headTimeMin,
        tailTimeMin,
      };
    },
    async rescheduleBooking(event) {
      const {
        lineupTime,
        lineupDate,
        startTime,
        startDate,
        endTime,
        endDate,
        eventId,
        expectedVersion,
        accountId,
        clientId,
      } = this.rescheduleForm;
      const {
        headTimeMin,
        tailTimeMin,
      } = event;

      const head = this.isStartTimeModified ? 0 : headTimeMin;
      const tail = this.isEndTimeModified ? 0 : tailTimeMin;

      const payload = {
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`).format(),
        startTime: moment.utc(`${startDate} ${startTime}`).format(),
        endTime: moment.utc(`${endDate} ${endTime}`).format(),
        headTimeMin: head,
        tailTimeMin: tail,
        eventId: event.id,
        expectedVersion: event.version,
        accountId: event.account.id,
        clientId: event.account.id,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };
      console.log('ddd', payload);
      const result = await this.rescheduleEvent(payload);
      const hasErrors = result.error;
      if (!hasErrors) this.rescheduleEventDialog = false;
    },

    async addTime(event, type, time) {
      const headTime = type === 'head' ? event.headTimeMin + time : event.headTimeMin;
      const tailTime = type === 'tail' ? event.tailTimeMin + time : event.tailTimeMin;

      const startTime = type === 'head' ? moment(event.startTime).subtract(time, 'minutes') : event.startTime;
      const endTime = type === 'tail' ? moment(event.endTime).add(time, 'minutes') : event.endTime;
      const newTimes = {
        ...this.rescheduleForm,
        lineupTime: event.lineupTime,
        startTime,
        endTime,
        headTimeMin: headTime,
        tailTimeMin: tailTime,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };

      delete newTimes.lineupDate;
      delete newTimes.startDate;
      delete newTimes.endDate;
      await this.rescheduleEvent(newTimes);
    },
  },
};
</script>
<style >


.el-loading-spinner svg {
  margin: 0 auto;
}

.highlight-event {
  background: rgba(255, 159, 49, 0.2) !important;
}

.agenda-filter-popup .group-label {
  margin-top: 10px;
  display: inline-block;
}

.output-status-null {
  display: none;
}

.output-DELETED {
  text-decoration: line-through;
}

.event-group {
  position: relative;
}

.collapse {
  position: absolute;
  left: -24px;
}

.h-5 {
  height: 24px;
}

.w-5 {
  width: 24px;
}

.select-multible {
  position: fixed;
  z-index: 11;
  float: right;
  right: 380px;
  margin-top: 2px;
}

.sticky-date {
  position: fixed;
  z-index: 11;
  background: #000;
  width: 138px;
  /* top: 0; */
  margin-top: -5px;
  padding: 11px;
  color: #CFDFFF;
  background: #060809;
  padding: 9px 17px 9px 37px;
  display: inline-block;
  opacity: 1;
  margin-left: -19px;
  font-size: 16px;
}

#agenda-view {
  padding: 20px;
  margin-top: 36px;
}
#agenda-view .wrapper {
  height: calc(100vh - 146px);
}
#agenda-view i.bi.bly-filter.icon-btn.active {
  color: #CFDFFF;
}
#agenda-view i.bi.bly-filter.icon-btn.active:after {
  content: " ";
  display: block;
  width: 9px;
  height: 9px;
  background: red;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  right: 4px;
  border: 2px solid #343c49;
}
#agenda-view .tx-report-booking-details {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 4px;
}
#agenda-view .tx-report-booking-details .content {
  color: #000;
}
#agenda-view .tx-report-booking-details .label {
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 2px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 16px;
}
#agenda-view .agenda-header-columns {
  display: grid;
  outline: 2px solid #13191D;
  grid-gap: 8px;
}
#agenda-view .agenda-header {
  color: #CFDFFF;
  display: grid;
  padding: 12px 16px;
  background: #343c49;
  font-size: 12px;
  font-weight: bold;
  /* border-radius: 3px; */
  position: fixed;
  top: 97px;
  left: 20px;
  right: 379px;
  z-index: 99;
  border-top: 5px solid #13191D;
}
#agenda-view .agenda-header .agenda-header-columns {
  display: grid;
}
#agenda-view .agenda-header .agenda-header-settings {
  float: right;
  position: absolute;
  right: 0;
}
#agenda-view .agenda-header .agenda-header-filter {
  position: absolute;
  right: 40px;
}
#agenda-view .quill-editor,
#agenda-view .quill-code {
  height: 20rem;
}
#agenda-view .agenda-day-group {
  margin-bottom: 16px;
  font-weight: 600;
}
#agenda-view .agenda-day-group-name {
  margin-bottom: 4px;
  display: grid;
  grid-template-columns: 80px 70px 70px 100px 100px 130px 240px auto;
  opacity: 0.7;
  font-size: 11px;
  align-items: center;
}
#agenda-view .agenda-day-group-name div {
  color: #CDD6EE;
  padding-left: 16px;
  padding-top: 4px;
  background: #000;
}
#agenda-view .agenda-day-group-name-name {
  grid-column: span 12;
  background: transparent !important;
}
#agenda-view .agenda-day-group-name-name span {
  color: #CFDFFF;
  background: #000;
  padding: 4px 16px;
  display: inline-block;
  opacity: 1;
  margin-left: -16px;
  font-size: 16px;
}
#agenda-view .agenda-day-group .agenda-day-event {
  padding: 4px 16px;
  background: #232b33;
  border-radius: 3px;
  margin-bottom: 6px;
  align-items: start;
  color: #CFDFFF;
  position: relative;
}
#agenda-view .agenda-day-group .agenda-day-event .agenda-label {
  display: none;
}
#agenda-view .agenda-day-group .agenda-day-event-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #1e242c;
  height: 40px;
  background: linear-gradient(90deg, rgba(30, 36, 44, 0) 0%, rgba(30, 36, 44, 0.91) 40%);
  display: none;
}
#agenda-view .agenda-day-group .agenda-day-event:hover, #agenda-view .agenda-day-group .agenda-day-event.active {
  outline: 2px solid #ff9f31;
  color: #CFDFFF;
  padding: 4px 16px;
}
#agenda-view .agenda-day-group .agenda-day-event:hover .agenda-day-event-actions, #agenda-view .agenda-day-group .agenda-day-event.active .agenda-day-event-actions {
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-details {
  grid-template-columns: 80px 70px 70px 100px 100px 130px 240px auto;
  display: grid;
  grid-gap: 8px;
}
#agenda-view .agenda-day-group .agenda-day-event-name {
  color: #fff;
  background: rgba(6, 8, 9, 0.18);
  padding: 4px 16px;
  margin: -1px -12px 4px -12px;
  border-radius: 3px;
}
#agenda-view .agenda-day-group .agenda-day-event-name .agenda-day-event-name.LIVE:after {
  position: absolute;
  display: block;
  content: " ";
  left: -6px;
  border: 3px solid #fff;
  right: -6px;
  top: -6px;
  bottom: -6px;
}
#agenda-view .agenda-day-group .agenda-day-event-name span.fc-event-account {
  background: #13191D;
  line-height: 30px;
  margin: -6px -16px -4px -16px;
  padding: 0 10px;
  float: right;
  text-transform: uppercase;
  height: 30px;
  font-size: 12px;
}
#agenda-view .agenda-day-group .agenda-day-event-name span.fc-event-account-client {
  color: #CDD6EE;
  margin: 0 -10px 0px 4px;
  display: inline-block;
  padding: 0px 0px 0px;
}
#agenda-view .agenda-day-group .agenda-day-event-name.LIVE:after {
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
  bottom: -6px;
  right: -6px;
  border: 3px solid #fff;
}
#agenda-view .agenda-day-group .agenda-day-event-name-description {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
#agenda-view .agenda-day-group .agenda-day-event-startTime small {
  opacity: 0.7;
  font-size: 11px;
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-endTime small {
  opacity: 0.7;
  font-size: 11px;
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-kickOff small {
  opacity: 0.7;
  font-size: 11px;
  display: block;
}
#agenda-view .agenda-day-group .agenda-day-event-Audio {
  white-space: pre-line;
  margin-top: -16px;
  font-size: 13px;
}
#agenda-view .agenda-day-group .agenda-day-event-Input {
  word-break: break-all;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs {
  word-break: break-all;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs .other div {
  margin-bottom: 2px;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs .show-ma {
  font-size: 80%;
  opacity: 0.8;
  margin-bottom: 4px !important;
}
#agenda-view .agenda-day-group .agenda-day-event-Outputs .show-ma.null {
  display: none;
}
@media screen and (max-width: 1200px) {
  #agenda-view .agenda-day-group .agenda-day-event {
    display: grid;
    padding: 4px 16px;
    grid-template-columns: 70px 70px 70px 100px 120px 150px 100px 150px 150px;
    grid-template-rows: 1fr 1fr;
  }
  #agenda-view .agenda-day-group .agenda-day-event-name {
    grid-column: span 9;
  }
}

.mobile #agenda-view {
  padding: 20px;
  margin-top: 10px;
}
.mobile #agenda-view .wrapper {
  height: calc(100vh - 146px);
  width: 100%;
}
.mobile #agenda-view .agenda-header {
  right: 20px;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event {
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event-name {
  grid-column: span 9;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event-details {
  grid-template-columns: 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-day-event-details > div {
  display: grid;
  grid-template-columns: 120px 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-header-columns {
  grid-template-columns: 1fr;
}
.mobile #agenda-view .agenda-day-group .agenda-label {
  display: inline-block;
  min-width: 120px;
}
.mobile #agenda-view .agenda-day-group-name {
  grid-template-columns: 1fr auto;
}
.mobile #agenda-view .agenda-header-columns {
  display: none;
  grid-template-columns: 1fr;
}

.theme-mini #agenda-view .agenda-day-group {
  font-weight: 400;
}
.theme-mini #agenda-view .commentary-language {
  color: #fff;
  background: #232b33;
  height: 20px;
  position: relative;
  top: 0px;
  padding-top: 7px;
  padding-left: 10px;
  left: -10px;
  padding-right: 11px;
  padding-bottom: 10px;
}
.theme-mini #agenda-view .agenda-day-event-name {
  background: transparent !important;
}
.theme-mini #agenda-view .agenda-day-event-name .agenda-day-event-name-description {
  color: #66718D !important;
}
.theme-mini #agenda-view .agenda-day-event-name span.fc-event-account {
  background: #13191d;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
  float: right;
  text-transform: uppercase;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 6px;
}
.theme-mini #agenda-view .agenda-day-event-name:before {
  display: block;
  position: absolute;
  content: " ";
  background: red;
  top: 4px;
  bottom: 4px;
  width: 5px;
  left: 4px;
}
.theme-mini #agenda-view .agenda-day-event-details {
  padding-left: 4px;
}
.theme-mini .event-preview-drawer #el-drawer__title .client-name {
  font-weight: 400;
}

span.moschecked {
  background: #00ff80;
  font-weight: bold;
  color: #fff;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  padding-top: 4px;
  font-size: 11px;
  margin-right: 4px;
}

.group-events {
  margin-left: 20px;
}
.group-events.collapsed {
  height: 0;
  overflow: hidden;
}

.remap-tag {
  @apply bg-yellow-500;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 3px;
  position: relative;
  top: -2px;
  font-size: 11px;
  height: 18px;
  display: inline-block;
  margin-left: 6px;
  line-height: 19px;
}
</style>
