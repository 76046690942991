<template>
  <div class="relative p-1 mb-1 bg-gray-800 rounded agenda-event hover:ring-2 hover:ring-yellow-500"
    :class="{ 'ring-yellow-500 ring-4': selected, 'ring-4 ring-white': eventStatus === 'LIVE' }">
    <div class="w-1 top-1 bottom-1 rounded absolute text-xs text-black text-center font-bold pt-0.5"
      :class="bgStatusColor(eventStatus)"></div>
    <div class="justify-between mb-1 ml-1 group-header md:flex">
      <!-- EVENT NAME -->
      <div class="flex items-center ml-6 space-x-1 text-sm">
        <span class="" :class="eventStatusTextColorClass">
          <!-- {{event.name}} -->
          <label class="items-center cursor-pointer sm:inline-flex" :for="event.id + 'x'"
            :class="{ 'pointer-events-none': !showCheck }">
            <input type="checkbox" class="mr-2 form-checkbox" v-show="showCheck" :name="event.id + 'x'"
              :checked="getSelectedIndex(event) !== -1">
            <span>{{ event.name }} </span>
          </label>
        </span>
        <span v-for="status in groupStatuses" :key="status" class="inline-block w-3 h-3 ml-1 transform rounded"
          :class="bgStatusColor(status)"></span>
        <div class="ml-3 agenda-day-event-name-description">
          {{ event.description }}
        </div>
      </div>
      <!-- EVENT CLIENT/ACCOUNT -->
      <div class="text-xs text-gray-100 ml-6 md:ml-0 py-0.5 px-1 rounded bg-gray-900">
        <span class="fc-event-account">
          <span v-if="event.commentaryLanguage" class="commentary-language"><i class="fas fa-globe-americas"></i>
            {{ event.commentaryLanguage }}
          </span>
          <span :class="eventStatusTextColorClass">{{ event.account.name }}</span>
          <span class="inline-block w-2 vertical-divinder"></span>
          <span class="fc-event-account-client">{{ event.customer.name }}</span>
        </span>
      </div>
    </div>
    <div class="items-center justify-between ml-1 sm:flex">
      <!-- <img src="https://corsproxy.io/?https://probes.elas.tech/projects/34/apps/125/tasks/7646/thumbs/51507324?ut=1703197927" alt=""> -->
      <div class="w-full ml-6 text-sm text-gray-100 group-details md:flex md:space-x-4" :class="headerColumnClass">
        <div v-for="col in visibleColumnDefs" :key="'i' + col.headerName" :class="'agenda-day-event-' + col.headerName"
          :style="{ width: isMobileOnly ? 'auto' : col.width + 'px' }" class="flex flex-initial">
          <span class="inline-block w-20 text-gray-500 agenda-label md:hidden">
            {{ col.headerName }}
          </span>
          <span class="w-12 time" v-if="col.type === 'time'">
            {{ event[col.field] | dateUtc('H:mm') }}
            <span class="block text-xs opacity-50">{{ event[col.field] | dateUtc('DD/MM') }}</span>
          </span>
          <span class="relative group" v-if="col.field === 'inputName'">
            <img :src="thumb.data" alt="" class="w-12"  v-if="isSysAdmin || (event.status === 'LIVE')"/>
            <img :src="thumb.data" alt="" class="absolute z-50 hidden w-52 group:hover:block" v-if="isSysAdmin || (event.status === 'LIVE')"/>
            <span v-html="event[col.field]"></span>
            <span :class="[bgInputStatusColor]" v-if="inputAlert.length === 0"
              class="inline-block w-3 h-3 rounded-full">
            </span>
            <span :class="[bgInputStatusColor]" @click="showModal = true" v-if="inputAlert.length > 0"
              class="w-3 h-3 rounded-full text-[6px] flex items-center text-center justify-center cursor-pointer">
              {{ inputAlert.length }}
            </span>
          </span>
          <span class="w-full break-words other"
            v-if="col.type === 'html' && col.field !== 'output' && col.field !== 'inputName' && col.field !== 'outputUI' && col.field !== 'audioUI'"
            v-html='event[col.field]'>
          </span>
          <span class="w-full break-words other" v-if="col.type === 'html' && col.field === 'outputUI'"
            v-html='outputUI'>
          </span>
          <pre class="font-sans audio" v-if="!col.type && col.field === 'audioUI'">{{ event[col.field] }}
          </pre>

          <span class="w-full break-words other" v-if="!col.type && col.field !== 'audioUI'"
            :class="{ warning: event[col.field] === 'not set' }">
            {{ event[col.field] }}
          </span>
        </div>
        <div>
        </div>
      </div>
    </div>

    <!--ACTIONS -->
    <div class="absolute bottom-0 right-0 bg-gray-800 agenda-event-actions">
      <slot></slot>

    </div>
    <div v-show="showModal" class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" @click="showModal = false">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div
          class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">

            <h4 class="font-bold">Alarms</h4>
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left max-h-[70vh] w-full overflow-y-scroll">
                <ul>
                  <li v-for="(alert, index) in inputAlert" :key="index" class="py-2 border-b border-gray-200">

                    <div class="text-xs">{{ alert.level }} &bull; {{ alert.type }} &bull; {{ new
                      Date(alert.trigger_time).toLocaleString()}}</div>
                    <div>{{ alert.name }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button @click="showModal = false"
                class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5">
                Close
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import Servers from '../../services/Servers'

export default {
  name: 'AgendaItem',
  props: {
    event: Object,
    groupEventsStatuses: Array,
    visibleColumnDefs: Array,
    isMobileOnly: Boolean,
    showCheck: Boolean,
    selectedEvents: Array,
    selected: Boolean,
    serverStatus: Object,
    inputStatuses: Object,
    isSysAdmin: Boolean,
  },

  computed: {
    groupStatuses() {
      return [...new Set(this.groupEventsStatuses)];
    },

    inputStatusData() {
      const { input, inputOverride } = this.event;
      if (inputOverride && inputOverride.name && inputOverride.probeTaskId && this.inputStatuses && this.inputStatuses[34] && this.inputStatuses[34][inputOverride.probeTaskId]) {
        this.thumb = this.inputStatuses[34][inputOverride.probeTaskId].thumb || {};        
      }
      if (!this.inputStatuses || !input || !this.inputStatuses || !this.inputStatuses[34] || !input.probeTaskId) return {};
      console.log('input.name getInputStatus', input.name,input, input.probeTaskId);
      if (this.inputStatuses[34] && this.inputStatuses[34][input.probeTaskId]) {
        this.thumb = this.inputStatuses[34][input.probeTaskId].thumb || {};
      }
      return this.inputStatuses[34][input.probeTaskId] || {};
    },

    inputAlert() {
      return this.inputStatusData.alarms || [];
    },

    bgInputStatusColor() {
      if (!this.inputStatusData.status) return 'bg-neutral-500';
      if (this.inputAlert.length > 0) return 'bg-red-500';
      return 'bg-green-500';
    },

    eventStatus() {
      return this.event.status;
    },
    serverStatuses2() {
      return this.serverStatus;
    },
    eventStatusTextColorClass() {
      return `text-${this.eventStatus}`;
    },
    bgStatusColor() {
      return status => `bg-${status}`;
    },
    headerColumnClass() {
      return `grid-cols-${this.visibleColumnDefs.length}`;
    },
    taskName() {
      if (this.event.input && this.event.input.name) {
        return this.event.input.name.trim();
      }
    },
    outputUI() {
      const newItem = { ...this.event };
      newItem.outputUI = '';
      newItem.outputPDF = '';
      newItem.outputString = '';
      this.event.outputs.items
        .filter((output) => !!output)
        .forEach((el) => {
          const [outputConfig] = newItem.actions.items
            .filter((action) => action.assignedOutput && action.assignedOutput.id === el.output.id);
          const hasWowzaConfig = outputConfig && outputConfig.configWowza;
          const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
          const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
          const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
          const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : '';
          // console.log('===> ', el, newItem. outputConfig);

          const urlPdf = el.output.url ? `URL: ${el.output.url}` : '';
          const statusOutput = this.statusByName(el.output).status;
          let color = '';
          switch (statusOutput) {
            case 'Active':
              color = 'bg-green-500';
              break;
            case 'NotFound':
              color = 'bg-red-500';
              break;
            case 'Error':
              color = 'bg-yellow-500';
              break;
            default:
              color = 'bg-transparent';
          }

          const statusSpan = `<span class="inline-block h-3 w-3 rounded-full ${color}"></span>`;
          const ottsSeverString = el.output.ottServer && el.output.ottApplication ? `<div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>` : '';
          newItem.outputUI += `
              <div class="output-${el.output.status}">
                ${el.output.displayName || el.output.name}
                <span class="output-type-tag ${el.output.outputType}">${el.output.outputType} </span>
                <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
                ${hasWowzaConfig ? autostartString : ''}
                ${ottsSeverString} ${statusSpan}
              </div>
            `;

          if (el.outputOttApplicationOverride) {
            newItem.outputUI += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>
              `;
          }
        });
      // if (hasRelatedEvents) newItem.outputUI += '<span class="warning">OVERLAPPING EVENTS WITH THE SAME OUTPUT</span>';
      if (newItem.outputUI === '') newItem.outputUI = 'not set';
      return newItem.outputUI
    }
  },
  watch: {
    // serverStatus() {
    //   console.log('serverStatuses', this.serverStatus, this.event, this.visibleColumnDefs);
    // },
    // event() {
    //   console.log('serverStatuses event', this.serverStatuses);
    // }
  },
  data() {
    return {
      showModal: false,
      thumb: {},
    };
  },
  mounted() {
    console.log('serverStatuses mounted', this.serverStatuses);
    setTimeout(() => {
      // this.getThumbnailUrl(this.taskName);
    }, 1000);
  },
  methods: {
    async getThumbnailUrl(taskName) {
      try {
        const result = await Servers.getInputsThumb(taskName)
        this.thumb = result.data.thumb;
        console.log('INPUT getInputsThumb ------------', result)
      } catch (error) {
        console.log('INPUT getInputStatus error', error)
      }
    },
    getSelectedIndex(event) {
      let index = -1;
      if (!this.selectedEvents) return index;
      this.selectedEvents.forEach((ev, i) => {
        if (event.id === ev.id) index = i;
      });

      return index;
    },
    statusByName(output) {
      const status = this.serverStatus[output.name]
      if (!status) return {};
      // console.log('serverStatuses statusByName', output.name, this.serverStatus[output.name])
      return status || {}
    }
  },
};
</script>

<style>
.agenda-event-actions {
  display: none;
}

.agenda-event:hover .agenda-event-actions {
  display: block;
}
</style>
