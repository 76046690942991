<template>
  <div>
    <div class="details-panel"  v-loading="loading || uploadFileLoading">
        <div class="text-black p-2 pt-5 relative">
          <div
            class="text-xs px-1 font-medium py-0.5 absolute top-0 left-0 right-0 text-center rounded-b-md"
            v-if="eventStatus"
            :class="bgStatusColor(eventStatus)">
            {{eventStatusDisplayName}}
          </div>
          <div class="client-name ml-3 mt-3">{{eventAccount.name}}</div>
          <span class="font-medium text-lg ml-3">{{eventTitle}}</span>
          <div class="sticky flex flex-wrap mb-3 justify-items-stretch justify-between gap-2 px-3">
            <div class="px-4 py-1 bg-RECEIVED hover:bg-yellow-400 text-center grow justify-center text-white cursor-pointer text-[10px] rounded-md flex items-center font-bold"
              @click="setStatus('RECEIVED')" v-if="eventStatusDisplayName === 'CLIENT CONFIRMED' && isSysAdmin">
              RECEIVED
            </div>
            <div class="px-4 py-1 bg-AWAITING_SIGNAL hover:bg-yellow-400 text-center grow justify-center text-white cursor-pointer text-[10px] rounded-md flex items-center font-bold"
              @click="setStatus('AWAITING_SIGNAL')" v-if="(eventStatusDisplayName === 'CLIENT CONFIRMED' || eventStatusDisplayName === 'RECEIVED') && isSysAdmin">
              AWAITING <br> SIGNAL
            </div>
            <div class="px-4 py-1 bg-green-600 hover:bg-green-400 text-center grow justify-center text-white cursor-pointer text-[10px] rounded-md flex items-center uppercase font-bold"
              @click="sysAdminConfirmConfirmation"
              v-if="(eventStatusDisplayName === 'CLIENT CONFIRMED' || eventStatusDisplayName === 'RECEIVED' || eventStatusDisplayName === 'AWAITING SIGNAL') && isSysAdmin">
              MOS <br>Confirm
            </div>
            <!-- <el-button type="warning" size="mini"
              @click.native="setStatus('RECEIVED')" v-if="eventStatusDisplayName === 'CLIENT CONFIRMED' && isSysAdmin">
              RECEIVED
            </el-button>
            <el-button type="warning" size="mini" @click.native="setStatus('AWAITING_SIGNAL')" v-if="(eventStatusDisplayName === 'CLIENT CONFIRMED' || eventStatusDisplayName === 'RECEIVED') && isSysAdmin">
              AWAITING <br> SIGNAL
            </el-button>
            <el-button type="warning" size="mini" @click.native="sysAdminConfirmConfirmation"
              v-if="(eventStatusDisplayName === 'CLIENT CONFIRMED' || eventStatusDisplayName === 'RECEIVED' || eventStatusDisplayName === 'AWAITING SIGNAL') && isSysAdmin">
              MOS <br>Confirm
            </el-button> -->
          </div>
        </div>
        <div id="agenda-event-preview" class="mt-4 sm:mt-0 -ml-2 sm:ml-0">
          <div class="panel-info-message" v-if="!selectedEvent">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><title>c-info</title><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="#636e88" stroke="#636e88"><circle data-stroke="none" cx="24" cy="12" r="1" stroke="none"></circle><circle cx="24" cy="24" r="22" fill="none" stroke="#636e88" stroke-miterlimit="10"></circle><circle cx="24" cy="12" r="1" fill="none" stroke-miterlimit="10"></circle><line x1="24" y1="19" x2="24" y2="36" fill="none" stroke-miterlimit="10"></line></g></svg>
              Click or add an event to start
            </div>
            <div class="py-1">
              <div class="flex space-x-2 text-gray-600 text-xs mt-4">
                <div class="px-2 rounded-sm flex-row items-center"><span class="uppercase mr-1 ">Total </span> <span class="text-xl block"> {{eventsStats.total}}</span> </div>
                <div class="px-2 rounded-sm flex-row items-center"><span class="uppercase mr-1 ">Live </span> <span class="text-xl block"> {{eventsStats.live}}</span> </div>
                <div class="px-2 rounded-sm flex-row items-center"><span class="uppercase mr-1 ">Completed </span> <span class="text-xl block"> {{eventsStats.completed}}</span> </div>
                <div class="px-2 rounded-sm flex-row items-center"><span class="uppercase mr-1 ">Remaining </span> <span class="text-xl block"> {{eventsStats.remaining }}</span> </div>
              </div>
            </div>
          </div>
          <div v-bar class="wrapper" v-if="selectedEvent">
            <div>
              <div class="event-preview-content px-5 pb-20" v-if="selectedEvent" >
                <!-- <h4 class="event-title"><strong>{{selectedEvent.name}}</strong></h4>-->
                <el-alert type="warning" :closable="false" v-if="isLive">
                  Service started. Rescheduling disabled.
                </el-alert>
                <el-alert type="warning" :closable="false" v-if="eventStatus === 'PENDING'">
                  This booking needs to be client confirmed
                  <el-button type="warning" size="mini" @click.native="confirmConfirmation">
                    Set Client Confirmed
                  </el-button>
                </el-alert>

                <el-alert type="warning" :closable="false" v-if="eventStatusDisplayName === 'CLIENT CONFIRMED' && !isSysAdmin">
                  This booking is pending resource confirmation by MediaAtmos
                </el-alert>

                
                <el-alert :closable="false"
                  v-if="eventStatusDisplayName === 'CANCELLED' && selectedEvent.cancellationMsg">
                  {{selectedEvent.cancellationMsg}}
                </el-alert>
                <el-alert type="warning" :closable="false" v-if="selectedEvent.impoortSource">
                  This event has been imported from the
                  <strong>{{selectedEvent.impoortSource | defaultValue('---')}}</strong>.
                  If you proceed and make changes they may be overwritten
                  with the next update from the source.
                </el-alert>
                <el-alert type="warning" :closable="false"
                  v-if="inputDetails.requiresPidInfo && (!selectedEvent.inputAudioPid && !selectedEvent.inputVideoPid)" show-icon>
                  This event requires Audio and Video PID to be set
                </el-alert>
                <el-row :gutter="16">

                  <el-col :span="5">
                    <div class="kicck-off">
                      <div class="label">Line-up</div>
                      <div class="content time-editor"
                        @click="setRescheduleEventDialog(true)"
                        :class="{
                          disableEditing: isPastServiceTime || isLive
                          || !(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee)
                        }">
                        {{selectedEvent.lineupTime | dateUtc}}
                        <i class="bi bly-edit"></i>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="5">
                    <div class="status">
                      <div class="label">Kick-off</div>
                      <div class="content time-editor"
                        @click="setRescheduleEventDialog(true)"
                        :class="{ disableEditing: isPastServiceTime || isLive
                          || !(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee)
                        }">
                        {{selectedEvent.startTime | dateUtc}}
                        <i class="bi bly-edit"></i>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="5">
                      <div class="status">
                      <div class="label">End Time</div>
                      <div class="content time-editor"
                        @click="setRescheduleEventDialog(true)"
                        :class="{ disableEditing: isPastServiceTime
                          || isLive
                          || !(isAdmin
                          || eventAcl.clientAdmin
                          || eventAcl.eventCoordinator
                          || eventAcl.ee)}">
                        {{selectedEvent.endTime | dateUtc}}
                        <i class="bi bly-edit"></i>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="9">
                    <div class="kicck-off">
                      <div class="label">Date</div>
                      <div class="content time-editor"
                        @click="setRescheduleEventDialog(true)"
                        :class="{ disableEditing: isPastServiceTime
                          || isLive
                          || !(isAdmin
                            || eventAcl.clientAdmin
                            || eventAcl.eventCoordinator
                            || eventAcl.ee)}">
                        {{eventDate}}
                        <i class="bi bly-edit"></i>
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <el-row :gutter="16">
                  <el-col :span="12">
                    <div class="label">Service start time</div>
                    <span class="content">
                      {{selectedEvent.serviceStartTime | dateUtc}}
                    </span>
                  </el-col>
                  <el-col :span="12">
                    <div class="label">Service end time</div>
                    <span class="content">
                      {{selectedEvent.serviceEndTime | dateUtc}}
                    </span>
                  </el-col>
                </el-row>
                <br>

                <el-row :gutter="16">
                  <el-col :span="8">
                    <div class="kicck-off"
                      :class="{ disableEditing: isPastServiceTime  }">
                      <el-popover
                        placement="left"
                        width="300"
                        :disabled="!(isAdmin
                          || eventAcl.clientAdmin
                          || eventAcl.eventCoordinator
                          || eventAcl.ee)"
                        @show="edit('fixturePopup', selectedEvent.fixture)"
                        v-model="showPopups.fixturePopup">
                        <p>
                          <el-input type="textarea" size="small" v-model="fixture"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('fixturePopup','fixture')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('fixture', fixture)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                          <div class="label">Fixture</div>
                          {{selectedEvent.fixture | defaultValue("----")}}
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="status"
                      :class="{ disableEditing: isPastServiceTime }">
                      <el-popover
                        placement="left"
                        width="300"
                        :disabled="!(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee)"
                        @show="edit('propertyPopup', selectedEvent.property)"
                        v-model="showPopups.propertyPopup">
                        <p>
                          <el-input type="textarea" size="small" v-model="property"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('propertyPopup','property')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('property', property)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                          <div class="label">Property</div>
                          {{selectedEvent.property | defaultValue("----")}}
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="sport"
                      :class="{ disableEditing: isPastServiceTime }">
                      <el-popover
                        placement="left"
                        width="300"
                        :disabled="!(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee)"
                        @show="edit('sportPopup', selectedEvent.sport)"
                        v-model="showPopups.sportPopup">
                        <p>
                          <el-input type="textarea" size="small" v-model="sport"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('sportPopup','sport')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('sport', sport)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                      <div class="label">Sport</div>
                          {{selectedEvent.sport | defaultValue("----")}}
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                </el-row>

                <el-popover
                  placement="left"
                  width="300"
                  :disabled="!(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                    || eventAcl.ee)"
                  @show="edit('descriptionPopup', selectedEvent.description)"
                  v-model="showPopups.descriptionPopup">
                  <p>
                    <el-input type="textarea" rows="4" v-model="description"></el-input>
                  </p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text"
                      @click="cancel('descriptionPopup','description')">Cancel</el-button>
                    <el-button type="primary" size="mini"
                      @click="save('description', description)">Save</el-button>
                  </div>
                  <div class="content editable" slot="reference"
                    :class="{ disableEditing: isPastServiceTime }">
                    <div class="label">Description</div>
                    {{selectedEvent.description | defaultValue("----")}}
                    <i class="bi bly-edit"></i>
                  </div>
                </el-popover>

                <el-row :gutter="16" v-if="isSysAdmin">
                  <el-col :span="12">
                    <div class="kicck-off">
                      <el-popover
                        placement="left"
                        width="300"
                        :disabled="!isSysAdmin"
                        @show="edit('inputAudioPid', selectedEvent.inputAudioPid)"
                        v-model="showPopups.inputAudioPidPopup">
                        <p>
                          <el-input type="text" size="small" v-model="inputAudioPid"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('inputAudioPidPopup','inputAudioPid')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('inputAudioPid', inputAudioPid)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                          <div class="label">Input Audio Pid </div>
                          {{selectedEvent.inputAudioPid | defaultValue("----")}}
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="kicck-off">
                      <el-popover
                        placement="left"
                        width="300"
                        :disabled="!isSysAdmin"
                        @show="edit('inputVideoPid', selectedEvent.inputVideoPid)"
                        v-model="showPopups.inputVideoPidPopup">
                        <p>
                          <el-input type="text" size="small" v-model="inputVideoPid"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('inputVideoPidPopup','inputVideoPid')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('inputVideoPid', inputVideoPid)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                          <div class="label">Input Video Pid </div>
                          {{selectedEvent.inputVideoPid | defaultValue("----")}}
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                </el-row>

                <!-- -------------------------------------------------------------------------- -->
                <!-- AUDIO -------------------------------------------------------------------- -->
                <!-- -------------------------------------------------------------------------- -->
                <div class="label">Source Audio</div>
                <div class="content">
                  <div class="preview-panel-audio-channel min-h-[32px]"
                    v-for="(audioChannel, index) in sortedAudioMapping"
                    :key="audioChannel.description + index + audioChannel.channelNo + 'audio'">
                    <div class="preview-panel-audio-channel-number">
                      {{audioChannel.channelNo}}
                        <el-tooltip content="Active" placement="left"  v-show="audioChannel.active">
                          <div class="audio-is-active"
                        ></div>
                        </el-tooltip>
                      </div>
                    <div class="preview-panel-audio-channel-name-left">
                      {{audioChannel.description !== ' ' ?  audioChannel.description : '---'}} &nbsp;
                    </div>
                    <div class="preview-panel-audio-channel-stereo">
                      <el-tag size="mini" type="danger" v-show="audioChannel.stereo || audioChannel.stereo === null">STEREO</el-tag>
                      <el-tag size="mini" type="info" v-show="!audioChannel.stereo && audioChannel.stereo !== null">MONO</el-tag>
                    </div>
                    <div class="preview-panel-audio-channel-actions">

                      <!---------------------------
                        AUDIO CHANNEL EDIT POPUOVER
                      ------------------------------->
                      <el-popover
                        placement="left"
                        @show="editAudioChannel(audioChannel, index)"
                        @hide="clearAudioPopup"
                        width="400"
                        :visible-arrow="false"
                        :popper-options="{boundariesElement: '.content'}"
                        v-model="audoMappingPopups[index]">
                        <el-form ref="editAudioForm" label-position="left"
                          :model="tempAudioMapping">
                          <p>
                            <el-form-item label="Pair #" prop="channelNo">
                              <el-input size="mini"
                                v-model="tempAudioMapping.channelNo" placeholder="#">
                              </el-input>
                            </el-form-item>
                            <el-form-item label="Description" prop="description">
                              <el-input size="mini"
                                v-model="tempAudioMapping.description" placeholder="Description">
                              </el-input>
                            </el-form-item>
                            <el-form-item>
                              <el-switch v-model="tempAudioMapping.active"
                                active-text="Active">
                              </el-switch>
                            </el-form-item>
                            <el-form-item>
                              <el-radio-group v-model="tempAudioMapping.stereo" size="mini">
                                <el-radio-button :label="true">STEREO</el-radio-button>
                                <el-radio-button :label="false">MONO</el-radio-button>
                              </el-radio-group>
                            </el-form-item>
                          </p>
                          <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text"
                              @click="cancelAudioEdit(index)">Cancel</el-button>
                            <el-button type="primary" size="mini"
                              @click="saveAudioMapping(index)">Save</el-button>
                          </div>
                        </el-form>

                        <span class="" slot="reference">
                          <el-tooltip content="Edit audio channel"
                            slot="reference"
                            v-if="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                              || eventAcl.ee"
                            v-show="!isLive">
                            <i class="bi bly-edit btn-icon"></i>
                          </el-tooltip>
                        </span>
                      </el-popover>
                      <!---------------------------
                        // AUDIO CHANNEL EDIT POPUOVER
                      ------------------------------->
                      <el-tooltip content="Delete audio channel">
                        <i class="bi bly-delete btn-icon"
                        v-if="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee"
                        v-show="!isLive"
                        @click="deleteAudioChannel(audioChannel.id,
                        selectedEvent)"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <el-button size="mini" type="primary"
                    v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                      || eventAcl.ee"
                    v-if="!isLive"
                    @click="showAudioMappingEditForm = !showAudioMappingEditForm">
                    Add audio pair
                  </el-button>
                  <br>
                  <br>
                  <el-card shadow="never" v-show="showAudioMappingEditForm">
                    <div class="audio-mapping-form">
                      <el-form ref="eventNewAudioMappingForm" label-position="left"
                        :model="audioMappingForm">
                            <el-form-item label="Pair #" prop="channelNo">
                              <el-input size="mini"
                                v-model="audioMappingForm.channelNo" placeholder="#">
                              </el-input>
                            </el-form-item>
                            <el-form-item label="Description" prop="description">
                              <el-input size="mini"
                                v-model="audioMappingForm.description" placeholder="Description">
                              </el-input>
                            </el-form-item>
                            <el-form-item label="Active">
                              <el-switch v-model="audioMappingForm.active" class="mt-1"></el-switch>
                            </el-form-item>
                            <el-form-item label="Stereo" style="text-align:right">
                              <el-radio-group v-model="audioMappingForm.stereo" size="mini">
                                <el-radio-button :label="true">STEREO</el-radio-button>
                                <el-radio-button :label="false">MONO</el-radio-button>
                              </el-radio-group>
                            </el-form-item>
                        <el-button size="small"
                          class="mr-1"
                          @click="showAudioMappingEditForm = !showAudioMappingEditForm">Cancel
                        </el-button>
                        <el-button
                          size="small"
                          type="primary"
                          @click="addAudioChannel
                          ('eventNewAudioMappingForm', selectedEvent.audioMapping.items,
                            selectedEvent)">
                          Add audio pair
                        </el-button>
                      </el-form>
                    </div>
                  </el-card>
                </div>

                <div class="label">Extend booking time</div>
                <div class="content">
                  <el-card shadow="hover" :body-style="{padding: '8px'}" v-if="false">
                    <el-row :gutter="16">
                      <el-col :span="4">Head: </el-col>
                      <el-col :span="17">{{selectedEvent.headTimeMin | defaultValue(0)}} min
                      </el-col>
                      <el-col :span="3" v-show="!isPastServiceTime && isBeforeServiceTimeStart">
                        <el-dropdown trigger="click"
                          v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                            || eventAcl.ee">
                          <i class="bi bly-more bi-rotate-90"></i>
                          <el-dropdown-menu slot="dropdown">
                            
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'head', 30)">
                                30<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'head', 60)">
                                60<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'head', 90)">
                              90<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'head', 120)">
                              120<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="openAddTime(selectedEvent, 'head')">
                                Custom </el-dropdown-item>
                            
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-col>
                    </el-row>
                  </el-card>
                  <br>
                  <el-card shadow="hover" :body-style="{padding: '8px'}">
                    <el-row :gutter="16">
                      <el-col :span="8">Time added: </el-col>
                      <el-col :span="13">{{selectedEvent.tailTimeMin | defaultValue(0)}} min
                      </el-col>
                      <el-col :span="3" class="text-right" v-show="!isPastServiceTime">
                        <el-dropdown trigger="click"
                          v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                            || eventAcl.ee || eventAcl.eo">
                          <i class="bi bly-more bi-rotate-90"></i>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'tail', 30)">
                                30<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'tail', 60)">
                                60<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'tail', 90)">
                              90<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="addTime(selectedEvent, 'tail', 120)">
                              120<span>min</span> </el-dropdown-item>
                            <el-dropdown-item icon="bi bly-minus"
                              @click.native="openAddTime(selectedEvent, 'tail')">
                                Custom </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </el-col>
                    </el-row>
                  </el-card>
                </div>

                <!-- -------------------------------------------------------------------------- -->
                <!-- INPUTS SECTION ----------------------------------------------------------- -->
                <!-- -------------------------------------------------------------------------- -->

                <el-row :gutter="16">
                  <el-col :span="24">
                    <div class="label">Input</div>
                    <div class="content donwling-config-information"
                      :class="{
                        disableEditing: isPastServiceTime
                        || !(isAdmin || eventAcl.clientAdmin
                          || eventAcl.eventCoordinator || eventAcl.ee)
                        || hardDisableEdit
                      }">
                      <el-popover
                        placement="right"
                        width="250"
                        v-model="showPopups.inputPopup"
                        :disabled="!(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee)"
                        trigger="click">
                        <div v-bar class="dd-popup-wrapper h-300">
                          <div>
                            <el-dropdown-item
                                v-for="input in accountInputs"
                                :key="input.id"
                                @click.native="changeInput(input)"
                                :title="input.name + ' ' + input.status"
                                >{{input.name | truncate(23)}}

                            </el-dropdown-item>
                          </div>
                        </div>
                        <span class="el-dropdown-link" slot="reference">
                          {{inputName}} {{inputStatus(selectedEvent)}}
                          <i class="el-icon-arrow-down el-icon--right"
                            v-show="!isPastServiceTime"></i>
                        </span>
                      </el-popover>
                      <el-popover
                        placement="left"
                        width="300"
                        :disabled="!(isSysAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                          || eventAcl.ee)"
                        @show="inputInfo = selectedEvent.inputDetails; edit('inputDetails', selectedEvent.inputDetails)"
                        v-model="showPopups.inputDetailsPopup">
                        <p>
                          <el-input type="text" size="small" v-model="inputInfo"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('inputDetailsPopup','inputInfo')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('inputDetails', inputInfo)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                          <div class="label mt-4" v-show="hasInputDetailsEnabled">Input Details </div>

                          <div class="muted"  v-show="hasInputDetailsEnabled">{{selectedEvent.inputDetails || '-----'}}</div>
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                      <!-- <div class="muted">{{selectedEvent.inputDetails || '-----'}}</div> -->
                      <div class="extended" v-if="inputDetails.type === 'DOWNLINK'">
                        <br>
                        <div class="label">Downlink Config
                          <el-tooltip content="Copy downlink config information">
                            <i
                              @click="copyToClipboard('downlinkInfo')"
                              class="bi bly-clipbin icon-btn copy-all">

                            </i>
                          </el-tooltip>
                        </div>
                        <div class="warning" v-if="!downLinkConfig">
                          <i class="bi bly-warning"></i>
                          Downlink config is missing
                        </div>
                        <el-popover
                          placement="left"
                          width="300"
                          :disabled="!(isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                            || eventAcl.ee)"
                          @show="edit('downlinkConfigPopup', selectedEvent.description)"
                          v-model="showPopups.downlinkConfigPopup">
                          <div>
                            <div v-if="downlinkConfigForm">
                              Name & pos <span class="text-red-500">*</span><br>
                              <el-input v-model="downlinkConfigForm.satelliteName" class="mb-1" required></el-input>
                              BISS <span class="text-red-500">*</span><br>
                              <el-input v-model="downlinkConfigForm.BISS" class="mb-1" required></el-input>
                              Service ID <span class="text-red-500">*</span><br>
                              <el-input v-model="downlinkConfigForm.service" class="mb-1" required></el-input>
                              Polarization <span class="text-red-500">*</span><br>
                              <el-radio-group v-model="downlinkConfigForm.satellitePolarization"
                                size="small" class="mb-1"
                                 required>
                                <el-radio-button label="Vertical">Vertical</el-radio-button>
                                <el-radio-button label="Horizontal">Horizontal</el-radio-button>
                              </el-radio-group>
                              Frequency <br>
                              <el-input-number v-model="downlinkConfigForm.satelliteFrequency" class="mb-1">
                              </el-input-number> <br>
                              Symbol Rate <br>
                              <el-input
                                placeholder="Choose or enter rate"
                                v-model="downlinkConfigForm.symbolRate">
                                <el-dropdown slot="append" size="small" type="primary">
                                  <el-button type="primary">
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                  </el-button>
                                  <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="item in [3400,3600,5000,7000,7120,7200,7500,9850,10000,12500,15000,34000]"
                                    :key="item"
                                    @click.native="downlinkConfigForm.symbolRate = item"
                                    >{{item}}</el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </el-input>
                            </div>
                          </div>
                          <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="text"
                              @click="cancel('downlinkConfigPopup','downlink')">Cancel</el-button>
                            <el-button type="primary" size="mini"
                              :disabled="!downlinkConfigForm.satelliteName || !downlinkConfigForm.BISS || !downlinkConfigForm.service || !downlinkConfigForm.satellitePolarization"
                              @click="updateDownlinkConfig()">Save</el-button>
                          </div>
                          <div class="content editable" slot="reference"
                            :class="{ disableEditing: isPastServiceTime }">

                              <div v-if="!downLinkConfig">
                                <div class="el-button el-button--primary el-button--mini">Update config</div>
                              </div>
                              <div v-if="downLinkConfig" id="downlinkInfo">
                                <div>
                                  <span>Name & pos:
                                  <strong id="downlinkInfo-name">{{downLinkConfig.satelliteName}}</strong></span>
                                  <i @click="copyToClipboard('downlinkInfo-name')"
                                    class="bi bly-clipbin copy-property"></i>
                                </div>
                                <div>
                                  <span>
                                    Frequency:
                                    <strong id="downlinkInfo-satelliteFrequency">{{downLinkConfig.satelliteFrequency}}</strong>
                                    <i @click="copyToClipboard('downlinkInfo-satelliteFrequency')"
                                      class="bi bly-clipbin copy-property"></i>
                                  </span>
                                </div>
                                <div>
                                  <span>Polarization:
                                    <strong id="downlinkInfo-satellitePolarization">{{downLinkConfig.satellitePolarization}}</strong>
                                    <i @click="copyToClipboard('downlinkInfo-satellitePolarization')"
                                    class="bi bly-clipbin copy-property"></i>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    BISS:
                                    <strong id="downlinkInfo-BISSx">
                                      {{downLinkConfig.BISS}}
                                    </strong>
                                    <strong id="downlinkInfo-BISS" class="hide" style="opacity:0; position: absolute">
                                      {{downLinkConfig.BISS | removeSpaces(false)}}
                                    </strong>
                                    <i @click="copyToClipboard('downlinkInfo-BISS')"
                                    class="bi bly-clipbin copy-property"></i>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    Symbol Rate:
                                    <strong id="downlinkInfo-symbolRate">
                                      {{downLinkConfig.symbolRate | defaultValue('----')}}
                                    </strong>
                                    <i @click="copyToClipboard('downlinkInfo-symbolRate')"
                                    class="bi bly-clipbin copy-property"></i>
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    Service ID:
                                    <strong id="downlinkInfo-service">
                                      {{downLinkConfig.service | defaultValue('----')}}
                                    </strong>
                                    <i @click="copyToClipboard('downlinkInfo-service')"
                                    class="bi bly-clipbin copy-property"></i>
                                  </span>
                                </div>
                              </div>
                            <i class="bi bly-edit"></i>
                          </div>
                        </el-popover>
                      </div>
                      <div v-if="inputDetails.frcAvailable" class="mt-2">
                        <el-switch v-model="selectedEvent.inputFrcEnabled" @change="toggleFRC('inputFrcEnabled', selectedEvent.inputFrcEnabled)"  active-text="Enable Framerate conversion"
                        >
                        </el-switch>

                        <div v-if="selectedEvent.inputFrcEnabled" class="mt-3">
                          <el-card shadow="hover" style="margin-bottom: 8px" :body-style="{ padding: '8px' }" >
                              Input format:
                            <span class="text-right" style="float:right">
                              <el-dropdown
                                v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator || eventAcl.ee || eventAcl.eo">
                                <strong class="el-dropdown-link">{{selectedEvent.inputFrcInputFormat ? selectedEvent.inputFrcInputFormat.name : 'Select Format'}}</strong>
                                <el-dropdown-menu slot="dropdown">
                                  <el-dropdown-item
                                    v-for="format in pictureFormats" :key="format.id"
                                    @click.native="save('eventInputFrcInputFormatId', format.id)">
                                    {{format.name}}
                                  </el-dropdown-item>
                                </el-dropdown-menu>
                              </el-dropdown>
                            </span>
                          </el-card>
                          <el-card shadow="hover" style="margin-bottom: 8px" :body-style="{ padding: '8px' }" >
                              Output format:
                              <span class="text-right" style="float:right">
                                <el-dropdown
                                  v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator || eventAcl.ee || eventAcl.eo">
                                  <strong class="el-dropdown-link">{{selectedEvent.inputFrcOututFormat ? selectedEvent.inputFrcOututFormat.name : 'Select Format'}}</strong>
                                  <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                      v-for="format in pictureFormats" :key="format.id"
                                      @click.native="save('eventInputFrcOututFormatId', format.id)">
                                      {{format.name}}
                                    </el-dropdown-item>
                                  </el-dropdown-menu>
                                </el-dropdown>
                              </span>
                          </el-card>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>


                <el-row  v-if="isSysAdmin">
                  <div class="sys-admin-menu">
                    <div class="label">
                      Input override
                    </div>
                    <div class="content">
                      <el-popover
                        placement="right"
                        width="250"
                        v-model="showPopups.ovrrideInputPopup"
                        trigger="click">
                        <div v-bar class="dd-popup-wrapper h-300">
                          <div>
                            <div>
                              <input v-model="searchInputOverride" type="text" placeholder="Search input"
                                class="border m-1 w-full p-1 rounded"
                                >
                            </div>
                            <el-dropdown-item
                                v-for="input in allInputsFiltered"
                                :key="input.id"
                                @click.native="ovrrideInput(input)"
                                :title="input.name + ' ' + inputStatus(input)"
                                >{{input.name | truncate(23)}} {{inputStatus(input)}}</el-dropdown-item>
                          </div>
                        </div>
                        <span class="el-dropdown-link" slot="reference">
                          {{overrideInputName}}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                      </el-popover>
                    </div>
                  </div>
                </el-row>

                <div class="label">
                  Outputs
                </div>
                <div class="content preview-outputs">
                  <el-card shadow="hover" :body-style="{ padding: '8px' }"
                    class="input"
                    v-for="(target, index) in sortedActions"
                    :key="target + 'x' + index">
                    <div v-if="target.actionType === 'ROUTE'" :class="`output-${target.assignedOutput.status}`">
                      <div>
                        {{target.assignedOutput.displayName || target.assignedOutput.name}}
                        <span class="output-status-tag" v-show="target.assignedOutput.status === 'DELETED'">
                          {{target.assignedOutput.status}}
                        </span>
                        <span class="output-type-tag" :class="target.assignedOutput.outputType">
                          {{target.assignedOutput.outputType}}
                        </span>
                        <span class="output-type-tag ROUTE">
                          ROUTE
                        </span>
                        <br>
                      </div>

                      <div class="actions">
                        <el-tooltip content="Connection Info">
                            <small class="mt-1" @click="selectedOutput = {output: target.assignedOutput, target: null}; outputReportDialog = true;">
                                <i class="el-tooltip bi bly-info icon-btn" />
                            </small>
                          </el-tooltip>
                      </div>
                    </div>
                    <div v-if="target.actionType !== 'ROUTE'">
                      <span class="target" v-if="target.configWowza.targets.items[0]" :class="`output-${target.assignedOutput.status}`">
                        <el-tooltip
                          :content="'Autostart enabled: (' + target.configWowza.autostartOffsetMin + 'min)'"
                          v-show="target.configWowza.autostart">
                          <span><i class="bi bly-time" /></span>
                        </el-tooltip>
                        {{target.assignedOutput.displayName || target.configWowza.targets.items[0].target}}
                        <span class="output-type-tag" :class="target.assignedOutput.outputType">
                          {{target.assignedOutput.outputType}}
                        </span>
                        <span class="output-status-tag" v-show="target.assignedOutput.status === 'DELETED'">
                          {{target.assignedOutput.status}}
                        </span>
                        {{target.actionOutputMap.outputOttApplicationOverride}}
                      </span>
                      <br>
                      <small class="stream-key">
                        <i class="far fa-key" /> {{target.configWowza.targets.items[0].streamKey
                          | defaultValue('--- not set ---')}}
                      </small>
                      <small class="transcoder-profile" v-if="target.configWowza.targets.items[0].transcoderProfile">
                        <i class="far fa-random" />{{target.configWowza.targets.items[0].transcoderProfile
                          | defaultValue('--- not set ---')}}
                      </small>
                      <small class="stream-key" v-if="target.actionOutputMap.outputOttApplicationOverride">
                        (OTT Override: {{target.actionOutputMap.outputOttApplicationOverride}})
                      </small>
                      <div class="actions" v-show="!isPastServiceTime || !hardDisableEdit">
                        <el-tooltip content="Connection Info">
                          <small class="mt-1" @click="selectedOutput = {output: target.assignedOutput, target: target.configWowza.targets.items[0]}; outputReportDialog = true">
                              <i class="el-tooltip bi bly-info icon-btn" />
                          </small>
                        </el-tooltip>
                        <el-tooltip content="Start stream">
                          <i
                            v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eo || eventAcl.ec"
                            @click="startStream(
                              target.configWowza.targets.items[0], selectedEvent
                            )"
                            class="bi bly-play icon-btn" />
                        </el-tooltip>
                        <el-tooltip content="Stop stream">
                          <i
                            v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eo || eventAcl.ec"
                            @click="stopStream(
                              target.configWowza.targets.items[0], selectedEvent
                            )"
                            class="bi bly-stop icon-btn" />
                        </el-tooltip>

                        <el-tooltip content="Set stream key">
                          <i
                            v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator || eventAcl.ee || eventAcl.eo"
                            :disabled="isPastServiceTime
                              || target.configWowza.targets.items[0].noStremKeyRequired"
                            @click="editStreamKey(target)"
                            class="bi bly-edit icon-btn" />
                        </el-tooltip>

                        <el-tooltip content="Set transcoding profile">
                          <i
                            v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator || eventAcl.ee || eventAcl.eo"
                            :disabled="isPastServiceTime"
                            @click="editTranscoderProfile(target)"
                            class="far fa-random icon-btn" />
                        </el-tooltip>

                        <el-tooltip content="Override OTT Application">
                          <i class="icon-btn"
                            v-show="isSysAdmin"
                            @click="editOutputOverride(target)">
                            <font-awesome-icon icon="exchange-alt"/>
                          </i>
                        </el-tooltip>
                        <el-tooltip content="Set autostart time">
                          <i
                            v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator || eventAcl.ee || eventAcl.eo"
                            :disabled="isPastServiceTime
                              || target.configWowza.targets.items[0].noStremKeyRequired"
                            @click="setAutostart(target)"
                            class="bi bly-time icon-btn"
                            :class="{active: target.configWowza.autostart}" />
                        </el-tooltip>
                        <!-- <el-tooltip content="more">
                          <el-dropdown trigger="click"
                            v-if="false"
                            v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eo">
                            <i class="bi bly-more icon-btn bi-rotate-90"></i>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item icon="bi bly-edit"
                                :disabled="isPastServiceTime
                                  || target.configWowza.targets.items[0].noStremKeyRequired"
                                @click.native="editStreamKey(target)">
                                Edit stream key
                              </el-dropdown-item>
                              <el-dropdown-item icon="bi bly-delete"
                                v-if="false"
                                v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                                  || eventAcl.ee"
                                @click.native="deleteOutput(target.assignedOutput)">
                                Delete output
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </el-tooltip> -->
                      </div>
                    </div>
                  </el-card>

                  <!-- OUTPUTS without streamkey -->
                  <el-card shadow="hover" :body-style="{ padding: '8px' }"
                    class="input"
                    v-for="(map, index) in basicOutputs"
                    :key="map.id + index">
                    <div>
                      {{map.output.displayName || map.output.name}}
                      <span class="output-type-tag" :class="map.output.outputType">
                        {{map.output.outputType}}
                      </span>
                      <br>
                      <small>{{map.output.description}}</small>


                      <div class="actions mt-1">
                        <el-tooltip content="Connection Info">
                          <small class="mt-1" @click="selectedOutput = {output: map.output, target: null}; outputReportDialog = true">
                              <i class="el-tooltip bi bly-info icon-btn" />
                          </small>
                        </el-tooltip>
                        <div v-show="!isPastServiceTime">
                          <el-tooltip content="more">
                            <el-dropdown trigger="click"
                              v-if="false"
                              v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                                || eventAcl.ee || eventAcl.eo">
                              <i class="bi bly-more icon-btn bi-rotate-90"></i>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="bi bly-delete"
                                  v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                                    || eventAcl.ee"
                                  @click.native="deleteOutput(map.output)">
                                  Delete output
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </el-card>
                </div>
                <el-button size="mini" type="primary"
                  v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eventCoordinator
                    || eventAcl.ee"
                  :disabled="!(!isPastServiceTime && isBeforeServiceTimeStart) || hardDisableEdit"
                  @click="openAddOutputDialog">
                  <span v-if="!hasOutputs">Add outputs</span>
                  <span v-if="hasOutputs">Change outputs</span>
                </el-button>

                <!-- ADVANCE CONFIG FOR NORMAL USERS -->
                <div class="el-divider el-divider--horizontal cursor-pointer uppercase mt-6 mb-2 relative"
                  v-if="!isSysAdmin"
                  @click="showAdvance = !showAdvance">
                  <div class="el-divider__text is-left">Show advanced settings </div>
                  <div class="absolute right-0 -top-1.5 pl-3 bg-white" >
                    <svg viewBox="0 0 24 24" :class="{ 'transform rotate-180': showAdvance}" height="12" width="12" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5"><path d="M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </div>
                </div>
                <el-row :gutter="16" v-if="showAdvance && !isSysAdmin">
                  <el-col :span="12">
                    <div class="kicck-off">
                      <el-popover
                        placement="left"
                        width="300"
                        @show="edit('inputAudioPid', selectedEvent.inputAudioPid)"
                        v-model="showPopups.inputAudioPidPopup">
                        <p>
                          <el-input type="text" size="small" v-model="inputAudioPid"></el-input>
                        </p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text"
                            @click="cancel('inputAudioPidPopup','inputAudioPid')">Cancel</el-button>
                          <el-button type="primary" size="mini"
                            @click="save('inputAudioPid', inputAudioPid)">Save</el-button>
                        </div>
                        <div class="content editable" slot="reference">
                          <div class="label">Input Audio Pid </div>
                          {{selectedEvent.inputAudioPid | defaultValue("----")}}
                          <i class="bi bly-edit"></i>
                        </div>
                      </el-popover>
                    </div>
                  </el-col>
                </el-row>
                <br><br>

                <div class="label">
                  Attachments
                  <div class="attachments-upload">
                    <el-upload
                      class="upload-demo"
                      action="uploadAction"
                      :auto-upload="false"
                      :on-change="handleUploadChange"
                      :show-file-list="false"
                      :multiple="false"
                      :accept="'.png, .jpg, jpeg, .doc, .docx, .pdf, .xls, .xlsx, .csv'">
                      <el-tooltip content="Upload another file" placement="left" slot="trigger">
                        <i class="icon-btn bi bly-upload"></i>
                      </el-tooltip>
                    </el-upload>
                  </div>
                </div>
                <div
                  class="attachment-link"
                  v-for="attachment in eventAttachments"
                  :key="attachment"
                  @click="downloadAttachment(attachment)">
                  {{attachment}}
                </div>
                <div v-show="!eventAttachments">No Attachments</div>

                <div v-if="selectedEvent.commentaryLanguage">
                  <div class="label mt-4">
                    <i class="fas fa-globe-americas"></i> Commentary
                  </div>
                  <div class="mb-1">
                    <span>
                      Language:
                      <strong>
                        {{selectedEvent.commentaryLanguage}}
                      </strong>
                    </span>
                  </div>
                  <div>
                    <span>
                      User:
                      <strong v-if="isAdmin || eventAcl.clientAdmin || eventAcl.eo">
                        <el-popover
                          placement="right"
                          width="250"
                          v-model="showPopups.commentaryUserPopup"
                          trigger="click">
                          <div v-bar class="dd-popup-wrapper h-300">
                            <div>
                              <el-dropdown-item
                                  v-for="input in clientUsers"
                                  :key="input.id"
                                  @click.native="changeCommetnaryUser(input)"
                                  :title="input.email"
                                  >{{input.email | truncate(23)}}</el-dropdown-item>
                            </div>
                          </div>
                          <span class="el-dropdown-link" slot="reference">
                            {{selectedEvent.commentaryUser.email}}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                        </el-popover>
                      </strong>
                      <strong v-else>{{selectedEvent.commentaryUser.email}}</strong>
                    </span>
                  </div>
                </div>

                <el-divider></el-divider>
                <el-row :gutter="16">
                  <el-col :span="24">
                      <el-button
                      type="primary"
                      size="small"
                      style="width:100%"
                      icon="bi bly-reload"
                      :disabled="isPastServiceTime"
                      v-if="hasOttOutputs"
                      v-show="isAdmin || eventAcl.clientAdmin || eventAcl.eo || eventAcl.ec"
                      @click="restartIncomingStream(selectedEvent.id)">
                      Restart application
                    </el-button>
                  </el-col>
                </el-row>
                <el-row :gutter="16" v-if="eventStatus !== 'COMPLETED'">
                  <el-col :span="24">
                    <el-button
                      type="info"
                      size="small"
                      style="width:100%"
                      :disabled="isPastServiceTime || isLive"
                      v-show="isAdmin || eventAcl.clientAdmin
                        || eventAcl.eventCoordinator || eventAcl.ee"
                      @click="setRescheduleEventDialog(true)">
                      Reschedule
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button
                      type="success"
                      size="small"
                      style="width:100%"
                      icon="bi bly-play"
                      :disabled="isPastServiceTime"
                      v-show="isSysAdmin"
                      v-if="eventStatus !== 'LIVE'"
                      @click="startEventNow()">
                      Start now
                    </el-button>
                    <el-button
                      type="danger"
                      size="small"
                      style="width:100%"
                      icon="bi bly-stop"
                      v-show="isSysAdmin"
                      v-if="eventStatus === 'LIVE'"
                      @click="stopEventNow()">
                      Stop now
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button
                      type="grey"
                      size="small"
                      style="width:100%"
                      v-if="selectedEvent.status !== 'POSTPONED'"
                      :disabled="isPastServiceTime || isLive"
                      v-show="isAdmin || eventAcl.clientAdmin
                        || eventAcl.eventCoordinator || eventAcl.ee"
                      @click="confirmPostpone()">
                      Postpone
                    </el-button>
                    <el-button
                      type="grey"
                      size="small"
                      style="width:100%"
                      v-if="selectedEvent.status === 'POSTPONED'"
                      :disabled="isPastServiceTime || isLive"
                      v-show="isAdmin || eventAcl.clientAdmin
                        || eventAcl.eventCoordinator || eventAcl.ee"
                      @click="setAsPending()">
                      Set Pedning
                    </el-button>
                  </el-col>
                  <el-col :span="8">
                    <!--
                    <el-button
                      type="danger"
                      size="small"
                      style="width:100%"
                      icon="bi bly-close"
                      :disabled="isPastServiceTime"
                      v-show="isAdmin || eventAcl.clientAdmin
                        || eventAcl.eventCoordinator || eventAcl.ee"
                      @click="confirmDelete()">
                      Cancel
                    </el-button>
                    -->
                    <el-button
                      type="danger"
                      size="small"
                      style="width:100%"
                      icon="bi bly-close"
                      :disabled="selectedEvent.status === 'CANCELLED'"
                      v-show="isAdmin || eventAcl.clientAdmin
                        || eventAcl.eventCoordinator || eventAcl.ee"
                      @click="cancelWithComment()">
                      Cancel
                    </el-button>
                  </el-col>
                </el-row>
                <div
                  v-if="selectedEvent.status === 'LIVE' || selectedEvent.status === 'COMPLETED'"
                  v-show="isAdmin || eventAcl.clientAdmin
                    || eventAcl.eventCoordinator || eventAcl.ee || eventAcl.eo">
                  <el-row :gutter="16">
                    <el-col :span="12">
                      <el-button
                        type="success"
                        size="small"
                        style="width:100%"
                        @click="sendNothingToReport(selectedEvent)">
                        Send NTR report
                      </el-button>
                    </el-col>
                    <el-col :span="12">
                      <el-button
                        type="danger"
                        size="small"
                        style="width:100%"
                        @click="openReportDialog(selectedEvent)">
                        Create report
                      </el-button>
                    </el-col>
                  </el-row>
                </div>
                <br><br>
                <el-button
                  size="small"
                  style="width:100%"
                  v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator"
                  @click="duplicateBooking({extendedProps: selectedEvent})">
                  <i class="bi bly-clipbin"></i> Duplicate Booking
                </el-button>

                <el-button
                    size="small"
                    style="width:100%"
                    @click="createPdf(selectedEvent)">
                    Download PDF
                  </el-button>

                <br><br>
                <div class="sys-admin-menu" v-if="isSysAdmin">
                  <el-divider content-position="left">SYS ADMIN ONLY</el-divider>
                  <div><br></div>
                  <div class="label">
                    Group ID
                    <el-input v-model="selectedEvent.groupId" class="mb-1"  ></el-input>
                    <button type="button" class="el-button el-button--primary el-button--mini" @click="save('groupId', selectedEvent.groupId)"><!----><!----><span> Save </span></button>

                  </div>
                  <div class="label">
                    MOS CHECKED
                    <div style="float:right">
                    <el-switch v-model="selectedEvent.mosChecked" @change="save('mosChecked', selectedEvent.mosChecked)"></el-switch></div>
                  </div>
                  <div><br></div>
                  <div class="label">
                    Event Status
                  </div>
                  <el-dropdown trigger="click"
                    style="width:100%">
                    <el-button type="primary"
                      size="small"
                      style="width:100%">
                      Change status<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(status, key) in statusDisplayName"
                        :key="status"
                        @click.native="setStatus(key)">
                        <el-tag size="mini" :color="eventColorsSimple[key]" style="position:relative;top:4px;left:-4px;"></el-tag>
                        <span>{{status}}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div><br></div>
                  <div class="label" for="">Start Type <strong style="color: #000">{{this.selectedEvent.startType | defaultValue('---not set---')}}</strong></div>
                  <el-dropdown
                    style="width:100%">
                    <el-button type="default"
                      size="small"
                      style="width:100%">
                      Start type <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="save('startType','EVENT_NORMAL_START_STOP')">EVENT NORMAL START STOP</el-dropdown-item>
                      <el-dropdown-item @click.native="save('startType','EVENT_MANUAL_START')">EVENT MANUAL START</el-dropdown-item>
                      <el-dropdown-item @click.native="save('startType','EVENT_MANUAL_STOP')">EVENT MANUAL STOP</el-dropdown-item>
                      <el-dropdown-item @click.native="save('startType','EVENT_MANUAL_START_STOP')">EVENT MANUAL START STOP</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div><br></div>
                  <div class="label mt-3">
                    INPUT STREAMS
                  </div>
                  <el-card shadow="hover" style="margin-bottom: 4px" :body-style="{ padding: '8px' }"  v-for="item in eventInputStreams" :key="item.id">
                    {{item.inputStream.name}}
                    <div style="float:right">
                      <i class="bi bly-delete" style="cursor: pointer" @click="unmapInputStream(item)"></i>
                    </div>
                  </el-card>
                  <el-dropdown>
                    <el-button type="primary"
                      size="mini">
                      Add Input Stream <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="item in streamSources" @click.native="mapInputStream(item)" :key="item.id">{{item.name}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="label mt-3">
                    OUTPUT STREAMS
                  </div>
                  <div>
                    <el-card shadow="hover" style="margin-bottom: 4px" :body-style="{ padding: '8px' }"
                     v-for="item in eventOutputStreams" :key="item.id">
                      {{item.outputStream.name}}
                      <div style="float:right">
                        <i class="bi bly-delete" style="cursor: pointer" @click="unmapOutputStream(item)"></i>
                      </div>
                    </el-card>
                    <el-dropdown>
                      <el-button type="primary"
                        size="mini">
                        Add Output Stream <i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in streamDestinations" @click.native="mapOutputStream(item)" :key="item.id">{{item.name}}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <div class="label mt-3" for="">Delete Event</div>
                  <el-button
                    type="danger"
                    size="small"
                    style="width:100%"
                    @click="confirmDeleteEventPermanently()">
                    Delete Event Permanently
                  </el-button>
                </div>

                <br><br>
                <div class="updated-by">
                  <span>Updated by: </span>
                  {{selectedEvent.lastUpdatedBy.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <!-- -------------------------------------------------------------------------- -->
    <!-- ADD OUTPUTS DIALOG ------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Select output profile"
      :visible.sync="addOutputDialog"
      :close-on-click-modal="false"
      width="360px">
      <div class="dialog-form">
          <el-alert type="warning" v-show="hasOutputs" :closable="false">
            Existing outputs are going to be replaced.
          </el-alert>
          <br>
          <el-select
            v-model="outputProfileId"
            @clear="outputProfileId = null"
            clearable
            filterable
          >
            <el-option
              v-for="input in outputProfilesSorted"
              :key="input.id"
              :value="input.id"
              :label="input.name"
            ></el-option>
          </el-select>
          <el-checkbox v-model="shouldCopyStreamKeys" v-show="savedStreamKeys.length > 0">Copy stream keys</el-checkbox>
          <!-- {{savedStreamKeys}}
          <pre class="text-black text-xs">
            has keys to copy: {{savedStreamKeys.length > 0}}
            {{outputProfileId}}
            hasSelectedOutputProfileOTT : {{hasSelectedOutputProfileOTT}}
            {{selectedOutputProfile}}
          </pre> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOutputDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addOutputs(shouldCopyStreamKeys)" v-loading="loading">
          Add
        </el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- SET OUTPUTS STREAMKEY DIALOG --------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Set stream key"
      :visible.sync="setStreamKeyDialog"
      :close-on-click-modal="false"
      width="360px">
      <div class="dialog-form" v-if="tempTarget">
          Stream key for <strong>{{tempTarget.name}}</strong>: <br>
          <el-input v-model="tempTarget.streamKey"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setStreamKeyDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateStreamKey(tempTarget)" v-loading="loading">
          Save
        </el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- SET Transcoder Profile DIALOG --------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Set transcoder profile"
      :visible.sync="setTranscoderDialog"
      :close-on-click-modal="false"
      width="360px">
      <div class="dialog-form" v-if="tempTarget">
          Select transcoder profile for all OTT Outputs from the list
          <el-select
            v-model="tempTarget.transcoderProfile"
            @clear="tempTarget.transcoderProfile = null"
            clearable
            filterable
          >
            <el-option
              v-for="input in accountTranscodeProfiles"
              :key="input.id"
              :value="input.transcoderProfile"
              :label="input.name"
            ></el-option>
          </el-select>
      </div>
      <br>
      <div class="dialog-form" v-if="tempTarget && isSysAdmin">
          Or type it manualy
          <el-input v-model="tempTarget.sysAdminOption"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setTranscoderDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateTranscoderProfile(tempTarget)" v-loading="loading">
          Save
        </el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- SET OUTPUTS STREAMKEY DIALOG --------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Override OTT Application"
      :visible.sync="setOverrideOutputDialog"
      :close-on-click-modal="false"
      width="360px">
      <div class="dialog-form" v-if="tempTarget">
          Override OTT Application for <strong>{{tempTarget.name}}</strong>: <br>
          <el-input v-model="tempTarget.override"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setOverrideOutputDialog = false">Cancel</el-button>
        <el-button type="primary" @click="setOverrideOutputMap(tempTarget)" v-loading="loading">
          Save
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Set autostart"
      :visible.sync="setAutostartDialog"
      :close-on-click-modal="false"
      width="360px">
      <div class="dialog-form" v-if="tempWowzaConfig">
          <div class="mb-3">
            <el-switch v-model="tempWowzaConfig.autostart" active-text="Enable autostart">
            </el-switch>
          </div>
          <div v-show="tempWowzaConfig.autostart">
            Autostart offset (min): <br>
            <el-input-number
              v-model="tempWowzaConfig.autostartOffsetMin"
              controls-position="right"
              :min="0"
              :max="serviceStartDuration"></el-input-number>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setAutostartDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateAutostart(tempWowzaConfig)" v-loading="loading">
          Save
        </el-button>
      </span>
    </el-dialog>

    <!-- -------------------------------------------------------------------------- -->
    <!-- EVENT REPORT ------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      title="Create report for booking"
      :visible.sync="reportEventDialog"
      width="80%"
      :close-on-click-modal="false">
      <div class="send-report-dialog">
        <el-row :gutter="24">
          <el-col :span="16">
            <el-form ref="createReportForm" :model="reportForm" label-position="top">
              <strong>Issue description</strong>
              <quill-editor v-model="reportForm.description"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @ready="onEditorReady($event)">
              </quill-editor>
            </el-form>
          </el-col>
          <el-col :span="8">
            <div v-if="selectedEvent" class="tx-report-booking-details">
              <strong>{{selectedEvent.name}}</strong>
              <el-row>
                <el-col :span="8">
                  <div class="label">Line-up</div>
                  <div class="content">{{selectedEvent.lineupTime | date('HH:mm')}}</div>
                </el-col>
                <el-col :span="8">
                  <div class="label">Kick-off</div>
                  <div class="content">{{selectedEvent.startTime | date('HH:mm')}}</div>
                </el-col>
                <el-col :span="8">
                  <div class="label">End Time</div>
                  <div class="content">{{selectedEvent.endTime | date('HH:mm')}}</div>
                </el-col>
              </el-row>

              <div class="label">Fixture</div>
              <div class="content">{{selectedEvent.fixture | defaultValue('---')}}</div>

              <div class="label">Property</div>
              <div class="content">{{selectedEvent.property | defaultValue('---')}}</div>

              <div class="label">Sport</div>
              <div class="content">{{selectedEvent.sport | defaultValue('---')}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reportEventDialog = false">Cancel</el-button>
        <el-button type="primary" @click="sendReport" v-loading="loading">Create</el-button>
      </span>
    </el-dialog>
    <!-- -------------------------------------------------------------------------- -->
    <!-- OUTPUTS REPORT ----------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------- -->
    <el-dialog
      v-if="selectedOutput.output"
      :title="'Output connection info \r\n' + selectedOutput.output.name"
      :visible.sync="outputReportDialog"
      width="360px"
      :close-on-click-modal="false">
      <div class="send-report-dialog" v-if="selectedOutput.output">
        <div class="d mb-3" v-if="selectedOutput.target">
          <div class="font-bold uppercase text-xs">Stream key</div>
          <div>{{selectedOutput.target.streamKey || 'not set'}}</div>
        </div>
        <div class="d" v-if="selectedOutput.output.url">
          <div class="font-bold uppercase text-xs">URL
            <span class="text-gray-500 font-normal lowercase">(click to copy)</span>
          </div>
          <div @click="copyCode(selectedOutput.output.url)" class="cursor-pointer hover:underline">{{selectedOutput.output.url}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="outputReportDialog = false" v-loading="loading">OK</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import Vue from 'vue';

import config, { statusColors } from '../../config';
import { Amplify, Storage } from 'aws-amplify'

export default {
  name: 'AgendaPreview',
  props: {
    eventsWithTheSameOTT: Array,
  },
  data() {
    return {
      loading: false,
      event: {},
      isMobileOnly,
      previewPushPanel: true,
      // dialogs
      outputReportDialog: false,
      // forms
      eventAudioMapping: [
        {
          active: false,
          channelNo: 0,
          nameFieldL: 'Left',
          nameFieldR: 'Right',
        },
      ],
      searchInputOverride: '',
      showAudioMappingForm: false,
      showAudioMappingEditForm: false,
      audioMappingForm: {
        active: false,
        channelNo: 1,
        nameFieldL: null,
        nameFieldR: null,
        stereo: true,
      },

      colors: statusColors,
      config,

      // popups
      showPopups: {
        fixturePopup: false,
        propertyPopup: false,
        sportPopup: false,
        descriptionPopup: false,
        editBackupValue: null,
        addOutput: false,
        downlinkConfigPopup: false,
        inputAudioPidPopup: false,
        inputVideoPidPopup: false,
        commentaryUserPopup: false,
        inputDetailsPopup: false,
      },

      outputProfileId: null,

      fixture: '',
      sport: '',
      property: '',
      description: '',
      clientName: '',
      lineupTime: '',
      inputVideoPid: '',
      inputInfo: '',
      inputAudioPid: '',
      audoMappingPopups: [],
      tempAudioMapping: {
        channelNo: 0,
        nameFieldL: '',
        nameFieldR: '',
        active: false,
      },

      selectedEvent: null,
      selectedOutput: {},
      showAdvance: false,

      addOutputDialog: false,
      setStreamKeyDialog: false,
      setTranscoderDialog: false,
      setOverrideOutputDialog: false,
      tempTarget: null,
      reportEventDialog: false,

      tempWowzaConfig: null,
      setAutostartDialog: false,

      rescheduleEventDialog: false,
      rescheduleForm: {
        startDate: null,
        lineupDate: null,
        endDate: null,
        endTime: null,
        startTime: null,
        eventId: null,
        lineupTime: null,
      },
      statusTagStyles: {
        REJECTED: 'danger',
        CANCALED: 'danger',
        APPROVED: 'success',
        'CLIENT CONFIRMED': 'clientconfirmed',
        MOS_CONFIRMED: 'mosconfirmed',
        CONFIRMED: 'success',
        COMPLETED: 'info',
        IN_PROGRESS: 'warning',
        LINEUP: '',
        SCHEDULED: '',
        SUBMITTED: 'info',
      },

      reportForm: {
        ntr: false,
        eventId: null,
        description: null,
      },
      downlinkConfigForm: {
        satelliteName: null,
        satelliteFrequency: null,
        satellitePolarization: null,
        symbolRate: null,
        BISS: null,
        service: null,
      },
      editorOption: {
        theme: 'bubble',
        placeholder: 'Describe the issue',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
      },
      fileToUpload: null,
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date().setDate(new Date().getDate() - 1));
        },
      },
      uploadFileLoading: false,
      savedStreamKeys: [],
      shouldCopyStreamKeys: false,
    };
  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      userPermissions: (state) => state.userAccount.userPermissions,
      account: (state) => state.userAccount.account,
      client: (state) => state.userAccount.client,
      clients: (state) => state.userAccount.clients,

      // event: (state) => state.schedule.selectedEvent,
      listEvents: (state) => state.schedule.listEvents,
      loadings: (state) => state.schedule.loadings,
      events: (state) => state.schedule.events,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventColorsSimple: (state) => state.schedule.scheduleUIConfig.eventColorsSimple,
      statusDisplayName: (state) => state.schedule.statusDisplayName,
      eventTypes: (state) => state.schedule.eventTypes,
      inputs: (state) => state.schedule.inputs,
      outputs: (state) => state.schedule.outputs,
      outputProfiles: (state) => state.schedule.outputProfiles,
      eventStartType: (state) => state.schedule.eventStartType,
      eventsStats: (state) => state.schedule.eventsStats,
      awsExports: (state) => state.awsExports,

      appSettings: (state) => state.appSettings.settings,

      streamDestinations: (state) => state.stream.streamDestinations,
      streamSources: (state) => state.stream.streamSources,
      pictureFormats: (state) => state.input.pictureFormats,
      transcoderProfiles: (state) => state.permissions.transcoderProfiles,
    }),

    bgStatusColor() {
      return status => `bg-${status}`;
    },

    hardDisableEdit() {
      const { code } = this.selectedEvent.customer;
      const accountCode = this.selectedEvent.account.code;
      return !this.isSysAdmin && `${code}.${accountCode}` === 'plp.ots';
    },

    shouldChangeToClientConfirmed() {
      const hasStatus = this.eventStatus === 'RECEIVED' || this.eventStatus === 'CONFIRMED' || this.eventStatus === 'AWAITING_SIGNAL';
      const isBeforeServiceTime = moment.utc(this.selectedEvent.serviceStartTime).subtract(1, 'hour').isAfter(moment.utc());
      console.log('shouldChangeToClientConfirmed', hasStatus, isBeforeServiceTime, this.eventStatus)
      return hasStatus && isBeforeServiceTime;
    },

    defaultInputPictureFormat() {
      return this.pictureFormats.filter((el) => el.name === '1080i29.97')[0];
    },

    defaultOutputPictureFormat() {
      return this.pictureFormats.filter((el) => el.name === '1080i25')[0];
    },
    eventInputStreams() {
      return this.selectedEvent && this.selectedEvent.inputStreams ? this.selectedEvent.inputStreams.items : [];
    },
    eventOutputStreams() {
      return this.selectedEvent && this.selectedEvent.outputStreams ? this.selectedEvent.outputStreams.items : [];
    },

    clientUsers() {
      if (!this.clients) return [];
      const users = this.clients[this.eventClient.id].users.items.map((el) => el.user);
      const usersIds = users.map((user) => user.id);
      const filtered = users.filter(({ id }, index) => !usersIds.includes(id, index + 1));
      return filtered;
    },
    drawerSize() {
      console.log('window.screen.width;', window.screen.width);
      if (window.screen.width < 355) return '320px';
      return '360px';
    },

    eventAcl() {
      if (!this.event || !this.clients) return [];
      const { code } = this.event.customer;
      const accountCode = this.event.account.code;
      // const client = this.clients[code];
      const aclCurrent = {};

      if (this.isAdmin) {
        aclCurrent.admin = true;
        return aclCurrent;
      }
      const isClientAdmin = !!this.userPermissions[code]
        && this.userPermissions[code].accounts[code]
        && this.userPermissions[code].accounts[code].permissions.indexOf('ca') > -1;
      const iseventCoordinator = !!this.userPermissions[code]
        && this.userPermissions[code].accounts[code]
        && this.userPermissions[code].accounts[code].permissions.indexOf('ec') > -1;
      if (isClientAdmin) {
        aclCurrent.clientAdmin = true;
        return aclCurrent;
      }
      if (iseventCoordinator) {
        aclCurrent.eventCoordinator = true;
        aclCurrent.ec = true;
        return aclCurrent;
      }

      this.userPermissions[code].accounts[accountCode].permissions.forEach(
        (item) => {
          aclCurrent[item] = true;
        },
      );
      return aclCurrent;
    },

    eventDate() {
      return this.selectedEvent ? this.selectedEvent.startTime.split('T')[0] : '';
    },

    eventAccount() {
      return this.selectedEvent ? this.selectedEvent.account : { name: '' };
    },

    eventClient() {
      return this.selectedEvent ? this.selectedEvent.customer : { name: '' };
    },

    eventTitle() {
      return this.selectedEvent ? this.selectedEvent.fixture : '';
    },

    eventStatus() {
      return this.selectedEvent ? this.selectedEvent.status : null;
    },

    eventAttachments() {
      return this.selectedEvent
        && this.selectedEvent.attachments ? this.selectedEvent.attachments.split(',') : null;
    },

    eventStatusDisplayName() {
      return this.selectedEvent ? this.statusDisplayName[this.selectedEvent.status] : '';
    },

    eventServiceTimeRange() {
      return this.selectedEvent
        ? {
          start: this.selectedEvent.serviceStartTime,
          end: this.selectedEvent.serviceEndTime,
        } : null;
    },

    serviceStartDuration() {
      const duration = moment.duration(
        moment(this.selectedEvent.startTime).diff(moment(this.selectedEvent.serviceStartTime)),
      );
      return Math.floor(duration.asMinutes());
    },

    hasServiceTimeStarted() {
      const nowUtc = moment().utc();
      const { start, end } = this.eventServiceTimeRange;
      return moment(nowUtc)
        .isBetween(moment(start), moment(end), null, '[]');
    },

    isPastServiceTime() {
      const nowUtc = moment().utc();
      const { end } = this.eventServiceTimeRange;
      return moment(nowUtc).isAfter(moment(end));
    },

    isLive() {
      return this.selectedEvent.status === 'LIVE';
    },

    isBeforeServiceTimeStart() {
      // return this.selectedEvent.status === 'LIVE';
      const nowUtc = moment().utc();
      const { start } = this.eventServiceTimeRange;
      return moment(nowUtc).isBefore(moment(start));
    },

    claimsCanEdit() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [`ee.${accountId}.${clientId}`, `eo.${accountId}.${clientId}`];
    },

    claimsCanAccess() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
      ];
    },

    audioClaimsCanAccess() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
        `ca.${clientId}.${clientId}`,
        `aa.${accountId}.${clientId}`,
        `ec.${clientId}.${clientId}`,
      ];
    },

    audioClaimsCanEdit() {
      const accountId = this.eventAccount.code;
      const clientId = this.eventClient.code;
      return [
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
        `ca.${clientId}.${clientId}`,
        `aa.${accountId}.${clientId}`,
        `ec.${clientId}.${clientId}`,
      ];
    },

    unusedOutputs() {
      return this.accountOutputs
        .filter((item) => {
          const isAssigned = this.sortedActions
            .filter((action) => item.id === action.assignedOutput.id);
          return isAssigned.length === 0;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        })
        .map((item) => ({
          id: item.id,
          name: item.name,
          selected: false,
          description: item.description,
        }));
    },

    accountTranscodeProfiles() {
      const accessClaim = `usr.${this.eventAccount.code}.${this.eventClient.code}`;
      const hasItems = !!this.transcoderProfiles;
      if (!hasItems) return [];
      return this.transcoderProfiles
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1;
          return hasPermission;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },


    accountOutputs() {
      const accessClaim = `usr.${this.eventAccount.code}.${this.eventClient.code}`;
      const hasItems = !!this.outputs.items;
      if (!hasItems) return [];
      return this.outputs.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1;
          return hasPermission;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    allInputsFiltered() {
      return this.allInputs
        .filter((item) => {
          if(this.searchInputOverride === '' || this.searchInputOverride.length < 3) return true;
          return item.name.toLowerCase().includes(this.searchInputOverride.toLowerCase());
        });
    },

    allInputs() {
      console.log('%c inputs', 'color: red', this.inputs);
      const hasItems = !!this.inputs.items;
      if (!hasItems) return [];
      return this.inputs.items
        .slice(0)
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    accountInputs() {
      const accessClaim = `usr.${this.eventClient.code}`;
      const hasItems = !!this.inputs.items;
      if (!hasItems) return [];
      return this.inputs.items.filter((item) => {
        const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1 || item.claimsCanAccess.indexOf(this.accountAccessClaim) !== -1;
        return hasPermission;
      })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    inputsById() {
      const elements = {};

      this.accountInputs.forEach((item) => {
        elements[item.id] = item;
      });

      return elements;
    },

    // selectedEvent() {
    //   return this.event;
    // },

    basicOutputs() {
      if (!this.selectedEvent) return [];
      const outputs = this.selectedEvent.outputs.items;
      const actions = this.selectedEvent.actions.items;

      const basicOutputs = outputs ? outputs.filter((output) => {
        console.log(output);
        if (!output) return false;
        const { id } = output.output;
        const result = actions.filter((action) => {
          const hasAction = id === action.assignedOutput.id;
          return hasAction;
        });

        return result.length === 0;
      }) : [];
      return basicOutputs.sort();
    },

    actions() {
      if (!this.selectedEvent) return [];
      const actions = this.selectedEvent.actions.items
        .map((action) => {
          const actionOutputMap = this.selectedEvent.outputs.items
            .find((el) => action.assignedOutput.id === el.output.id);

          return {
            ...action,
            actionOutputMap,
          };
        });
      console.log('%cActions', 'color:lime', actions);
      return actions;
    },

    sortedActions() {
      if (!this.selectedEvent) return [];
      const sorted = this.actions
        .slice(0)
        .sort((a, b) => {
          if (a.assignedOutput.name < b.assignedOutput.name) { return -1; }
          if (a.assignedOutput.name > b.assignedOutput.name) { return 1; }
          return 0;
        });
      return sorted;
    },

    sortedAudioMapping() {
      const sorted = this.selectedEvent.audioMapping.items
        .slice(0)
        .sort((a, b) => {
          if (a.channelNo < b.channelNo) { return -1; }
          if (a.channelNo > b.channelNo) { return 1; }
          return 0;
        });
      return sorted;
    },

    inputName() {
      const { input } = this.selectedEvent;
      return input && input.name ? input.name : '--- not set ---';
    },

    inputStatus() {
      return (input) => (input && input.status === 'OFFLINE' ? input.status : '');
    },
    hasInputDetailsEnabled() {
      const { input } = this.selectedEvent;
      return input ? input.requireDetails : false;
    },

    overrideInputName() {
      const { inputOverride } = this.selectedEvent;
      return inputOverride && inputOverride.name ? inputOverride.name : 'Override input';
    },

    inputDetails() {
      const { input } = this.selectedEvent;
      return input && input.name ? input : {};
    },

    downLinkConfig() {
      const eventHasDownlinkConfig = this.selectedEvent && this.selectedEvent.inputDownlinkConfig;
      if (eventHasDownlinkConfig) return this.selectedEvent.inputDownlinkConfig;
      if (this.inputDetails.type === 'DOWNLINK' && this.inputDetails.downlinkConfig) return this.inputDetails.downlinkConfig;
      return null;
    },

    accountAccessClaim() {
      if (!this.selectedEvent) return '';
      return `usr.${this.selectedEvent.account.code}.${this.selectedEvent.customer.code}`;
    },

    hasOutputs() {
      return this.sortedActions.length !== 0 || this.basicOutputs.length !== 0;
    },

    hasOttOutputs() {
      // if(!this.hasOutputs) return false;
      const actions = this.sortedActions.filter((action) => action.assignedOutput.outputType === 'OTT');
      const outputs = this.basicOutputs.filter((output) => output.output.outputType === 'OTT');
      return actions.length > 0 || outputs.length > 0;
    },
    serviceStartTimeOffsetMin() {
      return moment(this.selectedEvent.lineupTime).diff(this.selectedEvent.serviceStartTime, 'minutes');
    },
    outputProfilesSorted() {
      if (!this.selectedEvent) return []
      this.customerAccessClaim = `usr.${this.selectedEvent.customer.code}`;
      if (!this.outputProfiles.items) return [];
      const profiles = this.outputProfiles.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(this.accountAccessClaim) !== -1 || item.claimsCanAccess.indexOf(this.customerAccessClaim) !== -1;
          return hasPermission;
        }).sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
      if (this.isMobileOnly) profiles.unshift({ name: 'None', id: -1 });
      return profiles;
    },
    selectedOutputProfile() {
      return this.outputProfilesSorted.filter((profile) => {
          return profile.id === this.outputProfileId;
      })[0] || {};
    },
    hasSelectedOutputProfileOTT() {
      const { outputs } = this.selectedOutputProfile;
      if (!outputs) return false;

      return outputs.items.filter((map) => {
        return map.output.outputType === 'OTT';
      }).length > 0;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.loading = true;
      const event = await this.getEvent(this.$route.params.id);
      this.processEvent(event);
      this.loading = false;
    }
  },

  watch: {
    async '$route.params.id'(newId) {
      console.log('rout changed agenda preview', newId);
      this.loading = true;
      const event = await this.getEvent(newId);
      this.processEvent(event);
      this.loading = false;
    },
    selectedEvent(newVal) {
      if (!newVal && this.isMobileOnly) {
        this.previewPushPanel = false;
        return;
      }
      this.lineupTime = newVal.lineupTime;
      this.audioMappingForm.channelNo = this.sortedAudioMapping.length + 1;

      this.rescheduleForm = {
        endDate: newVal.endTime.split('T')[0],
        startDate: newVal.startTime.split('T')[0],
        lineupDate: newVal.lineupTime.split('T')[0],
        eventId: newVal.id,
        endTime: newVal.endTime.split('T')[1],
        startTime: newVal.startTime.split('T')[1],
        lineupTime: newVal.lineupTime.split('T')[1],
        expectedVersion: newVal.version,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        tailTimeMin: newVal.tailTimeMin,
        headTimeMin: newVal.headTimeMin,
      };
      this.downlinkConfigForm = {
        satelliteName: null,
        satelliteFrequency: null,
        satellitePolarization: null,
        symbolRate: null,
        BISS: null,
        service: null,
      };
      if (this.downLinkConfig)  this.downlinkConfigForm = JSON.parse(JSON.stringify(this.downLinkConfig));
      this.previewPushPanel = true;
    },

    event(newVal) {
      if (!newVal) return;
      Vue.set(this, 'selectedEvent', newVal);
      this.fixture = newVal.fixture;
      this.sport = newVal.sport;
      this.property = newVal.property;
      this.description = newVal.description;
      this.lineupTime = newVal.lineupTime;
      this.inputAudioPid = newVal.inputAudioPid;
      this.inputVideoPid = newVal.inputVideoPid;
      // this.clientName = newVal.customer.name;
    },
  },

  methods: {
    ...mapActions({
      getEvent: 'schedule/getEvent',
      deleteEvent: 'schedule/deleteEvent',
      updateEvent: 'schedule/updateEventAgenda',
      rescheduleEvent: 'schedule/rescheduleEvent',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',
      updateEventAudioChannel: 'schedule/updateEventAudioChannel',

      startStreamTarget: 'schedule/startStreamTarget',
      stopStreamTarget: 'schedule/stopStreamTarget',
      restartIncomingStream: 'schedule/restartIncomingStream',
      setStreamKey: 'schedule/setStreamKey',
      setEventOutputMapOverride: 'schedule/setEventOutputMapOverride',
      eventManualStart: 'schedule/eventManualStart',
      eventManualStop: 'schedule/eventManualStop',
      eventInputChange: 'schedule/eventInputChange',
      eventOutputRemove: 'schedule/eventOutputRemove',
      addEventReport: 'schedule/addEventReport',
      eventOutputProfileChange: 'schedule/eventOutputProfileChangeAgenda',
      updateWowzaConfig: 'schedule/updateWowzaConfig',
      createDownlinkConfig: 'schedule/createDownlinkConfig',
      signDownloadUrl: 'schedule/signDownloadUrl',

      updateAppSetting: 'appSettings/updateAppSetting',
      createPdf: 'schedule/createPdf',

      mapEventInputStream: 'schedule/mapEventInputStream',
      mapEventOutputStream: 'schedule/mapEventOutputStream',

      unmapEventInputStream: 'schedule/unmapEventInputStream',
      unmapEventOutputStream: 'schedule/unmapEventOutputStream',
      updateWowzaTarget: 'schedule/updateWowzaTarget',
    }),
    ...mapMutations({
      setActiveEvent: 'schedule/setActiveEvent',
      setSelectedEventProperty: 'schedule/setSelectedEventProperty',
      setRescheduleEventDialog: 'schedule/setRescheduleEventDialog',
      setShowCreateEventDialog: 'schedule/setShowCreateEventDialog',
      setBookingClone: 'schedule/setBookingClone',
    }),

    openAddOutputDialog() {
      this.savedStreamKeys = this.sortedActions
        .filter((action) => {
          return action.assignedOutput.outputType === 'OTT';
        })
        .map((action) => {
          const [target] = action.configWowza.targets.items;
          return {
            streamKey: target.streamKey,
            target: target.target,
          };
        })
        .filter((action) => {
          return action.streamKey;
        });
      this.addOutputDialog = true;
    },

    duplicateBooking(booking) {
      this.setBookingClone(booking);
      this.setShowCreateEventDialog(true);
    },

    clearSelectedEvent() {
      this.setActiveEvent(null);
      this.selectedEvent = null;
    },

    edit(property, value) {
      this.showPopups[property] = true;
      this.editBackupValue = value;
    },

    cancel(property) {
      this.editBackupValue = null;
      this.showPopups[property] = false;
    },

    async save(property, value) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent[property] = value;
      if (property === 'fixture') {
        newEvent.name = value;
      }
      console.log('xxxxxx', newEvent, property, value);
      const result = await this.updateEvent(newEvent);
      this.event = result;
      if (property !== 'inputDetails' && property !== 'fixture' && property !== 'description') {
        this.editBackupValue = null;
        this.showPopups[`${property}Popup`] = false;
        return;
      }
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')

      this.editBackupValue = null;
      this.showPopups[`${property}Popup`] = false;
    },

    async toggleFRC(property, value) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent[property] = value;
      const input = this.defaultInputPictureFormat.id;
      const output = this.defaultOutputPictureFormat.id;
      newEvent.eventInputFrcInputFormatId = input;
      newEvent.eventInputFrcOututFormatId = output;

      const result = await this.updateEvent(newEvent);
      this.event = result;
      this.editBackupValue = null;
      this.showPopups[`${property}Popup`] = false;
    },

    async updateDownlinkConfig() {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      this.downlinkConfigForm.claimsCanEdit = newEvent.claimsCanEdit;
      this.downlinkConfigForm.claimsCanAccess = newEvent.claimsCanAccess;
      this.downlinkConfigForm.version = 1;
      this.downlinkConfigForm.inputDownlinkConfigLastUpdatedById = this.userId;

      const downlinkConfigId = await this.createDownlinkConfig(this.downlinkConfigForm);
      newEvent.eventInputDownlinkConfigId = downlinkConfigId.id;
      const result = await this.updateEvent(newEvent);
      this.event = result;
      // if (this.appSettings.symbolRate.indexOf(this.downlinkConfigForm.symbolRate) === -1) {
      //   this.appSettings.symbolRate.push(this.downlinkConfigForm.symbolRate);
      //   console.log(this.appSettings.symbolRate);
      //   this.updateAppSetting({ id: 'symbolRate', key: 'symbolRate', value: this.appSettings.symbolRate.join(',') });
      // }
      this.editBackupValue = null;
      this.showPopups.downlinkConfigPopup = false;
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')
    },

    async cancelWithComment() {
      const result = await this.$prompt('Please provide comment/reason for cancellation', 'Canceling booking', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
      });

      const isConfirmed = result.action === 'confirm';
      if (!isConfirmed) return;

      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent.cancellationMsg = result.value;
      newEvent.status = 'CANCELLED';
      console.log('test cancelWithComment', result, newEvent);
      await this.updateEvent(newEvent);
    },

    editAudioChannel(mapping, index) {
      this.tempAudioMapping = {
        ...mapping,
      };
      this.audoMappingPopups[index] = true;
    },

    cancelAudioEdit(index) {
      this.clearAudioPopup();
      this.audoMappingPopups[index] = false;
    },

    async saveAudioMapping(index) {
      const newMapping = {
        ...this.tempAudioMapping,
        eventAudioChannelLastUpdatedById: this.userId,
        // eventAudioMappingId: this.selectedEvent.id,
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
      };

      await this.updateEventAudioChannel(newMapping);
      this.clearAudioPopup();
      this.audoMappingPopups[index] = false;
      this.$forceUpdate();
    },

    clearAudioPopup() {
      this.tempAudioMapping = {
        channelNo: 0,
        nameFieldL: '',
        nameFieldR: '',
        active: false,
        stereo: true,
      };
    },

    async rescheduleBooking() {
      const {
        lineupTime,
        lineupDate,
        startTime,
        startDate,
        endTime,
        endDate,
        eventId,
        expectedVersion,
        accountId,
        clientId,
      } = this.rescheduleForm;
      const {
        headTimeMin,
        tailTimeMin,
      } = this.selectedEvent;

      const payload = {
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`).format(),
        startTime: moment.utc(`${startDate} ${startTime}`).format(),
        endTime: moment.utc(`${endDate} ${endTime}`).format(),
        headTimeMin,
        tailTimeMin,
        eventId,
        expectedVersion,
        accountId,
        clientId,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };
      const result = await this.rescheduleEvent(payload);
      const hasErrors = result.error;
      if (!hasErrors) this.rescheduleEventDialog = false;
    },

    async changeInput(input) {
      const data = {
        eventId: this.selectedEvent.id,
        newInputId: input.id,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        expectedVersion: this.selectedEvent.version,
      };

      await this.eventInputChange(data);
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')
      this.showPopups.inputPopup = false;
    },

    async changeCommetnaryUser(user) {
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      newEvent.eventCommentaryUserId = user.id;
      await this.updateEvent(newEvent);
      this.editBackupValue = null;

      this.showPopups.commentaryUserPopup = false;
    },

    async ovrrideInput(input) {
      this.loading = true;
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);

      newEvent.eventInputOverrideId = input.id;

      this.showPopups.ovrrideInputPopup = false;
      const result = await this.updateEvent(newEvent);
      this.event = result;
      this.editBackupValue = null;
      this.loading = false;
    },

    async addOutputs(shouldCopyStreamKeys) {
      this.loading = true;
      const data = {
        eventId: this.selectedEvent.id,
        outputProfileId: this.outputProfileId,
        accountId: this.eventAccount.code,
        clientId: this.eventClient.code,
        claimsCanAccess: this.selectedEvent.claimsCanAccess,
        claimsCanEdit: this.selectedEvent.claimsCanEdit,
      };
      const result = await this.eventOutputProfileChange(data);
      this.event = result;
      console.log('%c OUTPUT UPATE', 'color:lime', result);
      this.loading = false;
      if (!shouldCopyStreamKeys) {
        this.shouldCopyStreamKeys = false;
        this.addOutputDialog = false;
        return;
      }

      let actions = result.actions.items || [];
      actions = actions
        .map((action) => {
          const actionOutputMap = this.selectedEvent.outputs.items
            .find((el) => action.assignedOutput.id === el.output.id);

          return {
            ...action,
            actionOutputMap,
          };
        });
      const ottTargets = actions
        .filter((action) => {
          return action.assignedOutput.outputType === 'OTT';
        })
      console.log('%c OUTPUT UPATE', 'color:lime', ottTargets, result);
      let event = this.event;
      await this.asyncForEach(ottTargets, async (target, index) => {
        const streamKey = this.savedStreamKeys[index].streamKey || this.savedStreamKeys[0].streamKey;
        const model = {
          eventId: this.selectedEvent.id,
          streamTargetId: target.configWowza.targets.items[0].id,
          streamKey: streamKey,
          name: target.configWowza.targets.items[0].target,
        };
        event = await this.setStreamKey(model);
      });
      this.processEvent(event);
      this.shouldCopyStreamKeys = false;
      this.addOutputDialog = false;
      // this.shouldCopyStreamKeys = false;
      // const model = {
      //   eventId: this.selectedEvent.id,
      //   streamTargetId: target.configWowza.targets.items[0].id,
      //  streamKey: target.configWowza.targets.items[0].streamKey,
      //  name: target.configWowza.targets.items[0].target,
      // };
      // await this.setStreamKey(model);
    },

    async deleteOutput(output) {
      const [outputMapping] = this.selectedEvent.outputs.items
        .filter((item) => item.output.id === output.id);
      const doesMappingExist = !!outputMapping;

      if (!doesMappingExist) {
        this.$message.error(
          { message: 'Can\'t delete output. Output mapping does not exist.' },
        );
        return;
      }
      const data = {
        eventId: this.selectedEvent.id,
        input: {
          eventOutputMapId: outputMapping.id,
          accountId: this.eventAccount.code,
          clientId: this.eventClient.code,
          outputName: outputMapping.output.name,
        },
      };

      await this.eventOutputRemove(data);
    },

    getUpdatedTimes(lineupTime, startTime, endTime, headTimeMin, tailTimeMin) {
      const startDate = this.selectedEvent.startTime.split('T')[0];
      const endDate = this.selectedEvent.endTime.split('T')[0];
      const lineupDate = this.selectedEvent.lineupTime.split('T')[0];

      return {
        ...this.rescheduleForm,
        lineupTime: moment.utc(`${lineupDate} ${lineupTime}`)
          .format(),
        startTime: moment.utc(`${startDate} ${startTime}`)
          .format(),
        endTime: moment.utc(`${endDate} ${endTime}`)
          .format(),
        headTimeMin,
        tailTimeMin,
      };
    },

    async setStatus(status) {
      const confirmation = await this.$confirm('Are you sure you want to change status of the event?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', status);
    },

    async setAsPending() {
      const confirmation = await this.$confirm('Are you sure you want to set booking to pending?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'PENDING');
    },

    async confirmPostpone() {
      const confirmation = await this.$confirm('Are you sure you want to postpone booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'POSTPONED');
    },

    async confirmDeleteEventPermanently() {
      const confirmation = await this.$confirm('This will permanently delete booking. Are you sure?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.deleteEvent(this.selectedEvent);
      this.clearSelectedEvent();
    },

    async confirmDelete() {
      const confirmation = await this.$confirm('Are you sure you want to cancel booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      if (!this.isBeforeServiceTimeStart || this.eventStatus === 'LIVE') {
        this.save('status', 'LATE_CANCELLED');
        return;
      }
      this.save('status', 'CANCELLED');
    },

    async confirmConfirmation() {
      const confirmation = await this.$confirm('Once booking is confirmed MediaAtmos will confirm resources required and once done the status will change to "Resource confirmed"', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'CLIENT_CONFIRMED');
    },

    async sysAdminConfirmConfirmation() {
      const confirmation = await this.$confirm('Are you sure you want to confirm booking?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.save('status', 'CONFIRMED');
    },

    async startEventNow() {
      const confirmation = await this.$confirm('Are you sure you want to start booking now?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.eventManualStart(this.selectedEvent.id);
    },

    async stopEventNow() {
      const confirmation = await this.$confirm('Are you sure you want to stop booking now?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;
      this.eventManualStop(this.selectedEvent.id);
    },

    async startStream(target, event) {
      const confirmation = await this.$confirm('Are you sure you want to start the stream?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      await this.startStreamTarget({ streamTargetId: target.id, eventId: event.id });
    },

    async stopStream(target, event) {
      const confirmation = await this.$confirm('Are you sure you want to stop the stream?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });

      const isConfirmed = confirmation === 'confirm';
      if (!isConfirmed) return;

      await this.stopStreamTarget({ streamTargetId: target.id, eventId: event.id });
    },

    async addAudioChannel(form, object, event) {
      const doCreate = !!event.id;
      const formObject = this.$refs[form];
      const isValid = await formObject.validate();
      if (!isValid) return null;
      const audioMappingForm = {
        active: this.audioMappingForm.active,
        channelNo: this.audioMappingForm.channelNo,
        description: this.audioMappingForm.description,
        stereo: this.audioMappingForm.stereo,
      };
      console.log('this.audioMappingForm.stereo', this.audioMappingForm.stereo);

      // if existing event is attached create mapping
      if (!doCreate) {
        this.audioMappingForm.channelNo = this.audioMappingForm.channelNo + 1;
        this.audioMappingForm.description = '';
        object.push(audioMappingForm);
      }

      const newMapping = {
        ...audioMappingForm,
        eventAudioChannelLastUpdatedById: this.userId,
        eventAudioChannelEventId: event.id,
        claimsCanAccess: this.audioClaimsCanAccess,
        claimsCanEdit: this.audioClaimsCanEdit,
      };

      await this.createEventAudioChannel(newMapping);
      this.$forceUpdate();
      return false;
    },

    async deleteAudioChannel(id, event) {
      const needsUpdate = !!event.id;
      if (!needsUpdate) {
        // object.splice(index, 1);
        return false;
      }
      await this.deleteEventAudioChannel(id);
      this.$forceUpdate();
      return false;
    },

    async addTime(event, type, time) {
      const headTime = type === 'head' ? event.headTimeMin + time : event.headTimeMin;
      const tailTime = type === 'tail' ? event.tailTimeMin + time : event.tailTimeMin;

      const startTime = type === 'head' ? moment(event.startTime).subtract(time, 'minutes') : event.startTime;
      const endTime = type === 'tail' ? moment(event.endTime).add(time, 'minutes') : event.endTime;
      const newTimes = {
        ...this.rescheduleForm,
        lineupTime: event.lineupTime,
        startTime,
        endTime,
        headTimeMin: headTime,
        tailTimeMin: tailTime,
        serviceStartTimeOffsetMin: this.serviceStartTimeOffsetMin,
      };

      delete newTimes.lineupDate;
      delete newTimes.startDate;
      delete newTimes.endDate;
      await this.rescheduleEvent(newTimes);
      console.log('event updated', event)
      this.$forceUpdate();
    },

    async openAddTime(event, type) {
      try {
        const result = await this.$prompt('Please input time in minutes', 'Extend time', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputPattern: /^[0-9]/,
          inputErrorMessage: 'Invalid value',
        });

        const { value } = result;
        this.addTime(event, type, parseInt(value, 10));
      } catch (error) {
        if (error === 'cancel') return;
        this.$message.error('Something went wrong!');
      }
    },

    async updateStreamKey(target) {
      this.loading = true;
      const model = {
        eventId: target.eventId,
        streamTargetId: target.streamTargetId,
        streamKey: target.streamKey,
      };
      const event = await this.setStreamKey(model);
      this.processEvent(event);
      this.loading = false;
      this.$forceUpdate();
      this.setStreamKeyDialog = false;
      return false;
    },

    async updateTranscoderProfile(target) {
      const targets = this.sortedActions.filter((el) => {
        return el.assignedOutput.outputType === 'OTT';
      });

      await this.asyncForEach(targets, async (tr, index) => {
        const [wowzaTarget] = tr.configWowza.targets.items;
        const profile = target.sysAdminOption || target.transcoderProfile;
        const payload = {
          id: wowzaTarget.id,
          transcoderProfile: profile,
          expectedVersion: wowzaTarget.version,
          claimsCanEdit: wowzaTarget.claimsCanEdit,
          claimsCanAccess: wowzaTarget.claimsCanAccess,
        };
        console.log('transcoder Profile', targets, tr, target);
        await this.updateWowzaTarget({payload, id: this.selectedEvent.id});
      });

      console.log('transcoder Profile', targets, this.sortedActions, target);
      this.$forceUpdate();
      this.setTranscoderDialog = false;
      return false;
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },

    async setOverrideOutputMap(target) {
      console.log('.......', target);
      const model = {
        eventId: target.eventId,
        override: target.override,
        id: target.id,
      };
      await this.setEventOutputMapOverride(model);
      this.$forceUpdate();
      this.setOverrideOutputDialog = false;
      return false;
    },

    async updateAutostart(model) {
      await this.updateWowzaConfig({ input: model, eventId: this.selectedEvent.id });
      this.$forceUpdate();
      this.setAutostartDialog = false;
      return false;
    },

    editTranscoderProfile(target) {
      this.tempTarget = {
        eventId: this.selectedEvent.id,
        streamTargetId: target.configWowza.targets.items[0].id,
        transcoderProfile: target.configWowza.targets.items[0].transcoderProfile,
        name: target.configWowza.targets.items[0].target,
        sysAdminOption: null,
        target,
      };
      this.setTranscoderDialog = true;
    },

    editStreamKey(target) {
      this.tempTarget = {
        eventId: this.selectedEvent.id,
        streamTargetId: target.configWowza.targets.items[0].id,
        streamKey: target.configWowza.targets.items[0].streamKey,
        name: target.configWowza.targets.items[0].target,
      };
      this.setStreamKeyDialog = true;
    },

    editOutputOverride(target) {
      console.log('%ceditOutputOverride', 'color:blue', target);
      this.tempTarget = {
        eventId: this.selectedEvent.id,
        id: target.actionOutputMap.id,
        override: target.actionOutputMap.outputOttApplicationOverride,
        name: target.configWowza.targets.items[0].target,
      };
      this.setOverrideOutputDialog = true;
    },

    setAutostart(action) {
      const { configWowza } = action;
      this.tempWowzaConfig = {
        id: configWowza.id,
        claimsCanEdit: configWowza.claimsCanEdit,
        claimsCanAccess: configWowza.claimsCanAccess,
        autostart: configWowza.autostart,
        autostartOffsetMin: configWowza.autostartOffsetMin,
        expectedVersion: configWowza.version,
      };
      this.setAutostartDialog = true;
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        eventInputId: event.eventInputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        startType: event.startType,
        id: event.id,
        attachments: event.attachments,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: event.requestedBitrate,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        groupId: event.groupId,
        eventLastUpdatedById: this.userId,
      };
    },

    openReportDialog(event) {
      this.reportForm.eventId = event.id;
      this.reportForm.ntr = false;
      this.eventToReport = event.extendedProps;
      this.reportForm.description = '';
      this.reportEventDialog = true;

      this.reportForm.clientId = this.eventClient.code;
      this.reportForm.accountId = this.eventAccount.code;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
    },

    async sendReport() {
      const result = await this.addEventReport(this.reportForm);
      if (!result.error) this.reportEventDialog = false;
    },

    async sendNothingToReport(event) {
      this.reportForm.eventId = event.id;
      this.reportForm.clientId = this.eventClient.code;
      this.reportForm.accountId = this.eventAccount.code;
      this.reportForm.ntr = true;
      this.reportForm.claimsCanAccess = this.claimsCanAccess;
      this.reportForm.claimsCanEdit = this.claimsCanEdit;
      delete this.reportForm.description;
      await this.addEventReport(this.reportForm);
    },
    copyToClipboard(containerid) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.getElementById(containerid));
      selection.removeAllRanges(); // Remove all ranges from the selection.
      selection.addRange(range);
      document.execCommand('Copy');
      this.$message.success('Copied to clipboard');
      this.showPopups.downlinkConfigPopup = false;
      this.cancel('downlinkConfigPopup', 'downlink');
      setTimeout(() => {
        this.showPopups.downlinkConfigPopup = false;
        this.$forceUpdate();
      }, 1);
    },

    // UPLOAD MEHTODS
    async downloadAttachment(attachment) {
      const clientId = this.eventClient.code;
      const accountId = this.eventAccount.code;
      const key = `public/${clientId}/${accountId}/${attachment}`;
      const file = await this.signDownloadUrl(key);
      const link = document.createElement('a');
      link.download = attachment;
      link.href = file;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async handleUploadChange(file) {
      this.uploadFileLoading = true;
      const newEvent = this.getEventObjForUpdate(this.selectedEvent);
      this.fileToUpload = file;
      const name = file.name.substring(0, file.name.lastIndexOf('.'));
      const extension = file.name.substring(file.name.lastIndexOf('.'));
      const clientId = this.eventClient.code;
      const accountId = this.eventAccount.code;
      // const randomString = this.S4();
      const key = `${moment.utc().format('YYYYmmDD-hhmmss')}-${name}${extension}`;
      const result = await this.$Amplify.Storage.put(`${clientId}/${accountId}/${key}`, file.raw, {
        bucket: this.awsExports.aws_upload_bucket,
        region: 'eu-west-1',
        metadata: {
          account: accountId,
          client: clientId,
        },
      });
      this.uploadFileLoading = false;
      if (!result.key) {
        this.$message.error('Can\'t upload selected file. Please try again.');
        return;
      }
      newEvent.attachments += `,${key}`;
      const resultEvent = await this.updateEvent(newEvent);
      this.event = resultEvent;
      console.log('shouldChangeToClientConfirmed: upload event', this.shouldChangeToClientConfirmed);
      if (this.shouldChangeToClientConfirmed) await this.save('status', 'CLIENT_CONFIRMED')
    },
    async mapInputStream(source) {
      const payload = {
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
        eventInputStreamsMapEventId: this.selectedEvent.id,
        eventInputStreamsMapInputStreamId: source.id,
      };
      await this.mapEventInputStream(payload);
    },
    async mapOutputStream(dest) {
      const payload = {
        claimsCanAccess: this.claimsCanAccess,
        claimsCanEdit: this.claimsCanEdit,
        eventOutputStreamsMapEventId: this.selectedEvent.id,
        eventOutputStreamsMapOutputStreamId: dest.id,
      };
      await this.mapEventOutputStream(payload);
    },
    async unmapInputStream(source) {
      const payload = {
        id: source.id,
      };
      await this.unmapEventInputStream(payload);
    },
    async unmapOutputStream(dest) {
      const payload = {
        id: dest.id,
      };
      await this.unmapEventOutputStream(payload);
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array); // eslint-disable-line
      }
    },
    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$message.success('Copied to clipboard');
    },

    processEvent(event) {
      if (!event) return;
      const nowUtc = moment().utc();
      const item = event;
      const newItem = {
        ...event,
        id: item.id,
        start: item.startTime,
        startServiceTime: item.serviceStartTime,
        lineupTime: item.lineupTime,
        end: item.endTime,
        title: item.name,
        content: item.content,
        classNames: `${item.status} ${item.type}`,
        status: item.status,
        extendedProps: item,
        data: item,
        collapsed: true,
        isPastServiceTime: moment(nowUtc).isAfter(moment(item.serviceEndTime)),
      };

      if (item.customer) newItem.extendedProps.customerName = item.customer.name;
        const isOptus = 'otu' === newItem.extendedProps.customer.code && 'otu' === newItem.extendedProps.account.code;
        const isDownlink = newItem.extendedProps.input && newItem.extendedProps.input.type === 'DOWNLINK';
        console.log('CURRENT EVENTS CLIENT ACCOUNT', newItem.extendedProps.customer.code, newItem.extendedProps.account.code, isOptus, isDownlink);

        newItem.showRemap = isOptus && isDownlink;
        newItem.extendedProps.inputName = item.input ? item.input.name : '';
        newItem.extendedProps.inputOriginalName = item.input ? item.input.name : '';
        newItem.extendedProps.inputName += item.inputDetails ? `<div class="muted">${item.inputDetails}</div>` : '';
        newItem.extendedProps.overrideInputName = item.inputOverride ? item.inputOverride.name : '';

        if (newItem.extendedProps.overrideInputName !== '' && this.isSysAdmin) {
          newItem.extendedProps.inputName += ` ${newItem.extendedProps.overrideInputName} (OVERRIDE)`;
        }
        if (newItem.extendedProps.input && newItem.extendedProps.input.type === 'DOWNLINK') {
          if (newItem.extendedProps.inputDownlinkConfig) {
            const {
              BISS,
              satelliteFrequency,
              satelliteName,
              symbolRate,
            } = newItem.extendedProps.inputDownlinkConfig;
            const configMissing = BISS && satelliteFrequency && satelliteName && symbolRate;
            if (!configMissing) newItem.extendedProps.inputName += '<div class="warning"> DOWNLINK CONFIG INCOMPLETE</div>';
          } else {
            newItem.extendedProps.inputName += '<div class="warning">DOWNLINK CONFIG MISSING</div>';
          }
          if (newItem.showRemap) newItem.extendedProps.inputName += '<span class="remap-tag">REMAP</span>';
        }
        newItem.extendedProps.audio = '';
        newItem.extendedProps.audioPDF = '';
        item.audioMapping.items.forEach((channel) => {
          newItem.extendedProps.audio
            += `${channel.channelNo} ${channel.description}
          `;
          newItem.extendedProps.audioPDF += `${channel.channelNo} ${channel.description} \r\n`;
        });
        newItem.extendedProps.output = '';
        newItem.extendedProps.outputPDF = '';
        newItem.extendedProps.outputString = '';
        item.outputs.items
          .filter((output) => !!output)
          .forEach((el) => {
            const [outputConfig] = newItem.extendedProps.actions.items
              .filter((action) => action.assignedOutput.id === el.output.id);
            const hasWowzaConfig = outputConfig && outputConfig.configWowza;
            const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
            const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
            const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
            const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : '';
            // console.log('===> ', el, newItem.extendedProps, outputConfig);

            const urlPdf = el.output.url ? `URL: ${el.output.url}` : '';
            newItem.extendedProps.output += `
              <div class="output-${el.output.status}">
                ${el.output.displayName || el.output.name}
                <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
                <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
                ${hasWowzaConfig ? autostartString : ''}
                <div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>
              </div>
            `;

            const ott = `[${el.output.ottServer} - ${el.output.ottApplication}]`;
            const ottOverrride = `[OTT Override: ${el.outputOttApplicationOverride}]`;
            newItem.extendedProps.outputPDF += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''} ${urlPdf} \r\n`;

            newItem.extendedProps.outputString += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''}, `;
            if (el.outputOttApplicationOverride) {
              newItem.extendedProps.output += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>
              `;
            }
          });

        const hasRelatedEvents = this.eventsWithTheSameOTT.filter((ev) => ev.id === item.id).length > 0;
        if (hasRelatedEvents) newItem.extendedProps.output += '<span class="warning">OVERLAPPING EVENTS WITH THE SAME OUTPUT</span>';
        if (newItem.extendedProps.output === '') newItem.extendedProps.output = 'not set';
        if (newItem.extendedProps.inputName === '') newItem.extendedProps.inputName = 'not set';
        if (newItem.extendedProps.outputPDF === '') newItem.extendedProps.outputPDF = 'not set';
        if (newItem.extendedProps.audioPDF === '') newItem.extendedProps.audioPDF = 'not set';

        newItem.extendedProps.inputPDF = newItem.extendedProps.inputName.replace('<br>', '');
        this.setActiveEvent(newItem);
        this.event = newItem;
    },
  },
};
</script>

<style>
div#agenda-event-preview .time-editor {
  user-select: none;
}

div#agenda-event-preview .time-editor, div#agenda-event-preview .preview-panel-audio-channel-actions .btn-icon:hover, div#agenda-event-preview .content.editable {
  cursor: pointer;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}



.el-loading-spinner svg {
  margin: 0 auto;
}

#agenda-event-preview {
  user-select: text;
}
#agenda-event-preview .output-DELETED {
  text-decoration: line-through;
}
#agenda-event-preview .transcoder-profile i {
  top: 7px !important;
  margin-right: 4px;
}
#agenda-event-preview .bly-time.active {
  color: #67c23a;
}
#agenda-event-preview .donwling-config-information .label .icon-btn {
  float: right;
  position: relative;
  top: -14px;
}
#agenda-event-preview .donwling-config-information .bly-edit {
  right: 40px !important;
  top: -16px;
}
#agenda-event-preview .donwling-config-information .copy-all {
  top: -10px;
  position: relative;
}
#agenda-event-preview .donwling-config-information .copy-property {
  position: absolute;
  float: right;
  top: 0;
  right: 0px;
}
#agenda-event-preview .donwling-config-information #downlinkInfo div {
  position: relative;
}
#agenda-event-preview .donwling-config-information #downlinkInfo div span {
  display: block;
  position: relative;
}
#agenda-event-preview .donwling-config-information #downlinkInfo div i {
  display: none;
}
#agenda-event-preview .donwling-config-information #downlinkInfo div:hover {
  color: #ff9f31;
}
#agenda-event-preview .donwling-config-information #downlinkInfo div:hover i {
  display: block;
}
#agenda-event-preview .content:focus {
  outline: none;
}
#agenda-event-preview .attachments-upload {
  float: right;
  position: relative;
  top: -15px;
}
#agenda-event-preview .attachment-link {
  margin-top: 4px;
  line-height: 20px;
  cursor: pointer;
}
#agenda-event-preview .attachment-link:hover {
  color: #ff9f31;
}

.disableEditing {
  pointer-events: none;
}

.desktop .SCHEDULEDEFAULT .el-drawer__wrapper {
  z-index: 0 !important;
  max-width: 360px;
  left: auto;
}
.desktop .SCHEDULEDEFAULT .details-panel .el-dialog__wrapper {
  left: calc(100vw - 386px);
  top: 70px;
  z-index: 0;
}
.desktop .event-preview-drawer {
  position: fixed;
  top: 70px;
  right: 10px;
  bottom: 10px;
  height: calc(100vh - 82px);
}
.desktop .event-preview-drawer #el-drawer__title {
  margin-bottom: 0px;
}
.desktop #agenda-event-preview {
  height: calc(100vh - 136px);
}

.mobile .details-panel .el-dialog__wrapper {
  left: 0;
}
.mobile #agenda-event-preview {
  background: transparent;
  position: relative;
  right: 0;
  transition: all 0.4s ease-in;
  bottom: 0;
  top: -30px;
  z-index: 0;
  box-shadow: none !important;
  height: calc(100vh - 54px);
}
.mobile #agenda-event-preview .event-title {
  display: none;
}
.mobile #agenda-event-preview .wrapper {
  height: 100%;
}


div#agenda-event-preview .wrapper {
  height: 100%;
}

div#agenda-event-preview .el-alert {
  margin-bottom: 16px;
  margin-top: -10px;
  padding: 8px;
}
div#agenda-event-preview .close-btn {
  float: right;
  opacity: 0.7;
  display: none;
}
div#agenda-event-preview .two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
div#agenda-event-preview .three-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
div#agenda-event-preview .event-title {
  font-size: 18px;
  color: #000;
  font-weight: normal;
  margin: 0 0 10px;
}
div#agenda-event-preview.open {
  right: 0;
}
div#agenda-event-preview .el-button--small {
  margin-left: 0px;
  margin-top: 6px;
}
div#agenda-event-preview i.bi {
  position: relative;
  top: 2px;
}
div#agenda-event-preview .label {
  color: #969ba9;
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 2px;
  text-transform: uppercase;
  position: relative;
}
div#agenda-event-preview .label .actions {
  position: absolute;
  right: 0;
  top: -15px;
  color: #000;
  font-size: 14px;
}
div#agenda-event-preview .label.outputs-header {
  margin-bottom: -9px;
}
div#agenda-event-preview .content {
  margin-bottom: 20px;
  line-break: 20px;
  font-size: 14px;
  position: relative;
  color: #000;
}
div#agenda-event-preview .content.editable .bly-edit {
  opacity: 0;
  color: #66718D;
  position: absolute;
  margin-left: 10px;
  right: 0px;
}
div#agenda-event-preview .content.editable:hover {
  text-decoration: dashed underline #0077FF;
}
div#agenda-event-preview .content.editable:hover .bly-edit {
  opacity: 1;
}
div#agenda-event-preview .content.editable:hover .label {
  text-decoration: solid underline white !important;
}
div#agenda-event-preview .content i {
  vertical-align: top;
  position: relative;
  top: 1px;
}
div#agenda-event-preview .content textarea {
  width: 100%;
  border: 0px;
  background: transparent;
}
div#agenda-event-preview .content .input {
  position: relative;
}
div#agenda-event-preview .content .input input {
  width: 100%;
  margin-bottom: 15px;
  line-height: 38px;
  padding: 0 8px;
  color: #66718d;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #dde0e6;
  padding-right: 110px;
}
div#agenda-event-preview .content .input .target .status {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #7f8cad;
  border-radius: 5px;
}
div#agenda-event-preview .content .input .target .status.NotFound {
  background: #fc487f;
}
div#agenda-event-preview .content .input .target .status.Active {
  background: #00d56b;
}
div#agenda-event-preview .content .actions {
  position: absolute;
  top: 0px;
  right: 0px;
}
div#agenda-event-preview .content .actions .icon-btn {
  line-height: 30px;
}
div#agenda-event-preview .content .actions .icon-btn:hover {
  background: #fff;
  color: #000 !important;
}
div#agenda-event-preview .content .actions .icon-btn.bly-play:hover {
  color: #00FF80 !important;
}
div#agenda-event-preview .content .actions .icon-btn.bly-stop:hover {
  color: #ea0d51 !important;
}
div#agenda-event-preview .content .actions .btn {
  margin: 0 0 0 4px;
  width: 32px;
  padding: 0;
  text-align: center;
}
div#agenda-event-preview .el-form-item .el-switch {
  float: right;
}
div#agenda-event-preview .preview-panel-audio-channel {
  border: 1px solid #CDD6EE;
  padding: 5px 8px;
  border-radius: 3px;
  margin-bottom: 4px;
  position: relative;
}
div#agenda-event-preview .preview-panel-audio-channel-number {
  width: 20px;
  margin-bottom: 0px;
  float: left;
}
div#agenda-event-preview .preview-panel-audio-channel-stereo {
  float: right;
  top: -18px;
  right: 2px;
  position: relative;
}
div#agenda-event-preview .preview-panel-audio-channel .audio-is-active {
  border-radius: 10px;
  background: #00FF80;
  width: 10px;
  height: 10px;
  margin-top: 4px;
  margin-left: 0px;
  z-index: 9;
  position: absolute;
  top: 4px;
  left: 23px;
}
div#agenda-event-preview .preview-panel-audio-channel-actions {
  position: absolute;
  right: 10px;
  z-index: 1;
  background: #fff;
  top: 1px;
  bottom: 5px;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
div#agenda-event-preview .preview-panel-audio-channel-actions .btn-icon {
  line-height: 24px;
  width: 24px;
  text-align: center;
  color: #66718D;
  margin-left: 8px;
}
div#agenda-event-preview .preview-panel-audio-channel-actions .btn-icon:hover {
  color: #1A9DFF;
}
div#agenda-event-preview .preview-panel-audio-channel:hover .preview-panel-audio-channel-actions {
  opacity: 1;
  pointer-events: auto;
}
div#agenda-event-preview .preview-panel-audio-channel-name-left {
  position: relative;
  padding-left: 30px;
  font-size: 13px;
}
div#agenda-event-preview .preview-outputs .el-card {
  margin-bottom: 12px;
  padding-bottom: 16px;
}
div#agenda-event-preview .preview-outputs .el-card small {
  color: #66718D;
  font-weight: normal;
}
div#agenda-event-preview .preview-outputs .el-card small.stream-key {
  font-size: 14px;
  word-break: break-all;
  margin-top: 4px;
  display: block;
}
div#agenda-event-preview .preview-outputs .el-card small.stream-key i {
  top: 4px;
}
div#agenda-event-preview .preview-outputs .el-card small.transcoder-profile {
  font-size: 14px;
  word-break: break-all;
  margin-top: 4px;
  display: block;
}
div#agenda-event-preview .preview-outputs .el-card small.transcoder-profile i {
  top: 4px;
}
div#agenda-event-preview .preview-outputs .el-card .actions {
  bottom: 0;
  top: auto;
}
div#agenda-event-preview .time-editor .bi {
  opacity: 0;
  pointer-events: none;
}
div#agenda-event-preview .time-editor:hover .bi {
  opacity: 1;
}
div#agenda-event-preview .time-editor .el-date-editor {
  position: absolute;
  left: 0;
  opacity: 0.01;
  height: 20px;
}
div#agenda-event-preview .time-editor .el-date-editor .el-input__prefix {
  width: 100%;
}
div#agenda-event-preview .time-editor .el-date-editor .el-input__prefix .el-input__icon {
  width: 100%;
}
div#agenda-event-preview .time-editor .el-date-editor input {
  pointer-events: none;
}

.el-card {
  position: relative;
}
.el-card.new-output-card {
  margin-bottom: 10px;
  cursor: pointer;
}
.el-card .el-switch {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -10px;
}
.el-card .el-switch.pointer-diabled {
  pointer-events: none;
}

.tx-report-booking-details {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 4px;
}
.tx-report-booking-details .content {
  color: #000;
}
.tx-report-booking-details .label {
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 2px;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 16px;
}

.el-alert .el-alert__description {
  margin: 5px 0;
}
.el-alert .el-alert__description el-button {
  position: absolute;
  right: 0;
  top: 9px;
  right: 6px;
}

.theme-mini .details-panel .event-preview-drawer #el-drawer__title .el-tag--mini {
  top: 0;
  position: absolute;
  right: 0;
  font-size: 12px;
  padding: 4px 8px;
  height: auto;
  border-radius: 0px 0px 0px 5px;
  font-weight: 400;
  letter-spacing: 1px;
}
.theme-mini .panel-info-message {
  padding: 40px;
  font-size: 18px;
  text-align: center;
  display: grid;
  height: 100%;
  grid-template-rows: auto;
  align-content: center;
}
.theme-mini .panel-info-message div {
  overflow: hidden !important;
}
.theme-mini .panel-info-message svg {
  display: block;
  margin: 20px auto;
}
.theme-mini .panel-info-message i {
  display: block;
  font-size: 4em;
  margin-bottom: 30px;
}
.theme-mini .el-dropdown-link {
  cursor: pointer;
}
</style>
