<template>
  <div
    id="schedule"
    class="noselect"
    :class="{agenda: showAgenda, 'ma-user': isMaUser}"
  >
    <div class="schedule-left"
      :class="{'show-account-codes': isAdmin || acl.clientAdmin}">
      <FullCalendar
        :defaultView="calendarDefaultView"
        locale="en-gb"
        ref="calendar"
        :plugins="calendarPlugins"
        :header="headerConfig"
        :timeZone="'UTC'"
        :minTime="minTime"
        :maxTime="maxTime"
        :views="viewsSettings"
        :viewSkeletonRender="calendarRenderCallBack"
        @eventClick="eventClick"
        @eventMouseover="eventHover"
        :eventRender="eventRender"
        :datesRender="datesRender"
        :defaultDate="defaultStartDate"
        :scrollTime="defaultStartDate"
        :scrollToTime="'14:00'"
        :firstDay="1"
        :events="currentEvents"/>

      <div id="loading-view"
        v-loading="loadings.calendar"
        element-loading-text="Loading bookings"
        element-loading-background="rgba(19, 25, 29, 1)"
        >
      </div>
      <div id="no-bookings-message"
      class="no-bookings-message"
        v-loading="currentEvents.length === 0"
        element-loading-spinner="bi bly-warning-2"
        element-loading-text="There are no bookings for selected date range"
        element-loading-background="rgba(19, 25, 29, 0.5)"
        >
      </div>
      <div id="agenda-view" v-if="showAgenda">
        <router-view/>
      </div>
      <div v-html="statusStyle"></div>
      <div v-html="timeAxisStyle"></div>

      <!-- -------------------------------------------------------------------------- -->
      <!-- UPLOAD FILE DIALOG ------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------- -->
      <el-dialog
        title="Upload schedules"
        :visible.sync="uploadDialogVisible"
        width="640px"
        :close-on-click-modal="false"
        :before-close="handleUploadClose">
        <div class="upload-dialog">
          <div class="servers">
            <div>Type <br></div>
            <el-radio-group v-model="uploadedScheduleType" size="small">
              <el-radio :label="type" border v-for="type in uploadedScheduleTypes" :key="type">
                {{type | uppercase}}
              </el-radio>
            </el-radio-group>
          </div>

          <div class="upload">
            <el-upload
              class="upload-files"
              drag
              ref="upload"
              action="uploadAction"
              :auto-upload="false"
              :on-change="handleUploadChange"
              :show-file-list="true"
              :multiple="true"
              :accept="'.csv'"
              :http-request="handleHTTPUpload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Drop file here or <em>click to select</em></div>
              <div class="el-upload__tip" slot="tip">
                Supported files: .csv
              </div>
            </el-upload>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="uploadDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="uploadFiles">Upload</el-button>
        </span>
      </el-dialog>

      <!-- -------------------------------------------------------------------------- -->
      <!-- ADD EVENT DIALOG ------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------- -->
      <router-view name="addBooking"/>
      <!-- -------------------------------------------------------------------------- -->
      <!-- RESCHEDULE DIALOG -------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------- -->
      <router-view name="reschedule"/>

      <!-- -------------------------------------------------------------------------- -->
      <!-- GENERATE REPORT DIALOG --------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------- -->
      <el-dialog
        title="Generate TX Report"
        :visible.sync="generateReportDialog"
        width="96%"
        class="report-dialog-window"
        :close-on-click-modal="false">
        <table class="tx-reports-header" v-if="!isMobileOnly">
          <tr>
            <th width="70px">Line-up</th>
            <th width="70px">Start</th>
            <th width="70px">End</th>
            <th width="75px">Tail Time</th>
            <th width="75px">Head Time</th>
            <!--<th width="200px">Fixture</th>-->
            <th width="150px">Sport</th>
            <th width="200px">Property</th>
            <th width="150px">Client</th>
            <th width="170px">Input</th>
            <th width="160px">Output</th>
            <th>Audio</th>
          </tr>
        </table>
        <div class="report-dialog" v-bar>
          <div>
            <div class="tx-reports">
              <div class="tx-reports-day-group"
                v-for="(reportGroup, reportDate) in txReports" :key="reportDate">
                <div class="tx-reports-day-group-date">
                  <span>{{reportDate | date('DD/MM/YYYY')}}</span>
                </div>
                <!-- DESKTOP VERSION -->
                <div v-if="!isMobileOnly">
                  <div
                    class="tx-report"
                    v-for="report in reportGroup" :key="report.id"
                    :class="{ntr: report.ntr}">
                    <div class="tx-report-event">
                      <div class="tx-report-event-name">
                        <strong>{{report.event.fixture}}</strong>
                      </div>
                      <div class="tx-report-event-details">
                        <table>
                          <tr>
                            <td width="70px">{{report.event.lineupTime | date("HH:mm")}}</td>
                            <td width="70px">{{report.event.startTime | date("HH:mm")}}</td>
                            <td width="70px">{{report.event.endTime| date("HH:mm") }}</td>
                            <td width="75px">{{report.event.tailTime | defaultValue("0")}}</td>
                            <td width="75px">{{report.event.headTime | defaultValue("0")}}</td>
                            <!--<td width="200px">{{report.event.fixture}}</td>-->
                            <td width="150px">{{report.event.sport}}</td>
                            <td width="200px">{{report.event.property}}</td>
                            <td width="150px">{{report.event.customer.name}}</td>
                            <td width="170px"
                              v-if="!report.event.input" class="warning">Not set</td>
                            <td width="170px"
                              v-if="report.event.input">
                                {{report.event.input.name | defaultValue('---')}}
                            </td>
                            <td>
                              <el-popover
                              placement="bottom"
                              trigger="hover">
                                <div class="output" v-for="output in report.event.outputs.items"
                                  :key="output.id">
                                  {{output.output.name}} <br>
                                  {{output.output.description}}
                                </div>
                                <span slot="reference">
                                  <strong>{{report.event.outputs.items.length}}</strong> outputs
                                  <i class="bi bly-info"></i>
                                </span>
                              </el-popover>
                            </td>
                            <td>
                              <el-popover
                                placement="bottom"
                                trigger="hover"
                                :disabled="report.event.audioMapping.items.length === 0">
                                <div class="audio"
                                  v-for="mapping in report.event.audioMapping.items"
                                  :key="mapping.id">
                                  {{mapping.channelNo}} {{mapping.description}}
                                  <span v-show="mapping.active">(active)</span>
                                </div>
                                <span slot="reference">
                                  <strong>
                                    {{report.event.audioMapping.items.length}}
                                  </strong> audio channels
                                  <i class="bi bly-info"
                                    v-show="report.event.audioMapping.items.length !== 0"
                                  ></i>
                                </span>
                              </el-popover>

                            </td>
                          </tr>
                        </table>

                      </div>
                    </div>
                    <div class="tx-report-issue" v-if="report.description">
                      <i class="bi bly-warning-2 red"></i>
                      {{report.description | stripTags | truncate(100) }}
                      <span
                        v-show="report.description && report.description.length > 100"
                        class="link primary"
                        @click="showMoreDetails(report.description)">show full report</span>
                    </div>
                  </div>
                </div>
                <!-- MOBILE VERSION -->
                <div v-if="isMobileOnly">
                  <div
                    class="tx-report tx-report-mobile"
                    v-for="report in reportGroup" :key="report.id"
                    :class="{ntr: report.ntr}">
                    <div class="tx-report-event">
                      <div class="tx-report-event-name">
                        <strong>{{report.event.name}}</strong>
                      </div>
                      <div class="tx-report-event-details">
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Line-up Time
                          </div>
                        <div>  {{report.event.lineupTime | date("HH:mm")}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Start Time
                          </div>
                        <div>  {{report.event.startTime | date("HH:mm")}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            End Time
                          </div>
                        <div>  {{report.event.endTime| date("HH:mm") }}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Tail Time
                          </div>
                        <div>  {{report.event.tailTime | defaultValue("0")}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Head Time
                          </div>
                        <div>  {{report.event.headTime | defaultValue("0")}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Fixture
                          </div>
                        <div>  {{report.event.fixture}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Sport
                          </div>
                        <div>  {{report.event.sport}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Property
                          </div>
                        <div>  {{report.event.property}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Client
                          </div>
                        <div>  {{report.event.customer.name}}</div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Input
                          </div>
                          <div>
                            <span
                              v-if="!report.event.input" class="warning">Not set</span>
                            <span
                              v-if="report.event.input">
                                {{report.event.input.name | defaultValue('---')}}
                            </span>
                          </div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Outputs
                          </div>
                          <div>
                          <el-popover
                          placement="bottom"
                          trigger="hover">
                            <div class="output" v-for="output in report.event.outputs.items"
                              :key="output.id">
                              {{output.output.name}} <br>
                              {{output.output.description}}
                            </div>
                            <span slot="reference">
                              <strong>{{report.event.outputs.items.length}}</strong> outputs
                              <i class="bi bly-info"></i>
                            </span>
                          </el-popover>
                          </div>
                        </div>
                        <div class="tx-report-event-details-entry">
                          <div class="tx-report-event-label">
                            Outputs
                          </div>
                          <div>
                          <el-popover
                            placement="bottom"
                            trigger="hover"
                            :disabled="report.event.audioMapping.items.length === 0">
                            <div class="audio" v-for="mapping in report.event.audioMapping.items"
                              :key="mapping.id">
                              {{mapping.channelNo}} {{mapping.description}}
                              <span v-show="mapping.active">(active)</span>

                            </div>
                            <span slot="reference">
                              <strong>{{report.event.audioMapping.items.length}}</strong>
                              audio channels
                              <i class="bi bly-info"
                                v-show="report.event.audioMapping.items.length !== 0"
                              ></i>
                            </span>
                          </el-popover>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tx-report-issue" v-if="report.description">
                      <i class="bi bly-warning-2 red"></i>
                      {{report.description | stripTags | truncate(100) }}
                      <span
                        v-show="report.description && report.description.length > 100"
                        class="link primary"
                        @click="showMoreDetails(report.description)">show full report</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <el-dialog
          :width="dialogWidth('30%')"
          title="Issue description"
          :visible.sync="generateReportDetailsDialog"
          append-to-body>
          <div class="tx-report-issue-description">
            <div v-html="reportFullDescription"></div>
          </div>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
          <el-button @click="generateReportDialog = false">Cancel</el-button>
          <el-button type="primary" @click="generateReport(eventReports.items)">Generate</el-button>
        </span>
      </el-dialog>
      <!-- -------------------------------------------------------------------------- -->
      <!-- RESURCES DIALOG ---------------------------------------------------------- -->
      <!-- -------------------------------------------------------------------------- -->
      <div v-if="showResourcesUsed">
        <bly-modal :show.sync="showResourcesUsed" :overflow="true">
          <h3 slot="header">Input resource view</h3>
          <bly-resource-view-2 :dataset="resourcesUsed" :range="scheduleDateRange">
          </bly-resource-view-2>
        </bly-modal>
      </div>
    </div>
    <router-view name="right" />

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- NAV OPTIONS -------------------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------------------------------- -->
    <div class="config mv-config">
      <div v-if="!isMobileOnly">
        <el-tooltip content="Upload schedule"
          v-if="false">
          <i class="bi bly-upload icon-btn" @click="uploadDialogVisible = true"></i>
        </el-tooltip>
        <el-tooltip content="Add booking"
          v-if="isAdmin || acl.clientAdmin || acl.eventCoordinator || acl.ee">
          <i class="bi bly-plus icon-btn" @click="openCreateEventDialog()"></i>
        </el-tooltip>

        <el-tooltip content="Generate TX Report"
          v-if="isSysAdmin || acl.clientAdmin">
          <i class="bi bly-tasks icon-btn" @click="showGenerateReportDialog()"></i>
        </el-tooltip>
        <el-divider direction="vertical" v-if="isSysAdmin || hasMultipleClients"></el-divider>
        <el-tooltip content="Show Cancelled Bookings"
          v-if="isSysAdmin">
          <i class="bi bly-close icon-btn" :class="{active: showCanceled}"
            @click="showCanceled = !showCanceled"></i>
        </el-tooltip>
        <el-tooltip content="See all bookings"
          v-if="isSysAdmin || hasMultipleClients">
          <el-switch
            style="display: inline-block; top:-4px;left: 16px; color:red"
            v-model="showAllEvents"
            @change="showAllEventsChanged"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Show all bookings">
          </el-switch>
        </el-tooltip>
        <el-tooltip content="See only active">
          <el-switch
            style="display: inline-block; top:-4px;left: 26px; color:red"
            v-model="showAllActiveEvents"
            @change="showAllActiveEventsChanged"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Show completed">
          </el-switch>
        </el-tooltip>
        <el-tooltip style="visibility:hidden">
          <i class="bi bly-plus icon-btn"></i>
        </el-tooltip>
        <el-divider direction="vertical" v-if="isSysAdmin || hasMultipleClients"></el-divider>
        <el-tooltip content="Resources used" placement="right">
          <span class="relative">
            <!-- <i class="bi  far fa-stream icon-btn py-3" v-show="isSysAdmin" @click="showResourcesUsed = true"></i> -->
            <svg class="bi icon-btn py-3" v-show="isSysAdmin" @click="showResourcesUsed = true" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M16 128h416c8.84 0 16-7.16 16-16V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v32c0 8.84 7.16 16 16 16zm480 96H80c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zm-64 160H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" fill="currentColor"/></svg>
             <span v-show="hasOverlapingInputs" class="absolute top-0 right-2 block h-1.5 w-1.5 transform -translate-y-1/2 translate-x-1/2 rounded-full bg-red-500"></span>
          </span>
        </el-tooltip>
        <el-tooltip content="Key" placement="right">
          <el-popover
            placement="top-start"
            trigger="hover">
            <bly-schedule-help></bly-schedule-help>
            <i slot="reference" class="bi bly-question icon-btn"></i>
          </el-popover>
        </el-tooltip>
      </div>
      <div v-if="isMobileOnly">
        <i class="bi bly-more bi-rotate-90 icon-btn" @click="mobileConfigNav= true"></i>
        <el-drawer
          custom-class="config-push-nav"
          size="300px"
          :title="'View options'"
          :visible.sync="mobileConfigNav"
          :with-header="false">
          <div v-bar class="drawer-wrapper">
            <div>
              <el-dropdown-item
                v-if="false"
                icon="bly-upload"
                @click.native="uploadDialogVisible = true">
                Upload schedule
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isAdmin || acl.clientAdmin || acl.ee || acl.eventCoordinator"
                icon="bi bly-plus"
                @click.native="openCreateEventDialog()">
                Add booking
              </el-dropdown-item>
              <el-dropdown-item
              v-if="isSysAdmin"
                icon="bi bly-tasks"
                @click.native="showGenerateReportDialog()">
                Generate TX Report
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isSysAdmin"
                divided>
                <el-switch
                  v-model="showCanceled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Show Cancelled Bookings">
                </el-switch>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isSysAdmin || hasMultipleClients">
                <el-switch
                  v-model="showAllEvents"
                  active-color="#13ce66"
                  inactive-color="#ff4949"

                  @change="showAllEventsChanged"
                  active-text="See all bookings">
                </el-switch>
              </el-dropdown-item>
              <el-dropdown-item>
                  <el-switch
                    style="display: inline-block; top:-4px;left: 26px; color:red"
                    v-model="showAllActiveEvents"
                    @change="showAllActiveEventsChanged"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Show completed">
                  </el-switch>
              </el-dropdown-item>
              <el-divider>
              </el-divider>
              <div class="mobile-status-legend">
                <h3><strong>Booking color coding</strong></h3>
                <img  v-if="false" src="../assets/schedule-status-legend.svg" alt="" >
                <div>
                  <div class="status-legend"
                    v-for="(status, key) in scheduleUIConfig.eventColorsSimple" :key="key">
                    <span class="color"
                      :style="{background: status}"></span>
                    <span class="name">{{key.replace('_', ' ')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    <bly-schedule-stats :eventsStats="eventsStats"></bly-schedule-stats>
  </div>
</template>

<script>
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { mapState, mapActions, mapMutations } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';

import ScheduleService from '../services/Schedule';
import config, { statusColors } from '../config';
import AuthenticationService from '../services/Authentication';
// import { ev } from '../rest_data';
const moment = extendMoment(Moment);

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      showResourcesUsed: false,
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
      ],
      showAgenda: false,
      allLocales,

      // dialogs
      editEventDialog: false,
      createEventDialog: false,
      generateReportDialog: false,
      generateReportDetailsDialog: false,
      reportFullDescription: null,

      uploadDialogVisible: false,
      uploadedScheduleType: null,
      uploadedScheduleTypes: ['dazn', 'ovo', 'route'],

      defaultStartDate: moment().format('YYYY-MM-DD'),

      // forms
      eventForm: {
        bugRequired: false,
      },
      // event aacount selectable by sys admin
      eventFormAccount: null,

      eventAudioMapping: [
        {
          active: false,
          channelNo: 0,
          description: 'Left',
        },
      ],
      showAudioMappingForm: false,
      showAudioMappingEditForm: false,
      audioMappingForm: {
        active: false,
        channelNo: 1,
        description: null,
      },

      colors: statusColors,
      config,
      schedule: [],
      statuses: [],
      step: 30,
      statusInterval: null,
      eventInterval: null,

      showAllEvents: false,
      showAllActiveEvents: false,
      showCanceled: false,
      

      isMobileOnly,
      mobileConfigNav: false,

      calendarDefaultView: 'timeGridWeek',
      headerConfig: {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek, timeGrid3Day, timeGridDay, listWeek',
      },
      isOverlappingEventsNotificationSent: false,
      currentEvents: [],
      resourcesUsed: [],
    };
  },

  watch: {
    account() {
      this.setActiveEvent(null);
    },
    listEvents(newVal) {
      console.log('%c EVENT LOADED', 'font-size: 20px; color: lime', newVal);
      this.currentEvents = this.getCurrentEvents(newVal);
      this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
      this.resourcesUsed = this.getResourcesUsed(this.currentEvents);
    },
    showAllEvents() {
      localStorage.setItem('showAllEvents', this.showAllEvents);
      this.setShowAllEvents(this.showAllEvents);
    },
    showAllActiveEvents() {
      this.setShowAllActiveEvents(this.showAllActiveEvents);
    },
    showCanceled() {
      this.setShowCanceled(this.showCanceled);
    },
    eventsWithTheSameOTT(newVal) {
      if (newVal.length > 0 && this.isSysAdmin) {
        let message = '';
        newVal.forEach((ev) => {
          message += `
            <div><b>${ev.fixture}</b></div>
            <small style="margin-top: -7px;display: block; opacity: .6;">${ev.id}</small>`;
        });
        if (!this.isOverlappingEventsNotificationSent) {
          this.$notify({
            title: 'Overlapping events have the same OTT outputs',
            message,
            duration: 0,
            dangerouslyUseHTMLString: true,
            type: 'warning',
            position: 'top-left',
          });
          this.isOverlappingEventsNotificationSent = true;
        }
      }
      this.setEventsWithTheSameOTT(newVal);
    },
  },

  created() {
    const showAllEvents = localStorage.getItem('showAllEvents');
    this.showAllEvents = showAllEvents === 'true';
    if (this.isSysAdmin) {
      this.showAllEvents = true;
      this.showAllActiveEvents = true;
    }
    const dateRange = JSON.parse(localStorage.getItem('scheduleViewDateRange'));
    if (dateRange) this.defaultStartDate = moment.utc(dateRange.endDate).subtract(1, 'week').format('YYYY-MM-DD');
    const savedView = localStorage.getItem('scheduleView');
    if (savedView) this.calendarDefaultView = savedView;
    if (this.isMobileOnly) {
      this.headerConfig.right = 'timeGridDay, listWeek';
      this.headerConfig.left = 'prev,next';
      this.calendarDefaultView = 'timeGridDay';
    }

    const savedShowAllEvents = localStorage.getItem('showAllEvents');
    const savedShowAllActiveEvents = localStorage.getItem('showAllActive');
    console.log('%csavedShowAllEvents', 'color:red', savedShowAllEvents);
    if (savedShowAllEvents) this.showAllEvents = (savedShowAllEvents === 'true');

    if (savedShowAllActiveEvents) this.showAllActiveEvents = (savedShowAllActiveEvents === 'true');

  },

  computed: {
    ...mapState({
      userId: (state) => state.userAccount.userId,
      aclEx: (state) => state.userAccount.userPermissions,
      isAdmin: (state) => state.userAccount.isAdmin,
      isSysAdmin: (state) => state.userAccount.isSysAdmin,
      acl: (state) => state.userAccount.aclCurrent,
      userGroups: (state) => state.userAccount.userGroups,
      account: (state) => state.userAccount.account,
      client: (state) => state.userAccount.client,
      clientAccounts: (state) => state.userAccount.clientAccounts,
      listClients: (state) => state.userAccount.listClients,
      showDataFromAllAccounts: (state) => state.userAccount.showDataFromAllAccounts,

      selectedEvent: (state) => state.schedule.selectedEvent,
      loading: (state) => state.schedule.loading,
      loadings: (state) => state.schedule.loadings,
      events: (state) => state.schedule.events,
      listEvents: (state) => state.schedule.listEvents,
      eventStatuses: (state) => state.schedule.eventStatuses,
      eventTypes: (state) => state.schedule.eventTypes,
      eventReports: (state) => state.schedule.eventReports,
      viewsSettings: (state) => state.schedule.viewsSettings,
      inputs: (state) => state.schedule.inputs,
      outputs: (state) => state.schedule.outputs,
      outputProfiles: (state) => state.schedule.outputProfiles,
      audioProfiles: (state) => state.schedule.audioProfiles,
      scheduleDateRange: (state) => state.schedule.scheduleDateRange,
      scheduleUIConfig: (state) => state.schedule.scheduleUIConfig,
    }),
    eventsStats() {
      const remainingStatuses = [
        'PENDING',
        'CLIENT_CONFIRMED',
        'RECEIVED',
        'CONFIRMED',
      ];
      const liveEvents = this.currentEvents
        .filter((event) => {
          return event.status === "LIVE";
        });
      const completedEvents = this.currentEvents
        .filter((event) => {
          return event.status === "COMPLETED";
        });
      const remainingEvents = this.currentEvents
        .filter((event) => {
          return remainingStatuses.indexOf(event.status) >= 0;
        });
      const stats = {
        total: this.currentEvents.length,
        live: liveEvents.length,
        completed: completedEvents.length,
        remaining: remainingEvents.length,
      };
      this.setStats(stats);
      return stats
    },
    avaiableAccounts() {
      const accounts = [];
      if (!this.listClients.items) return [];
      this.listClients.items.forEach((client) => {
        client.accounts.items.forEach((account) => {
          const isDefault = account.code === client.code;
          accounts.push({
            account,
            client,
            isDefault,
          });
        });
      });

      return accounts;
    },

    hasMultipleClients() {
      if (!this.listClients.items) return false;
      return this.listClients.items.length > 1;
    },

    accountOutputs() {
      const accessClaim = `usr.${this.account.code}.${this.client.code}`;
      const hasItems = !!this.outputs.items;
      if (!hasItems) return [];
      return this.outputs.items
        .filter((item) => {
          const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1;
          return hasPermission;
        })
        .sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });
    },

    outputProfilesSorted() {
      if (!this.outputProfiles.items) return [];
      return this.outputProfiles.items.slice(0).sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    },

    isMaUser() {
      return Array.isArray(this.userGroups) && this.userGroups.indexOf('usr.ma') !== -1;
    },

    claimsCanEdit() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [`ee.${accountId}.${clientId}`, `eo.${accountId}.${clientId}`];
    },

    claimsCanAccess() {
      const accountId = this.account.code;
      const clientId = this.client.code;
      return [
        `ev.${accountId}.${clientId}`,
        `ee.${accountId}.${clientId}`,
        `eo.${accountId}.${clientId}`,
      ];
    },

    dialogWidth() {
      return (value) => (this.isMobileOnly ? '90%' : value);
    },

    accountInputs() {
      const accessClaim = `usr.${this.account.code}.${this.client.code}`;
      const hasItems = !!this.inputs.items;
      if (!hasItems) return [];
      return this.inputs.items.filter((item) => {
        const hasPermission = item.claimsCanAccess.indexOf(accessClaim) !== -1;
        return hasPermission;
      }).sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    },

    isEventActvie() {
      return (id) => this.selectedEvent && this.selectedEvent.id === id;
    },

    statusStyle() {
      let styles = '<style>';
      Object.keys(this.scheduleUIConfig.eventColors).forEach((key) => {
        const value = this.scheduleUIConfig.eventColors[key];
        // calendar view event color
        styles += `.fc-event.${key} {
          background: ${value}; border-color: ${value};
        }`;
        styles += `.theme-mini .fc-event.${key} .fc-title {
          color: ${value};
        }`;
        styles += `.theme-mini .fc-event.${key} .fc-account-code span {
          color: ${value} !important;
        }`;
        styles += `.fc-event.${key}:before {
          background: ${value};;
        }`;
        // status tag color
        styles += `.event-tag-${key} {
          background: ${value} !important;
          border-color: ${value} !important;
        }`;
        // agenda view header color
        styles += `.agenda-day-event-name.${key} {
          background: ${value} !important;
          border-color: ${value} !important;
        }`;
        styles += `.resourceItem.${key} {
          fill: ${value} !important;
        }`;
        styles += `.theme-mini .agenda-day-event-name.${key}:before {
          background: ${value} !important;
          border-color: ${value} !important;
        }`;
        styles += `.theme-mini .agenda-day-event-name.${key} {
          color: ${value} !important;
        }`;

        styles += `.group-statuses.${key} {
          background: ${value} !important;
        }`;
      });
      if (this.selectedEvent) {
        styles += `
          .fc-event.ev-${this.selectedEvent.id} {
            outline: 2px solid #ff9f31 !important;
          }
          .fc-event.ev-${this.selectedEvent.id} .fc-account-code:before {
          }
        `;
      }
      styles += '</style>';
      return styles;
    },

    sportPropositions() {
      if (!this.listEvents && !this.listEvents.items) return [];
      const events = this.listEvents.items ? this.listEvents.items : [];
      const mappedSports = [];
      events.forEach((event) => {
        const doesExist = mappedSports.indexOf(event.sport) !== -1;
        if (!doesExist) mappedSports.push(event.sport);
      });
      return mappedSports;
    },

    accounts() {
      const doesAccountExist = !!this.clientAccounts;
      if (!doesAccountExist || !Array.isArray(this.clientAccounts)) return [];
      return this.clientAccounts.map((account) => account.id) || [];
    },

    isAccountDefault() {
      return (this.account && this.account.code) === this.client.code;
    },

    eventsWithTheSameOTT() {
      const overlapingEvents = [];
      this.overlapingEvents.forEach((event) => {
        // console.group(event.id);
        const { overlappingOTT, outputs } = event;
        const mappedOutputs = outputs.items
          .filter((out) => out)
          .map((out) => {
            const ottApplication = out.outputOttApplicationOverride || out.output.ottApplication;
            return {
              id: out.id,
              ottApplication,
              ottPlatform: out.output.ottPlatform,
              ottServer: out.output.ottServer,
            };
          });

        const sameOTT = overlappingOTT
          .filter((ev) => {
            const mappedOuts = ev.outputs.items
              .filter((out) => out)
              .map((out) => {
                const ottApplication = out.outputOttApplicationOverride || out.output.ottApplication;
                return {
                  id: out.id,
                  ottApplication,
                  ottPlatform: out.output.ottPlatform,
                  ottServer: out.output.ottServer,
                };
              })
              .filter((out) => {
                const t = mappedOutputs.filter((output) => {
                  // console.log('OUT => ', output.ottServer, '=>', out.ottServer, output.ottApplication === out.ottApplication , output.ottServer === out.ottServer);
                  // console.log('OUT => ', output.ottApplication, '=>', out.ottApplication);
                  if (!output.ottApplication && !output.ottServer) return false;
                  return output.ottApplication === out.ottApplication && output.ottServer === out.ottServer;
                });
                return t.length > 0;
              });
            // console.log('mappedOUTS', ev.id, mappedOuts);
            return mappedOuts.length > 0;
          });
        // console.log('sameOTT', sameOTT);
        if (sameOTT.length > 0) {
          overlapingEvents.push({
            ...event,
            sameOTT,
          });
        }
        // console.log('mappedOutputs', event.id, overlappingOTT, sameOTT);
        // console.groupEnd();
      });
      return overlapingEvents;
    },

    overlapingEvents() {
      if (!this.listEvents && !this.listEvents.items) return [];
      const events = this.listEvents.items ? this.listEvents.items : [];
      const overlappingEvents = [];
      events.forEach((event) => {
        const {
          serviceStartTime,
          serviceEndTime,
          startTime,
          endTime,
          id,
        } = event;

        const newStart = moment.utc(serviceStartTime).subtract(60, 'minutes').toDate();
        const newEnd = moment.utc(serviceEndTime).add(60, 'minutes').toDate();

        const related = events.filter((booking) => {
          if (booking.status === 'CANCELLED' || booking.status === 'COMPLETED') return false;
          // return booking.outputs === outputs;
          if (booking.id === id) return false;
          const start = moment.utc(booking.serviceStartTime).toDate();
          const end =  moment.utc(booking.serviceEndTime).toDate();
          const eventRange =  moment.range(moment.utc(serviceStartTime), moment.utc(serviceEndTime));
          const bookingRange = moment.range(moment.utc(booking.serviceStartTime), moment.utc(booking.serviceEndTime));
          const areOverlapping = eventRange.overlaps(bookingRange);
          // console.log('OVERRALPING TEST', areOverlapping, moment.utc(serviceStartTime).format('MM/DD HH:mm'), moment.utc(serviceEndTime).format('MM/DD HH:mm'), moment.utc(booking.serviceStartTime).format('MM/DD HH:mm'), moment.utc(booking.serviceEndTime).format('MM/DD HH:mm'));
          if (!areOverlapping) {
            return false;
          }
          return true;
        });

        const relatedOTT = related.filter((booking) => {
          if (booking.status === 'CANCELLED' || booking.status === 'COMPLETED') return false;
          const { items } = booking.outputs;
          const ottOutputs = items.filter((out) => {
            if (!out) return null;
            if (out.output.outputType === 'OTT') return true;
            return false;
          });
          return ottOutputs.length > 0;
        });
        if (related.length > 0) {
          if (event.status !== 'CANCELLED' && event.status !== 'COMPLETED') {
            overlappingEvents.push({
              ...event,
              overlapping: related,
              overlappingOTT: relatedOTT,
            });
          }
        }
      });
      return overlappingEvents;
    },

    hasOverlapingInputs() {
      return this.resourcesUsed.filter((event) => {
        return event.overlappingInputs.length > 0;
      })
      .map(event => event.overlappingInputs.length)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    resourcesUsed_delete() {
      const inputs = {};
      const eventsWithInput = this.currentEvents
        .filter((event) => {
          return event.extendedProps.input || event.extendedProps.inputOverride;
        });
      const events = eventsWithInput
        .map((event) => {
          const eventInput = event.extendedProps.inputOverride || event.extendedProps.input;
          const inputName = eventInput.name;
          
          // console.groupCollapsed('%cxxxx', 'color:lime', event);
          const overlappingInputs = eventsWithInput.filter((ev) => {
            console.log('e resourcesUsed', ev.autostartTitle === event.autostartTitle, ev, event);
            if (ev.id === event.id || ev.title === event.title) return false;

            const evInput = ev.extendedProps.inputOverride || ev.extendedProps.input;
            if (evInput.id !== eventInput.id) return false;

            const range1 = moment.range(moment.utc(ev.extendedProps.serviceStartTime), moment.utc(ev.extendedProps.serviceEndTime));
            const range2 = moment.range(moment.utc(event.extendedProps.serviceStartTime), moment.utc(event.extendedProps.serviceEndTime));
            
            const isOverlaping = range1.overlaps(range2);

            // console.log('%cxxxx', 'color:lime', moment.utc(ev.extendedProps.serviceStartTime).isBetween(event.serviceStartTime, event.serviceEndTime), isOverlaping);
            // debugger;
            return isOverlaping;
          })
          // console.groupEnd();
          const newEvent = {
            ...event.extendedProps,
            inputName: inputName,
            overlappingInputs,
          }
          return newEvent;
        })
        // .forEach((event) => {
        //   const eventInput = event.extendedProps.input || event.extendedProps.inputOverride;
        //   const inputName = eventInput.name;
        //   if (!inputs[inputName]) inputs[inputName] = [];
        //   inputs[inputName].push({
        //     ...event,
        //   });
        // });
      return events;
    },

    // currentEvents_delete() {
    //   if (!this.listEvents && !this.listEvents.items) return [];
    //   const events = this.listEvents.items ? this.listEvents.items : [];
    //   const mappedEvents = events
    //   .filter((event) => {
    //     const eventRange =  moment.range(moment.utc(event.serviceStartTime), moment.utc(event.serviceEndTime));
    //     const calRange = moment.range(moment.utc(this.scheduleDateRange.start).add(1, 'days').startOf('day'), moment.utc(this.scheduleDateRange.end).subtract(2, 'days').endOf('day'));
    //     const areOverlapping = eventRange.overlaps(calRange);
    //     return areOverlapping;
    //   })
    //   .filter((event) => {
    //     const isAssignToCurrentAccount = this.account.id === event.account.id;
    //     const isCanceled = event.status === 'CANCELLED'
    //       || event.status === 'DELETED'
    //       || event.status === 'REJECTED';
    //     const isAccountDefault = this.account.id === this.client.id;
    //     if (isCanceled && !this.showCanceled) return false;
    //     if (this.showAllEvents) return true;
    //     if (isAccountDefault) return this.accounts.indexOf(event.account.id) !== -1;
    //     console.log('range ')
    //     return isAssignToCurrentAccount;
    //   }).map((item) => {
    //     const newItem = {
    //       id: item.id,
    //       start: item.lineupTime,
    //       lineupTime: item.lineupTime,
    //       end: item.endTime,
    //       title: item.fixture,
    //       content: item.description,
    //       classNames: `${item.status} ${item.type} ev-${item.id}`,
    //       status: item.status,
    //       extendedProps: item,
    //       data: item,
    //     };

    //     if (moment(item.endTime).utc().format('HH:mm') === '00:00') {
    //       // newItem.end = moment(item.endTime).add('1', 'days').utc().format();
    //     }
    //     if (item.customer) newItem.extendedProps.customerName = item.customer.name;
    //     newItem.extendedProps.inputName = item.input ? item.input.name : '';

    //     newItem.extendedProps.overrideInputName = item.inputOverride ? item.inputOverride.name : '';

    //     if (newItem.extendedProps.overrideInputName !== '' && this.isSysAdmin) {
    //       newItem.extendedProps.inputName += ` <br> ${newItem.extendedProps.overrideInputName} (OVERRIDE)`;
    //     }

    //     newItem.extendedProps.audio = '';
    //     newItem.extendedProps.audioPDF = '';
    //     item.audioMapping.items.forEach((channel) => {
    //       newItem.extendedProps.audio
    //         += `${channel.channelNo} ${channel.description}
    //       `;
    //       newItem.extendedProps.audioPDF += `${channel.channelNo} ${channel.description} \r\n`;
    //     });

    //     newItem.extendedProps.output = '';
    //     newItem.extendedProps.outputPDF = '';
    //     item.outputs = !!item.outputs ? item.outputs : { items: [] };
    //     item.outputs.items.filter((output) => !!output).forEach((el) => {
    //       const [outputConfig] = newItem.extendedProps.actions.items
    //         .filter((action) => action.assignedOutput.id === el.output.id);
    //       const hasWowzaConfig = outputConfig && outputConfig.configWowza;
    //       const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
    //       const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
    //       const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
    //       const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : '';
    //       // console.log('===> ', el, newItem.extendedProps, outputConfig);
    //       const urlPdf = el.output.url ? `URL: ${el.output.url}` : '';
    //         newItem.extendedProps.output += `
    //           <div class="output-${el.output.status}">
    //             ${el.output.displayName || el.output.name} 
    //             <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
    //             <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
    //             ${hasWowzaConfig ? autostartString : ''}
    //             <div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>
    //           </div>
    //         `;

    //       const ott = `[${el.output.ottServer} - ${el.output.ottApplication}]`;
    //       const ottOverrride = `[OTT Override: ${el.outputOttApplicationOverride}]`;
    //       newItem.extendedProps.outputPDF += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''} ${urlPdf} \r\n`;

    //       newItem.extendedProps.outputString += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''}, `;
    //       if (el.outputOttApplicationOverride) {
    //         newItem.extendedProps.output += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>
    //           `;
    //       }
    //     });

    //     if (newItem.extendedProps.output === '') newItem.extendedProps.output = 'not set';
    //     if (newItem.extendedProps.inputName === '') newItem.extendedProps.inputName = 'not set';
    //     if (newItem.extendedProps.outputPDF === '') newItem.extendedProps.outputPDF = 'not set';
    //     if (newItem.extendedProps.audioPDF === '') newItem.extendedProps.audioPDF = 'not set';

    //     newItem.extendedProps.inputPDF = newItem.extendedProps.inputName.replace('<br>', '');
    //     return newItem;
    //   });
    //   console.log(this.overlapingEvents);
    //   return mappedEvents;
    // },

    minTime() {
      // let minTime = 86400; // min time in seconds moment('23:59', 'HH:mm');
      // let isFullDay = false;
      const format = 'HH:mm:ss';
      const texst = this.currentEvents.map((event) => {
        const start = moment.duration(moment.utc(event.extendedProps.serviceStartTime).format('HH:mm')).asSeconds();
        const end = moment.duration(moment.utc(event.extendedProps.serviceEndTime).format('HH:mm')).asSeconds();
        return start > end ? 0 : start;
      });
      const minTime = moment(Math.min(...texst));
      if (this.currentEvents.length === 0 || Math.min(...texst) === 0) return '00:00';

      // if (isFullDay) return '00:00';
      return minTime.format(format);
    },

    maxTime() {
      /*
        let maxTime = moment('00:00', 'HH:mm');
        let minTime = moment('23:59', 'HH:mm');
        let shouldSetMax = false;
        this.currentEvents.forEach((event) => {
          const maxMinutes = this.minutesOfDay(maxTime);
          const minMinutes = this.minutesOfDay(minTime);
          const eventMinutes = this.minutesOfDay(moment(event.end));
          const isMidnight = moment(event.end).utc().format('HH:mm') === '00:00';
          const isBefore = eventMinutes < minMinutes;
          if (isMidnight) shouldSetMax = true;
          const isAfter = eventMinutes > maxMinutes;
          if (isAfter) maxTime = moment(event.end).utc();
          if (isBefore) minTime = moment(event.start).utc();
        });

        if (shouldSetMax) return '24:00';
        const time = maxTime.add(2, 'hour').format('HH:00:00');
        if (this.currentEvents.length === 0) return '24:00';
      */
      return '24:00';
      // return time.startsWith('00') || time.startsWith('01') ? '24:00' : time;
    },

    timeAxisHeight() {
      const timeRange = moment(this.maxTime, 'HH:mm:ss').hours()
        - moment(this.minTime, 'HH:mm:ss').hours();
      const percentage = (100 - (100 / 24 * timeRange) + 100) / 100;
      const height = (Math.round((1.5 * percentage) * 100) / 100) + 0.3;
      return height > 1.5 ? height : 1.5;
    },

    timeAxisStyle() {
      return `<style>
      .fc .fc-time-grid .fc-slats td {
        height: ${this.timeAxisHeight}em;
      }
      </style>`;
    },

    visibleColumnDefs() {
      return this.columnDefs.filter((column) => column.visible);
    },

    agendaHeaderStyle() {
      let gridColumns = '';
      this.columnDefs.forEach((column) => {
        const isVisible = column.visible;
        if (!isVisible) return;
        gridColumns += `${column.width}px `;
      });
      const style = `
        <style>
          .agenda-header-columns {
            grid-template-columns: ${gridColumns};
          }
        </style>
      `;
      return style;
    },

    groupCurrentEvents_delete() {
      return _.groupBy(this.currentEvents, (date) => moment(date.start).startOf('day'));
    },

    txReports() {
      const doesReportsExist = !!this.eventReports.items;
      if (!doesReportsExist) return [];
      const sortedReports = this.eventReports.items
        .slice(0)
        .sort((left, right) => moment.utc(left.event.startTime)
          .diff(moment.utc(right.event.startTime)));
      return _.groupBy(sortedReports, (date) => moment(date.event.startTime).startOf('day'));
    },
  },

  mounted() {
    // this.getStatus();
    this.init();
    // this.statusInterval = setInterval(this.getStatus, 60000);
    this.eventInterval = setInterval(this.getEvents, 30000);
  },

  beforeDestroy() {
    // window.clearInterval(this.statusInterval);
    window.clearInterval(this.eventInterval);
  },

  methods: {
    ...mapActions({
      fetchEvents: 'schedule/fetchEvents',
      fetchEventsByDate: 'schedule/fetchEventsByDate',
      createEvent: 'schedule/createEvent',
      addBooking: 'schedule/addBooking',
      deleteEvent: 'schedule/deleteEvent',
      updateEvent: 'schedule/updateEvent',
      deleteEventAudioChannel: 'schedule/deleteEventAudioChannel',
      createEventAudioChannel: 'schedule/createEventAudioChannel',

      fetchInputs: 'schedule/fetchInputs',
      listAudioMappingProfiles: 'schedule/listAudioMappingProfiles',
      fetchOutputs: 'schedule/fetchOutputs',

      restartEvent: 'schedule/restartEvent',
      restartIncomingStream: 'schedule/restartIncomingStream',
      listEventReports: 'schedule/listEventReports',
      refreshEvents: 'schedule/refreshEvents',

      listStreamSources: 'stream/listStreamSources',
      listStreamDestinations: 'stream/listStreamDestinations',

      fetchPictureFormats: 'input/fetchPictureFormats',
      listTranscoderProfiles: 'permissions/listTranscoderProfiles',
    }),
    ...mapMutations({
      setEventsWithTheSameOTT: 'schedule/setEventsWithTheSameOTT',
      setActiveEvent: 'schedule/setActiveEvent',
      setShowAllEvents: 'schedule/setShowAllEvents',
      setShowAllActiveEvents: 'schedule/setShowAllActiveEvents',
      setShowCanceled: 'schedule/setShowCanceled',
      setShowDataFromAllAccounts: 'userAccount/setShowDataFromAllAccounts',
      setShowCreateEventDialog: 'schedule/setShowCreateEventDialog',
      setDateRange: 'schedule/setDateRange',
      setStats: 'schedule/setStats',
    }),

    async init() {
      // await this.fetchEvents();
      await this.fetchInputs();
      await this.fetchOutputs();
      await this.listAudioMappingProfiles();
      await this.fetchPictureFormats();
      if (!this.isSysAdmin) return;
      await this.listStreamSources();
      await this.listStreamDestinations();
      await this.listTranscoderProfiles();
    },

    eventRender(info) {
      const {
        lineupTime,
        serviceStartTime,
        serviceEndTime,
        endTime,
        fixture,
        outputs,
        input,
        inputOverride,
        inputDownlinkConfig,
      } = info.event.extendedProps;

      const momentLineupStart = moment(lineupTime);
      const momentServiceStartTime = moment(serviceStartTime);
      const momentServiceEndTime = moment(serviceEndTime);
      const momentEnd = moment(endTime);

      const startText = moment.utc(info.event.start).format('HH:mm');
      const endText = moment.utc(info.event.end).format('HH:mm');
      const timeText = `${startText}-${endText}
        <span class="fc-title">${info.event.title}</span>
        <span class="fc-desc">${fixture}</span>`;

      const serviceStartDuration = moment.duration(momentLineupStart.diff(momentServiceStartTime));
      const serviceEndDuration = moment.duration(momentServiceEndTime.diff(momentEnd));

      const hasInput = info.event.extendedProps.input && info.event.extendedProps.input.name;
      const hasOverrideInput = info.event.extendedProps.inputOverride && info.event.extendedProps.inputOverride.name;
      const hasOutputs = info.event.extendedProps.actions.items.length !== 0
        || info.event.extendedProps.outputs.items.length !== 0;

      const isDownlink = input && input.type === 'DOWNLINK';

      const isDownlinkConfigMissing = inputDownlinkConfig && inputDownlinkConfig.BISS && inputDownlinkConfig.satelliteFrequency && inputDownlinkConfig.satelliteName && inputDownlinkConfig.symbolRate;

      const oneMinuteHeight = this.timeAxisHeight / 30; // in EM

      const div = document.createElement('div');
      const divVertical = document.createElement('div');
      const divVerticalContent = document.createElement('div');
      const serviceTimeBeforeDiv = document.createElement('div');
      const serviceTimeAfterDiv = document.createElement('div');
      const accountCodeDiv = document.createElement('div');
      const noInputDiv = document.createElement('div');

      const popupDiv = document.createElement('div');
      const popupDivContent = document.createElement('div');

      serviceTimeBeforeDiv.className = 'event-service-time event-service-time-before';
      serviceTimeAfterDiv.className = 'event-service-time event-service-time-after';
      accountCodeDiv.className = 'fc-account-code';
      noInputDiv.className = 'fc-no-input';
      popupDiv.className = 'fc-popup';
      popupDivContent.className = 'fc-popup-content animated fadeInUp fadeOutDown';
      divVertical.className = 'fc-event-vertical';
      divVerticalContent.className = 'fc-event-vertical-content';
      divVerticalContent.title = `${startText}-${endText} ${info.event.title}`;

      let noInputMessage = '';
      if (!hasInput) noInputMessage = 'Input not set<br>';
      if (!hasOutputs) noInputMessage += 'Outputs not set <br>';

      if (!isDownlinkConfigMissing && isDownlink) noInputMessage += 'DOWNLINK CONFIG INCOMPLETE <br>';

      noInputDiv.innerHTML = `<i class="bi bly-warning-2"></i><span>${noInputMessage}</span>`;
      popupDiv.innerHTML = '<i class="bi bly-info"></i>';
      const servicecStartHeight = serviceStartDuration.minutes() * oneMinuteHeight;
      const servicecEndHeight = serviceEndDuration.minutes() * oneMinuteHeight;

      serviceTimeBeforeDiv.style.height = `${servicecStartHeight}em`;
      serviceTimeBeforeDiv.style.top = `-${servicecStartHeight}em`;

      serviceTimeAfterDiv.style.height = `${servicecEndHeight}em`;
      serviceTimeAfterDiv.style.bottom = `-${servicecEndHeight}em`;

      div.className = 'fc-desc';
      div.innerHTML = info.event.extendedProps.content;
      accountCodeDiv.innerHTML = `<span>${info.event.extendedProps.account.name}</span>`;
      divVerticalContent.innerHTML = timeText;

      // info popup content
      const filteredOutputs = outputs.items.filter((item) => !!item);
      let inputText = hasInput ? input.name : '<span class="warning">Input not set</span>';
      if (!isDownlinkConfigMissing && isDownlink) inputText += '<div class="warning">DOWNLINK CONFIG INCOMPLETE</div>';
      const overrideInputText = inputOverride ? `${inputOverride.name} (OVERRIDE)` : '';
      const basicOutputList = filteredOutputs
        .map((item) => {
          let outputText = `<div>${item.output.name}`;
          outputText += `
              <span class="output-type-tag ${item.output.outputType}">${item.output.outputType}</span>
              <span class="show-ma">(${item.output.ottServer} - ${item.output.ottApplication})</span>
          `;
          if (item.outputOttApplicationOverride) outputText += `<span class="fc-ott-override" >(OTT Override: ${item.outputOttApplicationOverride})</span>`;
          outputText += '</div>';
          return outputText;
        });
      const basicOutputTypes = filteredOutputs.map((item) => item.output.outputType);
      const outputText = hasOutputs ? basicOutputList.join('') : '<div class="warning">Outputs not set</div>';
      const basicOutputTypesText = _.keys(_.groupBy(basicOutputTypes))
        .map((item) => `<span class="output-type-tag ${item}">${item}</span></div>`);

      popupDivContent.innerHTML = `<div class="fc-popup-fixture">${fixture}</div>`;
      popupDivContent.innerHTML += `<div class="fc-popup-input"><strong>Input:</strong>${inputText} <br> ${overrideInputText}</div>`;
      popupDivContent.innerHTML += `<div class="fc-popup-outputs"><strong>Outputs:</strong>${outputText}</div>`;

      divVerticalContent.innerHTML += `<div class="fc-output-types">${basicOutputTypesText}</div>`;

      popupDiv.append(popupDivContent);
      divVertical.append(divVerticalContent);
      info.el.firstChild.append(div);
      info.el.append(serviceTimeBeforeDiv);
      info.el.append(serviceTimeAfterDiv);
      info.el.append(accountCodeDiv);
      info.el.append(divVertical);
      info.el.append(popupDiv);

      // console.log('=> ', info.event.extendedProps.impoortSource, moment.utc(info.event.start).format('HH:mm'), info.event.title, info);
      if (!hasInput || !hasOutputs) info.el.append(noInputDiv);
    },

    showGenerateReportDialog() {
      this.generateReportDialog = true;
      this.listEventReports();
    },

    showMoreDetails(description) {
      this.generateReportDetailsDialog = true;
      this.reportFullDescription = description;
    },

    generateReport(reportItems) {
      const rowHeader = [
        'NTR',
        'Issue Description',
        'Name',
        'Line-up',
        'Start Time',
        'End Time',
        'Tail Time',
        'Head Time',
        'Fixture',
        'Sport',
        'Property',
        'Client',
        'Input',
        'Output',
        'Audio',
      ];
      const rows = [];
      rows.push(rowHeader);
      reportItems.forEach((row) => {
        const rowArray = [];
        const ntr = row.ntr ? 'NTR' : '';
        const cleanNTR = ntr ? ntr.split('\n').join(' | ').split(',').join('--') : ' - ';
        const desc = row.description ? row.description.split('\n').join(' | ') : '';
        rowArray.push(cleanNTR);
        rowArray.push(`"${desc}"`);
        rowArray.push(row.event.name);
        rowArray.push(row.event.lineupTime);
        rowArray.push(row.event.startTime);
        rowArray.push(row.event.endTime);
        rowArray.push(row.event.tailTime);
        rowArray.push(row.event.headTime);
        rowArray.push(row.event.fixture);
        rowArray.push(row.event.sport);
        rowArray.push(`"${row.event.property}"`);
        rowArray.push(row.event.customer.name);
        rowArray.push(row.event.input.name);

        let outputs = '';
        row.event.outputs.items.forEach((output) => {
          outputs += `${output.output.name} ${output.output.description} | `;
        });

        rowArray.push(outputs);

        let audioMapping = '';
        row.event.audioMapping.items.forEach((audio) => {
          const active = audio.active ? '(active)' : '';
          audioMapping += `${audio.channelNo} ${audio.description} ${active} | `;
        });

        rowArray.push(audioMapping);
        rows.push(rowArray);
      });

      const csvContent = rows.map((e) => e.join(',')).join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const filename = `report-${new Date().toISOString()}.csv`;
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      this.generateReportDialog = false;
    },

    async getEvents() {
      console.log('getEvents');
      const dateRange = JSON.parse(localStorage.getItem('scheduleViewDateRange'));
      const dateRangeUpdated = {
        start: `${moment.utc(dateRange.startDate).subtract(2, 'days').format().split('T')[0]}T00:00:00.000Z`,
        end: `${moment.utc(dateRange.endDate).add(1, 'days').format().split('T')[0]}T00:00:00.000Z`,
      };
      await this.refreshEvents({...dateRangeUpdated, showCompleted: this.showAllActiveEvents});
    },

    async datesRender(data) {
      const dateRange = {
        start: `${moment.utc(data.view.activeStart).subtract(1, 'days').format().split('T')[0]}T00:00:00.000Z`,
        end: `${moment.utc(data.view.activeEnd).add(1, 'days').format().split('T')[0]}T00:00:00.000Z`,
      };
      const dateRangeSaved = {
        startDate: `${moment.utc(data.view.activeStart).subtract(1, 'days').format().split('T')[0]}T00:00:00.000Z`,
        endDate: `${moment.utc(data.view.activeEnd).add(1, 'days').format().split('T')[0]}T00:00:00.000Z`,
      };

      console.log('dates render', dateRangeSaved);
      this.setDateRange(dateRange);
      localStorage.setItem('scheduleViewDateRange', JSON.stringify(dateRangeSaved));
      await this.fetchEventsByDate({ ...dateRange, showCompleted: this.showAllActiveEvents });
      this.loadings.calendar = false;
      const nowIndicator = document.querySelector('.fc-now-indicator');

      if (!nowIndicator) {
        window.scroll(0, 0);
        return;
      }
      window.scroll(0, document.querySelector('.fc-now-indicator').offsetTop);
    },

    calendarRenderCallBack(data) {
      this.showAgenda = data.view.type === 'listWeek';
      if (this.showAgenda) this.$router.push('/schedule-agenda/stats');
      localStorage.setItem('scheduleView', data.view.type);
    },

    openCreateEventDialog() {
      this.setShowCreateEventDialog(true);
      this.mobileConfigNav = false;
    },

    sportSearch(queryString, cb) {
      const sports = this.sportPropositions.map((sport) => {
        const sportObj = { value: sport };
        return sportObj;
      });
      if (queryString.length < 3) {
        cb(sports);
        return sports;
      }
      const searchString = queryString.toLowerCase();
      const results = sports.filter((sport) => {
        if (!sport.value) return false;
        const doesStartWith = sport.value.toLowerCase().includes(searchString);
        return doesStartWith;
      });
      // call callback function to return suggestions
      cb(results);
      return sports;
    },

    createFilter(queryString) {
      return (sport) => (sport.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    },

    eventClick(info) {
      this.setActiveEvent(info.event.extendedProps);
    },
    eventHover(info) {
      console.log(':: => ', info.event.extendedProps);
    },

    handleHTTPUpload(e) {
      const xhr = new XMLHttpRequest();
      const type = this.uploadedScheduleType;
      if (!this.uploadedScheduleType) {
        this.$message.error('Please choose type');
        return null;
      }
      xhr.open('POST', `${this.config.url}ScheduleImport`);

      xhr.onload = e.onSuccess;
      xhr.onprogress = e.onProgress;

      xhr.setRequestHeader('Authorization', `Bearer ${AuthenticationService.token}`);

      const fd = new FormData();
      fd.append('file', e.file);
      fd.append('filename', e.file.name);
      fd.append('type', type);

      xhr.upload.addEventListener('progress', (event) => {
        e.onProgress({ percent: event.loaded / event.total * 100 });
      }, false);

      xhr.onreadystatechange = (event) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          const uploadResult = data.error;

          if (uploadResult !== '') {
            this.$message.error(uploadResult);
            e.onError(event);
          }
          if (uploadResult === '') {
            e.onSuccess();
          }
        }
      };

      // xhr.onload = e.onSuccess;
      // xhr.onerror = e.onError;
      // xhr.upload.addEventListener("progress", e.onProgress)
      xhr.send(fd);
      return xhr;
    },

    uploadFiles() {
      this.$refs.upload.submit();
    },

    handleUploadChange() {

    },

    handleUploadClose() {
      this.uploadDialogVisible = false;
    },

    async getStatus() {
      // const result = await ScheduleService.getStatus();

      // const isSuccess = result.status === 200;
      // if (!isSuccess) return; // TODO: display message;

      // this.statuses = result.data.data;
    },

    getEventObjForUpdate(event) {
      return {
        bugRequired: event.bugRequired,
        claimsCanAccess: event.claimsCanAccess,
        claimsCanEdit: event.claimsCanEdit,
        description: event.description,
        endTime: event.endTime,
        eventAccountId: event.eventAccountId,
        eventCustomerId: event.eventCustomerId,
        inputId: event.inputId,
        expectedVersion: event.version,
        fixture: event.fixture,
        headTimeMin: event.headTimeMin,
        id: event.id,
        lineupTime: event.lineupTime,
        name: event.name,
        property: event.property,
        requestedBitrate: 15,
        serviceEndTime: event.serviceEndTime,
        serviceStartTime: event.serviceStartTime,
        sport: event.sport,
        startTime: event.startTime,
        status: event.status,
        tailTimeMin: event.tailTimeMin,
        type: event.type,
        eventLastUpdatedById: this.userId,
      };
    },
    showAllEventsChanged(value) {
      console.log('%cshow change, value', 'color: lime', value);
      localStorage.setItem('showAllEvents', value);
      // this.currentEvents = this.getCurrentEvents(this.listEvents);
      // this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
      this.resourcesUsed = this.getResourcesUsed(this.currentEvents);
    },
    showAllActiveEventsChanged(value) {
      console.log('%cshow change, value', 'color: lime', value, this.listEvents);
      localStorage.setItem('showAllActive', value);
      // this.currentEvents = this.getCurrentEvents(this.listEvents);
      // this.groupCurrentEvents = this.getEventGroupedByDate(this.currentEvents);
      this.resourcesUsed = this.getResourcesUsed(this.currentEvents);
    },
    getResourcesUsed(currentEvents = []) {
      const inputs = {};
      const eventsWithInput = currentEvents
        .filter((event) => {
          return event.extendedProps.input || event.extendedProps.inputOverride;
        });
      const events = eventsWithInput
        .map((event) => {
          const eventInput = event.extendedProps.inputOverride || event.extendedProps.input;
          const inputName = eventInput.name;
          
          // console.groupCollapsed('%cxxxx', 'color:lime', event);
          const overlappingInputs = eventsWithInput.filter((ev) => {
            // console.log('e resourcesUsed', ev.autostartTitle === event.autostartTitle, ev, event);
            if (ev.id === event.id || ev.title === event.title) return false;

            const evInput = ev.extendedProps.inputOverride || ev.extendedProps.input;
            if (evInput.id !== eventInput.id) return false;

            const range1 = moment.range(moment.utc(ev.extendedProps.serviceStartTime), moment.utc(ev.extendedProps.serviceEndTime));
            const range2 = moment.range(moment.utc(event.extendedProps.serviceStartTime), moment.utc(event.extendedProps.serviceEndTime));
            
            const isOverlaping = range1.overlaps(range2);

            // console.log('%cxxxx', 'color:lime', moment.utc(ev.extendedProps.serviceStartTime).isBetween(event.serviceStartTime, event.serviceEndTime), isOverlaping);
            // debugger;
            return isOverlaping;
          })
          // console.groupEnd();
          const newEvent = {
            ...event.extendedProps,
            inputName: inputName,
            overlappingInputs,
          }
          return newEvent;
        })
        // .forEach((event) => {
        //   const eventInput = event.extendedProps.input || event.extendedProps.inputOverride;
        //   const inputName = eventInput.name;
        //   if (!inputs[inputName]) inputs[inputName] = [];
        //   inputs[inputName].push({
        //     ...event,
        //   });
        // });
      return events;
    },
    getCurrentEvents(list) {
      console.time('SCHEDULE.JS getCurrentEvents');
      if (!list && !list.items) return [];
      const events = list.items ? list.items : [];
      const mappedEvents = events
      .filter((event) => {
        const eventRange =  moment.range(moment.utc(event.serviceStartTime), moment.utc(event.serviceEndTime));
        const calRange = moment.range(moment.utc(this.scheduleDateRange.start).add(1, 'days').startOf('day'), moment.utc(this.scheduleDateRange.end).subtract(2, 'days').endOf('day'));
        const areOverlapping = eventRange.overlaps(calRange);
        // console.log('IS EVENT OVERLAPPING', areOverlapping, eventRange.toString(), calRange.toString() )
        return areOverlapping;
      })
      .filter((event) => {
        const isAssignToCurrentAccount = this.account.id === event.account.id;
        const isCanceled = event.status === 'CANCELLED'
          || event.status === 'DELETED'
          || event.status === 'REJECTED';
        const isActive = event.status !== 'COMPLETED'
          && event.status !== 'POSTPONED';
        const isAccountDefault = this.account.id === this.client.id;
        if (isCanceled && !this.showCanceled) return false;

        if (!this.showAllActiveEvents && !isActive) return false;
        if (this.showAllEvents) return true;
        if (isAccountDefault) return this.accounts.indexOf(event.account.id) !== -1;
        return isAssignToCurrentAccount;
      })
      .map((item) => {
        const newItem = {
          id: item.id,
          start: item.lineupTime,
          lineupTime: item.lineupTime,
          end: item.endTime,
          title: item.fixture,
          content: item.description,
          classNames: `${item.status} ${item.type} ev-${item.id}`,
          status: item.status,
          extendedProps: item,
          data: item,
        };

        // if (moment(item.endTime).utc().format('HH:mm') === '00:00') {
        //   // newItem.end = moment(item.endTime).add('1', 'days').utc().format();
        // }
        if (item.customer) newItem.extendedProps.customerName = item.customer.name;
        newItem.extendedProps.inputName = item.input ? item.input.name : '';

        newItem.extendedProps.overrideInputName = item.inputOverride ? item.inputOverride.name : '';

        if (newItem.extendedProps.overrideInputName !== '' && this.isSysAdmin) {
          newItem.extendedProps.inputName += ` <br> ${newItem.extendedProps.overrideInputName} (OVERRIDE)`;
        }

        newItem.extendedProps.audio = '';
        newItem.extendedProps.audioPDF = '';
        item.audioMapping.items.forEach((channel) => {
          newItem.extendedProps.audio
            += `${channel.channelNo} ${channel.description}
          `;
          newItem.extendedProps.audioPDF += `${channel.channelNo} ${channel.description} \r\n`;
        });

        newItem.extendedProps.output = '';
        newItem.extendedProps.outputPDF = '';
        item.outputs = !!item.outputs ? item.outputs : { items: [] };
        item.outputs.items.filter((output) => !!output).forEach((el) => {
          const [outputConfig] = newItem.extendedProps.actions.items
            .filter((action) => action.assignedOutput.id === el.output.id);
          const hasWowzaConfig = outputConfig && outputConfig.configWowza;
          const autostartTitle = hasWowzaConfig && outputConfig.configWowza.autostart ? outputConfig.configWowza.autostartOffsetMin : 0;
          const autostartColor = hasWowzaConfig && outputConfig.configWowza.autostart ? 'green' : 'red';
          const autostartString = `<i class="bi bly-time ${autostartColor}" title="Offset ${autostartTitle} min"></i>`;
          const autostartPDFString = autostartTitle ? `[Offset ${autostartTitle} min]` : '';
          // console.log('===> ', el, newItem.extendedProps, outputConfig);
          const urlPdf = el.output.url ? `URL: ${el.output.url}` : '';
            newItem.extendedProps.output += `
              <div class="output-${el.output.status}">
                ${el.output.displayName || el.output.name} 
                <span class="output-type-tag ${el.output.outputType}">${el.output.outputType}</span>
                <span class="output-type-tag output-status-${el.output.status}">${el.output.status}</span>
                ${hasWowzaConfig ? autostartString : ''}
                <div class="show-ma ${el.output.ottServer}">(${el.output.ottServer} - ${el.output.ottApplication})</div>
              </div>
            `;

          const ott = `[${el.output.ottServer} - ${el.output.ottApplication}]`;
          const ottOverrride = `[OTT Override: ${el.outputOttApplicationOverride}]`;
          newItem.extendedProps.outputPDF += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''} ${urlPdf} \r\n`;

          newItem.extendedProps.outputString += `${el.output.displayName || el.output.name} [${el.output.outputType}]${hasWowzaConfig ? autostartPDFString : ''} ${el.output.ottServer ? ott : ''} ${el.outputOttApplicationOverride ? ottOverrride : ''}, `;
          if (el.outputOttApplicationOverride) {
            newItem.extendedProps.output += `<span class="show-ma" >(OTT Override: <strong>${el.outputOttApplicationOverride}</strong>)</span>
              `;
          }
        });

        if (newItem.extendedProps.output === '') newItem.extendedProps.output = 'not set';
        if (newItem.extendedProps.inputName === '') newItem.extendedProps.inputName = 'not set';
        if (newItem.extendedProps.outputPDF === '') newItem.extendedProps.outputPDF = 'not set';
        if (newItem.extendedProps.audioPDF === '') newItem.extendedProps.audioPDF = 'not set';

        newItem.extendedProps.inputPDF = newItem.extendedProps.inputName.replace('<br>', '');
        return newItem;
      });
      console.timeEnd('SCHEDULE.JS getCurrentEvents');
      return mappedEvents;
    },
    getEventGroupedByDate(list) {
      return _.groupBy(this.currentEvents, (date) => moment(date.start).startOf('day'));
    },
  },
};
</script>

<style>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
.el-loading-spinner svg {
  margin: 0 auto;
}

.show-ma {
  display: none;
}

.ma-user .show-ma {
  display: block;
}

.report-dialog-window .el-dialog {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.report-dialog-window .tx-reports-header {
  margin-top: -16px;
  margin-left: 42px;
}
.report-dialog-window .el-dialog__body:after {
  content: " ";
  background: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.912202381) 100%);
  display: block;
  width: 100%;
  height: 80px;
  margin-top: -80px;
  z-index: 5;
  position: relative;
  pointer-events: none;
}

.report-dialog {
  height: calc(100vh - 235px);
  margin-right: -20px;
}
.report-dialog .tx-reports {
  color: #000;
  padding-right: 24px;
}
.report-dialog .tx-reports-header {
  padding-left: 10px;
}
.report-dialog .tx-reports .tx-reports-day-group {
  padding: 10px;
  background: #f0f4ff;
  margin-bottom: 10px;
  margin-top: 10px;
}
.report-dialog .tx-reports .tx-reports-day-group-date {
  font-weight: bold;
}
.report-dialog .tx-reports .tx-reports-day-group-date span {
  padding: 4px 8px;
  background: #e6edff;
  display: inline-block;
  margin-bottom: 3px;
  padding: 0px 0px 10px;
  background: transparent;
  display: inline-block;
  margin-bottom: 3px;
  font-size: 16px;
}
.report-dialog .tx-reports .tx-report {
  padding: 8px 16px;
  border-left: 4px solid #ea0d51;
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  background: #fff;
  margin-left: 16px;
  margin-right: 16px;
}
.report-dialog .tx-reports .tx-report .bi {
  top: 2px;
  position: relative;
}
.report-dialog .tx-reports .tx-report-issue {
  padding: 4px 0;
  margin-top: 4px;
}
.report-dialog .tx-reports .tx-report.ntr {
  border-left-color: #0dd06f;
}
.report-dialog .tx-reports .tx-report-event-details {
  grid-template-columns: 1fr;
  display: grid;
}
.report-dialog .tx-reports .tx-report-event-details td {
  vertical-align: top;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row, .fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #343c49;
}

.fc-content-skeleton {
  position: absolute;
  top: 0;
}

.show-account-codes .fc .fc-event .fc-account-code {
  display: block;
}

.fc {
  padding: 20px;
}
.fc-header-toolbar {
  position: fixed;
  top: 50px;
  left: 20px;
  right: 380px;
  z-index: 2;
  padding-top: 10px;
  background: #13191D;
  outline: 2px solid #13191D;
}
.fc-ott-override {
  float: none !important;
  opacity: 0.7;
  margin-bottom: 6px;
  margin-top: -9px;
  display: block;
}
.fc-view-container {
  position: relative;
  top: 82px;
}
.fc-head {
  position: fixed;
  left: 20px;
  right: 378px;
  z-index: 2;
  top: 94px;
  padding-top: 8px;
  background: #13191D;
}
.fc-event {
  border-radius: 0px;
  border-width: 0px !important;
  padding: 0;
}
.fc-event.LIVE:after {
  content: " ";
  display: block;
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  border: 3px solid #fff;
  z-index: 15;
}
.fc-event.fc-time-grid-event-inset {
  box-shadow: 0px 0px 0px 1px #13191D;
}
.fc-event .event-service-time {
  position: absolute;
  background: repeating-linear-gradient(45deg, #13191D, #13191D 3px, #343c49 3px, #343c49 9px);
  background-image: linear-gradient(45deg, #13191D 25%, #343c49 25%, #343c49 50%, #13191D 50%, #13191D 75%, #343c49 75%, #343c49 100%);
  background-size: 11.31px 11.31px;
  position: absolute;
  width: 100%;
  opacity: 0.7;
  z-index: -1;
}
.fc-event .fc-account-code {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0px 8px;
  color: #cfdce7;
  background: #13191d;
  z-index: 9;
  line-height: 18px;
  text-transform: unset;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
.fc-event .fc-no-input {
  /* content: "!"; */
  position: absolute;
  /* top: 0; */
  left: 3px;
  color: black;
  /* width: 15px; */
  /* height: 16px; */
  background: #000;
  font-weight: bold;
}
.fc-event .fc-popup {
  position: absolute;
  z-index: 99;
  top: 3px;
  left: 1px;
  color: #7f8cad;
  background: #000;
}
.fc-event .fc-popup:hover {
  cursor: pointer;
}
.fc-event .fc-popup:hover .fc-popup-content {
  display: block;
}
.fc-event .fc-popup-content {
  position: fixed;
  bottom: 18px;
  right: 20px;
  background: #000;
  padding: 20px;
  display: none;
  z-index: 9999;
  transform-origin: bottom left;
  color: #fff;
  width: 300px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19);
}
.fc-event .fc-popup-content .fc-popup-fixture {
  color: #fff;
  font-size: 16px !important;
  margin-bottom: 10px;
}
.fc-event .fc-popup-content .fc-popup-input strong {
  text-transform: uppercase;
  opacity: 0.5;
  display: block;
  margin-right: 10px;
}
.fc-event .fc-popup-content .fc-popup-outputs {
  margin-top: 12px;
}
.fc-event .fc-popup-content .fc-popup-outputs strong {
  text-transform: uppercase;
  opacity: 0.5;
  display: block;
  margin-right: 20px;
}
.fc-event .fc-popup-content .fc-popup-outputs div {
  line-height: 20px;
}
.fc-event .fc-popup-content .fc-popup-outputs span {
  float: right;
}
.fc-event .fc-popup-content .fc-popup-outputs span.show-ma {
  float: none;
  opacity: 0.7;
  margin-bottom: 6px;
  display: block;
}
.fc-event .fc-event-vertical {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 16px;
  transform-origin: top left;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fc-event .fc-event-vertical [data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
.fc-event .fc-event-vertical [data-title]:after {
  content: attr(data-title);
  background-color: #00FF00;
  color: #111;
  font-size: 150%;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.6em;
  left: 100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
.fc-event .fc-event-vertical [data-title] {
  position: relative;
}
.fc-event .fc-event-vertical-content {
  transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  width: 20px;
  height: 20px;
  top: calc(100% - 24px);
  position: absolute;
}
.fc-event .fc-event-vertical-content .fc-title {
  display: none;
}
.fc-event .fc-event-vertical-content .fc-desc {
  display: inline-block !important;
  margin-left: 6px;
}
.fc-event .fc-event-vertical-content .fc-output-types span {
  margin-left: 0px;
  margin-right: 10px;
  margin-top: 2px;
  background: transparent;
  padding-left: 0;
  color: #7683a1;
}
.fc-event .fc-content {
  position: initial;
  display: none;
}
.fc-event .fc-content .fc-time {
  display: none;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.fc-event .fc-content .fc-time span {
  position: absolute;
  bottom: -10px;
  display: block;
  transform: rotate(-90deg);
  left: 1px;
  transform-origin: top left;
}
.fc-event .fc-content .fc-title {
  padding: 1px 2px;
  display: none;
}
.fc-event .fc-content .fc-desc {
  padding-left: 3px;
  opacity: 0.6;
}
.fc-scroller {
  overflow-y: hidden !important;
  height: auto !important;
}
.fc-scroller .fc-list-heading {
  display: grid;
}
.fc-scroller .fc-list-heading .fc-widget-header {
  background: transparent !important;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
}
.fc-scroller .fc-list-table .fc-list-item {
  display: grid;
  background: #232b33;
  grid-template-columns: 135px 10px 1fr;
  border-radius: 4px;
  margin-bottom: 8px;
}
.fc-scroller .fc-list-table .fc-list-item .c-list-item-time {
  color: #7f8cad;
}
.fc-scroller .fc-list-table .fc-list-item:hover td {
  background: #343c49;
}
.fc-scroller .fc-list-table .fc-list-item td {
  border: 0px;
  padding: 10px;
}
.fc-slats .fc-time {
  width: 90px;
}
.fc-slats .fc-axis {
  border-top: 0px;
  border-left: 0px;
  padding-right: 20px;
  color: #fff;
}
.fc .fc-today span {
  font-weight: bold;
}
.fc .fc-time-grid {
  overflow: hidden;
}
.fc .fc-time-grid .fc-slats td {
  height: 1.5em;
  border-top: 1px solid #343c49;
}
.fc.fc-unthemed td {
  border-left-color: #13191d;
  border-right-color: #13191d;
  border-bottom: 0px;
}
.fc.fc-unthemed td.fc-today {
  background: rgba(0, 0, 0, 0.19);
}
.fc.fc-unthemed th {
  border: 0px;
  background: #343c49;
  color: #CFDFFF;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 40px;
}
.fc.fc-unthemed th.fc-today {
  color: #ff9f31;
}
.fc .fc-bg {
  pointer-events: none;
}
.fc tr.fc-minor {
  opacity: 0.5;
}
.fc tr.fc-minor td {
  border-top: 1px solid red;
}
.fc tr.fc-minor td.fc-axis {
  border-color: #13191d !important;
}

.fc-toolbar h2 {
  color: #66718d;
}

.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}

.agenda .fc-view-container {
  height: 0;
  overflow: hidden;
}

.mobile #schedule {
  grid-template-columns: 1fr;
}
.mobile .v-modal {
  z-index: 20 !important;
}
.mobile #schedule #no-bookings-message {
  z-index: 90;
}

#schedule {
  max-width: 100vw;
  overflow: hidden;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 360px;
}
#schedule .config .el-switch__label--right {
  color: #7f8cad;
}
#schedule .config .el-divider--vertical {
  top: -3px;
  opacity: 0.5;
}
#schedule .fc-view-container hr {
  display: none;
}
#schedule .tree-day {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 380px;
}
#schedule .tree-day .vuecal__time-column {
  width: 0;
  overflow: hidden;
}
#schedule .tree-day > div:first-child .vuecal__time-column {
  width: auto;
  overflow: visible;
}
#schedule .vuecal__flex[column] {
  margin-top: -1px;
}
#schedule .container {
  padding: 0px;
  width: calc(100% - 380px);
  margin: 20px 0 0 0;
}
#schedule #loading-view, #schedule #no-bookings-message {
  padding: 20px;
  margin-right: 360px;
  margin-top: 36px;
  position: fixed;
  top: 107px;
  z-index: 999;
  left: 10px;
  bottom: 0px;
  right: 10px;
  pointer-events: none;
}
#schedule #loading-view svg, #schedule #no-bookings-message svg {
  margin: 0 auto;
}
#schedule #no-bookings-message {
  z-index: 990;
}

.extend-time .btn {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0px 6px;
}
.extend-time .btn span {
  font-size: 10px;
  /* display: block; */
  text-align: center;
  opacity: 0.5;
  line-height: 0px;
  padding-bottom: 11px;
}

.btn {
  display: inline-block;
  line-height: 32px;
  background: #0077ff;
  padding: 0 16px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.btn:hover {
  opacity: 0.7;
}
.btn i {
  line-height: 30px;
}
.btn-green {
  background: #00d56b;
}
.btn-red {
  background: #fc487f;
}
.btn-block {
  width: 100%;
  text-align: center;
}

.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}

.vertical-border {
  width: 1px;
  height: 100%;
  background: red;
  content: " ";
}

.mobile #schedule.agenda #loading-view, .mobile #schedule.agenda #no-bookings-message {
  top: 60px;
}
.mobile #loading-view, .mobile #no-bookings-message {
  margin-right: 0px !important;
  bottom: 0px;
  right: 19px;
  pointer-events: none;
}
.mobile .fc {
  padding: 20px;
  margin-right: 0px;
}
.mobile .fc-header-toolbar {
  position: fixed;
  top: 50px;
  left: 20px;
  right: 20px;
}
.mobile .fc-header-toolbar .fc-left {
  margin-left: -20px;
}
.mobile .fc-head {
  right: 20px;
}
.mobile .fc-center h2 {
  font-size: 16px;
  color: #cadcff;
}
.mobile .event-preview-drawer #el-drawer__title .el-tag {
  top: 0;
  position: absolute;
  right: 0;
  font-size: 12px;
  padding: 4px 8px;
  height: auto;
  border-radius: 0px 0px 0px 5px;
  font-weight: 400;
  letter-spacing: 1px;
}
.mobile .tx-reports-day-group .tx-report-event-details-entry {
  display: grid;
  grid-template-columns: 100px auto;
  font-weight: 600;
}
.mobile .tx-reports-day-group .tx-report-event-details-entry .tx-report-event-label {
  font-weight: 500;
}

.theme-mini .fc-event {
  background: #2a323c !important;
  border-color: #2a323c !important;
}
.theme-mini .fc-event:before {
  content: " ";
  width: 10px;
  height: 20px;
  display: block;
  left: 2px;
  bottom: 3px;
  border-radius: 2px;
  position: absolute;
  background: red;
  z-index: 20;
}
.theme-mini .fc-event .fc-time {
  background: transparent;
  color: #cadcff;
}
.theme-mini .fc-event .fc-desc {
  color: #7f8cad;
  display: none;
}
.theme-mini .fc-event .fc-content {
  padding-left: 18px;
  padding-top: 5px;
}
.theme-mini .fc-event .event-service-time {
  opacity: 0.8;
}
.theme-mini .fc-event .fc-account-code {
  left: 0;
  right: auto;
  top: 0;
  bottom: 0;
  width: 15px;
  line-height: 0;
  padding: 0;
  overflow: hidden;
}
.theme-mini .fc-event .fc-account-code span {
  position: absolute;
  bottom: 26px;
  display: block;
  left: 8px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-size: 11px;
  white-space: nowrap;
  text-align: right;
  width: 0;
  height: 0;
  font-weight: 600;
  letter-spacing: 1px;
  color: #cadcff;
}
.theme-mini .fc-event .fc-no-input {
  color: #FF5722;
  font-size: 12px;
  top: 22px;
  left: 1px;
  z-index: 9;
}
.theme-mini .fc-event .fc-no-input span {
  display: none;
  font-size: 12px;
}
.theme-mini .fc-event .fc-no-input:hover span {
  display: block;
  background: #000;
  padding: 4px 8px;
  position: absolute;
  right: -18px;
  width: 100px;
  z-index: 9999;
  font-weight: normal;
}
.theme-mini .fc .fc-scroller {
  overflow-y: visible !important;
  height: auto !important;
  padding-bottom: 82px;
}

.status-legend {
  line-height: 36px;
  font-weight: 500;
  font-size: 13px;
}
.status-legend .color {
  display: inline-block;
  width: 32px;
  height: 16px;
  line-height: 0;
  margin-right: 10px;
  position: relative;
  top: 3px;
  border-radius: 2px;
}

.mobile-status-legend {
  padding-left: 16px;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}
.output-type-tag {
  background: #3F51B5;
  color: #ffffff;
  padding: 0 6px;
  border-radius: 3px;
  position: relative;
  top: -2px;
  font-size: 11px;
  height: 18px;
  display: inline-block;
  margin-left: 6px;
  line-height: 19px;
}
.output-type-tag.OTT {
  background: #009688;
}
.output-type-tag.ROUTE {
  background: #607D8B;
}
</style>
