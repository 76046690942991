<template>
  <div class="agenda-group-event bg-gray-800 relative p-1 rounded mb-1 cursor-pointer hover:bg-black"  :class="{}">
    <div class="w-3 top-1 bottom-1 rounded absolute text-xs text-black text-center font-bold pt-0.5" :class="bgStatusColor(eventStatus)">G
      <div class="absolute bottom-0 left-0 right-0 transform rotate "
      :class="{'rotate-90': !isOpen, '-rotate-90 -left- -right-': isOpen}">
        <svg
        xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20"   fill="currentColor">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
      </div>
      <div class="rotate-[270deg]"></div>
    </div>
    <div class="group-header md:flex justify-between mb-1">
      <!-- GROUP NAME -->
      <div class="flex space-x-1 items-center text-sm ml-6">
        <span class="" :class="eventStatusTextColorClass">
          {{event.name}}
          <span v-if="eventGroup">{{eventGroup.length}}</span>
        </span>
        <span v-for="status in groupStatuses" :key="status"
          class="w-3 h-3 inline-block ml-1 rounded transform"
          :class="bgStatusColor(status)"></span>
          <div class="agenda-day-event-name-description ml-1">
            {{event.description}}
          </div>
      </div>
      <!-- GROUP CLIENT/ACCOUNT -->
      <div class="text-xs text-gray-100 ml-6 md:ml-0 py-0.5 px-1 rounded bg-gray-900">
        <span class="fc-event-account">
          <span v-if="event.commentaryLanguage"
            class="commentary-language"><i class="fas fa-globe-americas"></i> {{event.commentaryLanguage}}
          </span>
          <span :class="eventStatusTextColorClass">
            GROUP
          </span>
          <span class="vertical-divinder"></span>
          <span class="fc-event-account-client">{{event.customer.name}}</span>
        </span>
      </div>
    </div>
    <div class="sm:flex justify-between items-center">
      <div class="group-details md:flex ml-6 md:space-x-4 text-gray-100 text-sm w-full" :class="headerColumnClass">
        <div v-for="col in visibleColumnDefs" :key="'i' + col.headerName"
          :class="'agenda-day-event-' + col.headerName + ' ' + col.field"
          :style="{width: isMobileOnly ? 'auto' : col.width + 'px'}"
          class=" flex flex-initial">
          <span class="agenda-label inline-block md:hidden text-gray-500 w-20">
            {{col.headerName}}
          </span>
          <span class="time" v-if="col.type === 'time'">
            {{event[col.field] | dateUtc('H:mm')}}
            <span class="opacity-50 block text-xs">{{event[col.field] | dateUtc('DD/MM')}}</span>
          </span>
          <span class="other" v-if="col.type === 'html' && col.field !== 'outputUI' && col.field !== 'audio' && col.field !== 'inputName'"
            v-html='event[col.field]'>
          </span>
          <span class="other output" v-if="col.field === 'outputUI'"
            >
          </span>
          <span class="relative group" v-if="col.field === 'inputName'">
            <img :src="thumb.data" alt="" class="w-12" v-if="isSysAdmin || (event.status === 'LIVE')"/>
            <img :src="thumb.data" alt="" class="absolute hidden w-52 group:hover:block z-50" v-if="isSysAdmin || (event.status === 'LIVE')"/>
            <span v-html="event[col.field]"></span>
            <div class="hidden">{{ inputAlert }}</div>
          </span>
          <span class="other"
            v-if="!col.type && col.field !== 'audioUI' && col.field !== 'outputUI'"
            :class="{warning: event[col.field] === 'not set'}"
            >
            {{event[col.field]}}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AgendaGroupHeader',
  props: {
    event: Object,
    eventGroup: Array,
    groupEventsStatuses: Array,
    visibleColumnDefs: Array,
    isOpen: Boolean,
    isMobileOnly: Boolean,
    inputStatuses: Object,
    isSysAdmin: Boolean,
  },

  computed: {
    inputStatusData() {
      const { input } = this.event;
      console.log('input.name getInputStatus x', input);
      if (!this.inputStatuses || !input || !this.inputStatuses || !this.inputStatuses[34] || !input.probeTaskId) return {};
      
      this.thumb = this.inputStatuses[34][input.probeTaskId].thumb || {};
      return this.inputStatuses[34][input.probeTaskId] || {};
    },
    inputAlert() {
      return this.inputStatusData.alarms || [];
    },
    groupStatuses() {
      return [...new Set(this.groupEventsStatuses)];
    },
    eventStatus() {
      return this.event.status;
    },
    eventStatusTextColorClass() {
      return `text-${this.eventStatus}`;
    },
    bgStatusColor() {
      return status => `bg-${status}`;
    },
    headerColumnClass() {
      // return '';
      return `grid-cols-${this.visibleColumnDefs.length}`;
    },
  },

  data() {
    return {
      thumb: {},
    };
  },
  methods: {
  },
};
</script>
