import axios from 'axios';
import config from '../config';
import awsExports from '../aws-exports';

class ServersService {
  constructor(options) {
    this.config = config;
    this.options = options;
    this.url = config.url;
    this.svcUrl = config.svcUrl;
    this.apiUrl = config.apiUrl;
    this.awsConfig = awsExports[process.env.VUE_APP_MODE];
    console.log('awdConfig', this.awsConfig)
  }

  async getServersStatus() {
    return axios.get(`${this.url}serverstatus`);
  }

  async sendCommand(command) {
    return axios.post(`${this.url}servercommand`, command);
  }

  async restartApplication(command) {
    return axios.post(`${this.url}WowzaStreamTarget`, command);
  }
  async getOutputsStatus(data) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('ma-token'),
      'Client-Id': this.awsConfig.aws_user_pools_web_client_id,

    };

    // axios.post('https://your-api-url.com/endpoint', data, { headers })
    return axios.post(`${this.svcUrl}serverStatus`, data, { headers });
  }
  async getInputsStatus(data) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('ma-token'),
      'Client-Id': this.awsConfig.aws_user_pools_web_client_id,

    };

    // axios.post('https://your-api-url.com/endpoint', data, { headers })
    return axios.post(`${this.svcUrl}thumbs`, data, { headers });
  }
  async getInputsStatusThumbs(data) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('ma-token'),
      'Client-Id': this.awsConfig.aws_user_pools_web_client_id,

    };

    // axios.post('https://your-api-url.com/endpoint', data, { headers })
    return axios.post(`${this.svcUrl}inputsStatus`, data, { headers });
  }
  async getInputsThumb(taskName) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('ma-token'),
      'Client-Id': this.awsConfig.aws_user_pools_web_client_id,

    };

    // axios.post('https://your-api-url.com/endpoint', data, { headers })
    return axios.get(`${this.svcUrl}thumb?taskName=${taskName}`, { headers });
  }
}

export default new ServersService();
